/**
 * Locations for Maps and Directions
 *
 */

 /* =Location Index
 ----------------------------------------------------------*/

 .view-locations {
   padding: 30px 0;
 }

 .location {
   margin: 0 0 30px;
   padding: 0 0 30px;
   border-bottom: 1px solid $wcm-border-gray;
 }

 .location__title {
   font-size: 20px;
   font-family: $wcm-bold;
   color: $wcm-red;
   line-height: 1.3;
   margin: 0 0 5px;
 }
 
.location__photo {
  margin: 0 0 20px;
  img {
    border-top: 8px solid $wcm-red;
  }
}

.location__address {
  font-size: 16px;
  margin: 0 0 20px;
}

// .location__description {
//   margin: 0 0 7.5px;
// }

.location__directions,
.location__download {
  display: inline-block;
  a {
    border-bottom: none;
    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      float: left;
      margin: 5px 8px 0 0;
      background-repeat: no-repeat;
      background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg');
      background-size: 395px 120px;
    }
    &.wcm-edu-external-link:after {
      display: none;
    }
  }
}

.location__directions {
  margin-bottom: 20px;
  a:before {
    background-position: -300px -25px;
  }
}

.location__download {
  border-left: 1px solid $wcm-border-gray;
  margin-left: 20px;
  padding-left: 20px;
  a:before {
    background-position: -25px -75px;
  }
}

