/**
* Styles related to the Affiliations Content Type and List View
*
*/

.affiliations-highlight .quick-text {
  color: $wcm-white;
  background-image: linear-gradient( rgba(179, 27, 27, 0.6), rgba(179, 27, 27, 0.6) ), url('/sites/default/files/quick_text_bg/affiliations-background.jpg') !important;
  a {
    color: $wcm-white;
  }
}

.affiliations-intro-text {
  text-align: center;
  font-size: large;
  @include breakpoint($sm) {
    padding: 0 100px 20px;
  }
}

/* =Affiliations Teasers
----------------------------------------------------------*/

.view-affiliations {
  min-height: 200px;

  .views-row {
    &:first-child {
      .affiliations-teaser__overview {
        border-width: 1px 0 1px;
      }
    }
  }
}

.js .view-affiliations .affiliations-teaser {
  display: none;
}

.affiliations-teaser {
  margin: 0;
}

.affiliations-teaser__overview {
  border: 1px solid $wcm-border-gray;
  border-width: 0 0 1px;

  div[class*='col-'] {

    @include breakpoint($xs) {
      width: 100%;
    }
  }
}

.affiliations-teaser__name {
  @include breakpoint($xs) {
    // line-height: 1.3;
  }
  line-height: 30px;

  color: $wcm-dark-orange;
  font-size: 18px;
  padding: 20px 55px 20px 0px;

  position: relative;
  cursor: pointer;
  &:after {
    content: '+';
    width: 50px;
    display: block;
    position: absolute;
    top: 20px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border: 1px solid $wcm-border-gray;
    border-width: 0 1px;
    color: $wcm-dark-orange;
  }

  &.affiliations-teaser__toggle--open {
    color: $wcm-black;
    &:after {
      content: '–';
      // color: $wcm-dark-orange;;
    }
  }
}

.affiliations__link {
  padding-top: 22px;

  @include breakpoint($xs) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  a {
    border-bottom: none;
  }
}


.unit-teaser__cta {
  margin: 0 0 20px;
  @include breakpoint($sm) {
    margin: 0;
  }
  a.external-link:after {
    display: inline-block;
    content: '';
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
    margin-left: 6px;
    position: relative;
    top: 2px;
  }
}

// .unit-teaser__cta,
// .unit-teaser__readmore {
//   display: inline-block;
//   font-size: 13px;
// }

.unit-teaser__readmore {
  margin: 10px 0;
  @include breakpoint($sm) {
    margin: 0 0 10px;
  }
  a {
    &:after {
      content: '\e80d';
      color: $wcm-bright-orange;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
  }
}

.unit-teaser__cta {
  display: inline-block;
}

/* =Unit Hide and Show
----------------------------------------------------------*/

.affiliations-teaser {
  position: relative;
  @include clearfix();
}

.affiliations-teaser__details {
  display: none;
  padding: 20px 0 0;
  @include breakpoint($md) {
    padding: 20px 200px 0 60px;
  }
  
}

.no-js {
  .affiliations-teaser__details {
      display: block;
  }
  .affiliations-teaser__actions:before {
      display: none;
  }
}
