/**
 * Styles targeting the Faculty Callout panes
 *
 */

/* =Home Faculty Callout
----------------------------------------------------------*/

.faculty-callout-section {
  background: $wcm-med-gray;
  padding: 80px 0;
  @include breakpoint($sm) {
    background: url('/sites/default/files/education-featured-video-background-hires.png');
    background-size: cover;
  }
  .paragraph-section__title {
    margin: 0 0 30px;
  }
}

.faculty-callout__wrap {
  background: rgba(0, 0, 0, 0.6);
  padding: 0 0 10px;
  @include breakpoint($sm) {
    padding: 0 40px 60px;
  }
}

.faculty-callouts {
  width: 100%;
  margin: 0;
  padding: 0;
  @include breakpoint($sm) {
    display: flex;
  }
}

.faculty-callouts li {
  max-width: 300px;
  margin: 0 auto;
  list-style-type: none;
  position: relative;
  @include breakpoint($sm) {
    max-width: none;
    vertical-align: top;
    position: relative;
    width: 33.33333%;
    margin: 0;
    border:0 transparent solid;
	  border-width: 0 5px;
    background-clip:padding-box;
  }
  @include breakpoint($md) {
    border-width: 0 20px;
  }

  .faculty-callout__summary {
    cursor: pointer;
    @include breakpoint($xs) {
      height: auto !important;
    }
  }

  // &:before {
  //   content: '';
  //   background: $wcm-dark-gray;
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   -webkit-box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.6);
  //   box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.6);
  // }
}

.faculty-callout {
  position: relative;
  width: 100%;
  background: $wcm-dark-gray;
  margin: 0 0 20px;
  @include breakpoint($sm) {
    margin: 0;
  }
  @include breakpoint($md) {
    // margin-bottom: -99999px;
    // padding-bottom: 99999px;
  }

  &:before {
    content: '';
    background: $wcm-dark-gray;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.6);
    box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.6);
  }
}

.faculty-callout__image {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:before {
    content: '+';
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    bottom: -1.5%;
    right: 2%;
    z-index: 100;
    color: $wcm-white;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    border-style: solid;
    border-color: transparent;
    border-width: 30px;
    border-right-color: $wcm-dark-orange;
    border-bottom-color: $wcm-dark-orange;
  }

  // &:hover {
  //   &:before {
  //     color: $wcm-yellow;
  //   }
  // }
  img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease 0s;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.faculty-callout__identity {
  background: $wcm-dark-gray;
  color: $wcm-white;
  @include breakpoint($xs) {
    padding: 20px 20px;
  }
  padding: 20px 30px;
  position: relative;
  @include breakpoint($sm-only) {
    padding: 15px 20px;
  }
}

.faculty-callout__name {
  font-weight: 700;
  margin: 0 0 10px;
  line-height: 1.3;
}

.faculty-callout__title {
  font-size: 14px;
}

.faculty-callout__details {
  //display: none; // Not needed since the HTML5 hidden attribute is active on the element
  background: $wcm-dark-gray;
  color: $wcm-white;
  position: relative;
  @include breakpoint($sm) {
    opacity: 0;
    margin: 0;
    width: 66.6666%;
    float: left;
    // transition: all 400ms ease;
  }
}

.faculty-callout__text {
  margin: 10px 20px;
  @include breakpoint($sm) {
    padding: 0 20px 0 20px;
    margin: 0 0 0 0;
  }

  @include breakpoint($md) {
    padding: 0 20px 0 3px;
  }
}

.faculty-callout__description {
  font-size: 14px;
  margin: 0 0 20px;
  @include breakpoint($md) {
    font-size: 18px;
    margin: 0 20px 60px 0;
  }
}

.faculty-callout__profile-links {
  @include breakpoint($sm) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  a {
    position: relative;
    display: block;
    // font-weight: 700;
    background: $wcm-dark-orange;
    color: $wcm-white;
    margin: 5px 0;
    padding: 10px 40px;
    border: none;
    text-align: center;
    @include breakpoint($sm) {
      display: inline-block;
      margin: 0 0px 0 0;
      float: left;
      // font-size: 18px;
    }

    &:first-child {
      @include breakpoint($sm) {
        &:before {
          content: '';
          position: absolute;
          right: 0;
          height: 70%;
          top: 15%;
          border: 0.5px solid $wcm-white;
          opacity: 0.5;
        }
      }
    }

    &:after{
      content: '\e80d';
      padding-left: 10px;
      font-size: 70%;
      @include fontello();
    }
    &:hover {
      background: $wcm-red;
    }
  }
}

/* Pane Toggled */

.faculty-callouts.open li {
  @include breakpoint($sm) {
    width: 0%
  }
}

.faculty-callouts.open li.open {
  @include breakpoint($sm) {
    width: 100%;
    border-width: 0 5px 0 5px;
  }
  @include breakpoint($md) {
    border-width: 0 20px;
  }
}

.faculty-callouts.open li.closed {
  border-width: 0px;
}

.faculty-callouts.open li.closed .faculty-callout {
  @include breakpoint($sm) {
    display: none;
  }
}

li.open .faculty-callout {
  @include breakpoint($sm) {
    display: flex;
  }
}

li.open .faculty-callout__image {
  &:before {
    content: '–';
  }
}

li.open .faculty-callout__summary {
  @include breakpoint($sm) {
    width: 33.33333%;
    float: left;
    padding-right: 6.65px;
  }
  @include breakpoint($md) {
    padding-right: 26.6px;
  }
}

li.open .faculty-callout__details {
  opacity: 1;
  display: block;
  @include breakpoint($sm) {
    width: 66.66666%;
    float: left;
  }
}

li.open .faculty-callout__text {
  @include breakpoint($sm) {
    animation: reveal 0.5s;
    @include v-center();
    position: absolute;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
