/**
 * Styles targeting the Graduation Page
 *
 */

.graduation-page {

  .panel-onecol-full {
    position: relative;
    background-color: $wcm-white;
    overflow: hidden;

    &:before{ 
      content: '';
      background: $wcm-dark-orange;
      // background: $wcm-dark-orange url(../images/Seal_RGB_WHT.png) no-repeat;
      bottom: 0;
      display: block;
      height: 600px;
      left: 0;
      position: absolute;
      right: 0;
      transform: skewY(15deg);
      transform-origin: 0;
      // z-index: -1; 
      top: -310px;
      @include breakpoint($sm) {
        top: -255px;
      }
      @include breakpoint($md) {
        top: -190px;
      }
    }
    &:after { 
      content: '';
      // background: $wcm-dark-orange;
      bottom: 0;
      display: block;
      // height: 250px;
      // left: 50%;
      position: absolute;
      right: 0;
      top: 0;

      width: 0;
      height: 0;
      border-top: 40vw solid $wcm-dark-orange;
      border-left: 90vw solid transparent;

      // transform: skewY(-15deg);
      transform-origin: 0;
      // z-index: -1;
    } 

    .container-fluid {
      position: relative;
      z-index: 1;
    }

    .col-md-12 {
      background: url(../images/Seal_RGB_WHT.png) no-repeat 0 130px;
      background-size: 30%;

      @include breakpoint($sm) {
        background-size: 40%;
        background-position: 0 120px;
      }
      @include breakpoint($md) {
        background-size: auto;
        background-position: 0 50px;
      }
    }
  }

  .pane-node-title {
    text-align: center;
    background-color: $wcm-white;
    margin-bottom: 20px;
    h1 {
      font-size: 55px;
      background-color: $wcm-white;
      color: $wcm-bright-orange;
      margin: 20px 0;
      display: inline-block;
      max-width: 1180px;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .pane-node-field-featured-image {
    .hero-image {
      position: relative;
      margin-bottom: 20px;
      background-color: $wcm-black;
      img {
        margin-bottom: 0;
        border: 1px solid $wcm-white;
        // opacity: 0.8;
      }
    }
    figcaption {
      @include center();
      font-style: normal;
      font-family: $wcm-bold;
      text-align: center;
      float: none;
      width: 80%;
      color: $wcm-white;
      font-size: 18px;
      top: 20%;
      // padding-top: 40px;

      @include breakpoint($sm) {
        font-size: 30px;
        line-height: 40px;
      }
      @include breakpoint($md) {
        font-size: 36px;
        line-height: 46px;
      }
    }
  } // featured image

  .featured-news {
    .tile-callout {
      width: 100%;
      
      .tile-callout__photo {
        @include breakpoint($sm) {
          width: 48%;
          float: left;
          margin-right: 40px;
        }
      }
      .tile-callout__title {
        font-size: 30px;

        @include breakpoint($md) {
          padding-top: 2%;
        }
        @include breakpoint($lg) {
          padding-top: 7%;
        }
      }
    }
  }

  .wcm-graduation-social-cta {
    background-color: $wcm-bg-gray;
    padding: 20px 0;
    margin-bottom: 20px;

    a:after {
      display: none;
    }

    .title-section {
      font-size: 30px;
      color: $wcm-red;
      margin-bottom: 20px;

      span {
        font-family: $wcm-bold;
      }
    }

    .wcm-social-media-icons {
      @include breakpoint($md) {
        border-right:  1px solid $wcm-border-gray;
      }
        display: inline-block;
        padding-right: 35px;
        margin-right: 35px;

      &:last-child {
        border-right: none;
      }
      
      div {
        margin: 0 10px;
      }

      .header {
        font-family: $wcm-bold;
        color: $wcm-med-gray;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-size: 13px;
        float: left;
        padding-top: 15px;
      }
    }

    .wcm-cta__tile {
      &:hover {
        background-color: transparent;
      }
      .cta-icon {
        width: 50px;
        height: 50px;
      }
    }
    .wcm-cta__tile.external-link > .wcm-cta__detail {
      &:after {
        content: '';
        display: none;
      }
    }
  }

  .paragraphs-items-field-card-callout {
    display: inherit;
    .card-callout {
      @include breakpoint($md) {
        width: 50%;

        .card-callout__wrapper {
          padding-top: 20px;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}