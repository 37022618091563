/**
 * Styles targeting the Faculty Landing page
 *
 */

 .faculty-search {
  // background: $wcm-bg-gray;
  // padding: 30px; 
  position: relative;
  margin-top: -70px;

  .form-group {
    background: $wcm-white;
    padding: 20px 30px 20px;
    @include breakpoint($md) {
      padding: 20px 45px 20px;
    }
    margin-bottom: 0;
  }
  .form-control {
     width: 100%;
     max-width: none;
   }
   .input-group-btn .btn {
     background-color: $wcm-dark-orange;
     border: 1px solid $wcm-dark-orange;
     border-top-right-radius: 4px;
     border-bottom-right-radius: 4px;
     font-size: 16px;
     height: 39px;
     padding: 6px 15px;
     &:hover {
       background-color: $wcm-red;
     }
   }
   .glyphicon-search {
     font-size: 16px;
     position: relative;
     top: 3px;
     padding-right: 5px;
   }
 }