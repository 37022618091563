/**
 * Component Styles
 *
 */

/* =SiteImprove (accessibility)
 ----------------------------------------------------------*/

 a:hover,
 a:focus {
   color: $wcm-red;
 }

 #active-third-level-nav .level-3-select a {
   color: $wcm-dark-orange;
 }

 .footer-contact__site-name a {
   color: $wcm-white;
   &:hover {
     color: $wcm-white;
   }
 }

 .footer-contact__social-links a {
   @include breakpoint($sm) {
     color: $wcm-white;
     &:hover {
       color: $wcm-yellow;
     }
   }
 }

 .social-share a {
   color: $wcm-dark-orange;
 }

 .btn--wcm {
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $wcm-white;
    background-color: $wcm-dark-orange;
    border-color: $wcm-red;
    border-width: 4px;
  }
}

/* =Structure
----------------------------------------------------------*/

.site-main {
  margin: 70px 0 0;
  @include breakpoint($md) {
    margin: 0;
  }
}

/* =Typography
 ----------------------------------------------------------*/

@mixin wcm_external_icon() {
  display: inline-block;
  content: '' !important;
  background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
  margin-left: 6px;
  position: relative;
  top: 2px;
  @include breakpoint($md) {
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
  }
}

.wcm-edu-external-link:after {
  @include wcm_external_icon();
}

.institutional-link:after {
  content: '' !important;
}

 /* =Branding
----------------------------------------------------------*/

.brand__nav {
  @include breakpoint($xs) {
    margin: 0 0 5px;
  }
}

.brand__lockup a {
  border: none;
}

/* =Image Panes
----------------------------------------------------------*/

.pane-bundle-image img {
  width: 100%;
  height: auto;
}


/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

/* =Autocomplete
----------------------------------------------------------*/

.wcm-autocomplete {
  padding: 10px;
  border: 1px solid $wcm-border-gray;
  li a {
    border: none;
  }
  .ui-autocomplete-category {
    text-transform: capitalize;
    color: $wcm-dark-orange;
    font-weight: 700;
  }
  .ui-state-focus {
    background: $wcm-dark-orange;
    color: $wcm-white;
    border-color: $wcm-dark-orange;
  }
}

.loader {
  background: url(../images/ajax-loader.gif) bottom center no-repeat;
  display: none;
  width: 20px;
  height: 20px;
}

/* =Buttons
----------------------------------------------------------*/

.btn--minor {
  font-size: 13px;
  font-weight: 400;
  border-radius: 10px;
  border-color: $wcm-dark-orange;
  border: 1px solid $wcm-dark-orange;
  &:hover {
    background: $wcm-red;
    border: 1px solid $wcm-red;
  }
}

/* =Social Media Links
----------------------------------------------------------*/

.wcm-graduation-social-cta {
  text-align: center;
}

.wcm-social-media-icons {
  position: relative;
  margin: 0 0 10px;
  @include clearfix();
  h4 {
    margin: 10px 0;
  }
  > div {
    display: inline-block;
  }
  .wcm-cta__tile {
    width: 46px;
    height: 46px;
    border: none;
    margin-right: 5px;
  }
  .wcm-cta__tile .cta-icon {
    width: 35px;
    height: 35px;
  }
  .wcm-cta__detail {
    padding-top: 6px;
  }
  .wcm-edu-external-link:after {
    display: none;
  }
}

/* =Embeds
----------------------------------------------------------*/

/* Responsive maps, etc */
.embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* =Misc
----------------------------------------------------------*/

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.mask {
  @include breakpoint($sm) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.45);
  }
}

.patient-care-callout-sidebar {
  border-top: 1px solid $wcm-border-gray;
  border-bottom: 2px solid $wcm-border-gray;
  text-align: center;
  padding: 20px 0;
  a {
    border: none;
    &.external-link:after {
      content: '';
      display: none;
    }
  }

  .cta-icon {
    margin-top: 10px;
    width: 40px;
    height: 40px;
    fill: $wcm-bright-orange;
    color: $wcm-dark-orange;
  }
}

.tabledrag-handle {
  border-bottom: none;
  &:hover {
    border-bottom: none;
  }
}

iframe {
  border: none;
}

.general-intro-text {
  text-align: center;
  font-size: large;
  @include breakpoint($sm) {
    padding: 0 100px 20px;
  }
}

.mini-site-menu-wrapper {
  margin-bottom: 10px;
}
.mini-site-title {
  font-family: $wcm-regular;
  font-weight: normal;
  font-size:50px;
  line-height: 60px;
  margin-top: 45px;
  margin-bottom: 25px;
  color: $wcm-red;
  text-align:center;
}
html {
  scroll-behavior: smooth;
}