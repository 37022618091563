/**
 * Styles targeting the Priority Highlights / Focus Area content type
 *
 */

 /* =Priority Highlights - Teasers
 ----------------------------------------------------------*/

.highlights {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $wcm-med-gray;
  @include clearfix();
  @include breakpoint($sm) {
    display: flex;
  }
}

.highlights-mobile-nav {
  background: rgba(207,69,32,.9);
  z-index: 12;
  padding: 10px 30px 20px;
  position: relative;
  @include clearfix();
  @include breakpoint($sm) {
    display: none;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: rgba(207,69,32,.9) transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    right: 50%
  }
  .wayfinder--highlights {
    width: 80%;
    margin: 0 auto;
    button {
      width: 100%;
      text-align: left;
    }
    .selection-arrow span {
      top: 5px;
    }
    .highlights__nav--sm.dropdown-menu {
      width: 90%;
      a {
        border-bottom: none;
      }
    }
  }
}

.highlights__nav {
  display: none;
  @include breakpoint($sm) {
    display: block;
    float: left;
    width: 33.33333%;
    margin: 0;
    font-size: 18px;
    background: $wcm-dark-orange;
    color: $wcm-white;
    opacity: 0.9;
    z-index: 10;
    padding: 40px 30px;
  }
  @include breakpoint($md) {
    padding: 60px 30px 60px 60px;
  }
  li {
    list-style-type: none;
    padding: 0;
    border: 1px dotted;
    border-width: 1px 0 0;
    position: relative;
    @include breakpoint($md) {
      padding: 0;
    }
  }
  li:last-child {
    border-width: 1px 0;
  }
  a {
    display: block;
    padding: 10px 5px;
    border: none;
    color: $wcm-white;
    text-align: right;
    &.active,
    &:hover {
      background: $wcm-red;
    }
  }
}

.highlights__nav a.active {
  &:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent $wcm-dark-orange;
    position: absolute;
    top: 15px;
    right: -40px;
  }
}

.highlights__teaser {
  display: none;
  position: relative;
  z-index: 11;
  cursor: pointer;
  @include breakpoint($xs) {
    min-height: 250px;
    padding: 50px;
  }
  @include breakpoint($sm) {
    float: left;
    width: 66.66666%;
  }
}

// Show first teaser on load
.highlights__teaser:nth-of-type(2) {
  display: block;
}

.highlights__text {
  color: $wcm-white;
  @include breakpoint($xs) {
    text-align: center;
  }
  @include breakpoint($sm) {
    width: 75%;
    padding: 0 0 0 60px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%); /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: translateY(-50%); /* IE 9 */
    transform: translateY(-50%); /* IE 10, Fx 16+, Op 12.1+ */
  }
}

.highlights__title {
  font-size: 42px;
  font-family: $wcm-bold;
  line-height: 1.2;
  padding: 0 0 10px;
  @include breakpoint($sm) {
    text-align: left;
  }
}

.highlights__title:after {
  @include breakpoint($xs) {
    content: '\e80d';
    padding-left: 10px;
    font-size: 40%;
    vertical-align: middle;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
    color: $wcm-yellow;    
  }
}

.highlights__desc {
  font-size: 18px;
}

.highlights__indicator {
  display: none;
  @include breakpoint($sm) {
    display: block;
    position: absolute;
    height: 50%;
    top: 25%;
    right: 5%;
    border-left: 1px dotted $wcm-yellow;
    padding-left: 30px;
    z-index: 10;
    opacity: 0.7;
    transition: all 0.2s ease 0s;
  }
  &:after {
    content: "";
    position: relative;
    top: 25%;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 40px 40px;
    border-color: transparent transparent transparent $wcm-yellow;
  }
}

.highlights .mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.45);
  @include breakpoint($md) {
    transition: all 0.2s ease 0s;
  }
}

.highlights.is-hover {
  .highlights__indicator {
    @include breakpoint($md) {
      opacity: 1.0;
    }
  }
  .mask {
    @include breakpoint($md) {
      background: rgba(0,0,0,0.6);
    }
  }
}

/* =Priority Highlights - Full Node
 ----------------------------------------------------------*/
 
.focus-area__hero {
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
}

.focus-area__no-hero {
  position: relative;
  background: $wcm-med-gray;
  min-height: 350px;
}

.focus-area__title {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 32px;
  font-family: $wcm-bold;
  line-height: 1.2;
  color: $wcm-white;
  text-align: center;
  z-index: 10;
  @include center();
  @include breakpoint($sm) {
    font-size: 52px;
  }
  @include breakpoint($md) {
    font-size: 60px;
  }
}

.focus-area__short-description {
  background: $wcm-dark-orange;
  color: $wcm-white;
  text-align: center;
  font-size: 18px;
  line-height: 1.4;
  padding: 15px 40px;
  @include breakpoint($md) {
    font-size: 24px;
    padding: 15px 60px;
  }
}

.focus-area__description {
  background: $wcm-bg-gray;
  // color: $wcm-dark-gray;
  text-align: center;
  // font-size: 16px;
  line-height: 1.5;
  padding: 3% 6%;
  /*@include breakpoint($sm) {
    font-size: 18px;
  }
  @include breakpoint($md) {
    font-size: 20px;
  }
  @include breakpoint($lg) {
    font-size: 22px;
  }*/

  .focus-area__description-content {
    max-width: 1180px;
    margin: 0 auto;
    font-size: large;
    @include breakpoint($sm) {
      padding: 0 17%;
    }
  }
}

.focus-area__hero .mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.45);
}

/* =Focus Area/Priority Highlight Landing Index
----------------------------------------------------------*/

.highlight {
  text-align: center;
  margin: 0 0 40px;
}

.highlight__image {
  overflow: hidden;
  a {
    display: block;
  }
  img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease 0s;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.highlight__title {
  font-size: 24px;
  font-family: $wcm-bold;
  color: $wcm-dark-orange;
  a {
    color: $wcm-red;
    border: none;
    &:hover {
      color: $wcm-bright-orange;
      &:before {
        text-decoration: none;
      }
    }
    &:after {
      content: '' !important;
      display: none !important;
    }
  }
  a span {
    display: block;
    position: relative;
    top: -25px;
    margin: 0 auto -25px;
  }
  a span:before {
    content: '\e802';
    width: 50px;
    height: 50px;
    color: $wcm-bright-orange;
    background: $wcm-white;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    @include fontello();
    line-height: 50px;
    display: inline-block;
    @include transition(background-color .25s);
  }
  a.external-link span:before {
    content: '' !important;
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 15px 15px;
    margin-left: 6px;
    position: relative;
    top: 2px;
  }
  a:hover span:before {
    text-decoration: none;
  }
}

.highlight__description {
  font-size: 20px;
  color: $wcm-med-gray;
}

