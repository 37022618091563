/**
 * Styles targeting the Paragraph bundles used on the site
 *
 */

/* =Sections
----------------------------------------------------------*/

.paragraph-section__title {
  border-top: 5px solid $wcm-red;
  text-align: center;
  span {
    display: inline-block;
    background: $wcm-red;
    color: $wcm-white;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: -1px 0 0;
    padding: 5px 20px;
    letter-spacing: 2px;
    @include breakpoint($md) {
      margin: 0;
    }
  }
}

/* =Tile Callouts
----------------------------------------------------------*/

.tile-callout {
  margin: 20px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid $wcm-border-gray;
  a.external-link:after {
    @include wcm-external-icon();
  }
  @include breakpoint($sm) {
    border: none;
    padding-bottom: 0;
  }
}

.tile-callout:nth-of-type(3n) {
  clear: left;
}

.tile-callout__title {
  a {
    color: $wcm-red;
    &:hover {
      color: $wcm-bright-orange;
      text-decoration: underline;
    }
  }
}

/*.tile-callout__description {
  color: $wcm-med-gray;
  @include breakpoint($md) {
    font-size: 18px;
    line-height: 1.4;
  }
}*/

.tile-callout__photo {
  overflow: hidden;
}

.tile-callout__photo img {
  transition: all 0.3s ease 0s;
  &:hover {
    transform: scale(1.1);
  }
}

/* =Card Callouts
----------------------------------------------------------*/

.paragraphs-items-field-card-callout {
  @include breakpoint($md) {
    display: flex;
  }
}

.card-callout {
  margin: 20px 0;
  padding-bottom: 20px;
  @include breakpoint($md) {
    border: none;
    padding-bottom: 0;
    overflow: hidden;
  }
}

.card-callout__wrapper {
  background: $wcm-bg-gray;
  @include breakpoint($md) {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
  }
}

.card-callout__text {
  padding: 20px;
}

.card-callout__title {
  margin: 0 0 10px;
  text-align: center;
  a {
    color: $wcm-red;
    &:hover {
      color: $wcm-bright-orange;
      text-decoration: underline;
      &:before {
        text-decoration: none;
      }
    }
    &:after {
      content: '' !important;
      display: none !important;
    }
  }
  a span {
    display: block;
    position: relative;
    top: -40px;
    margin: 0 auto -40px;
  }
  a span:before {
    content: '\e802';
    width: 50px;
    height: 50px;
    color: $wcm-bright-orange;
    background: $wcm-bg-gray;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    @include fontello();
    line-height: 50px;
    display: inline-block;
    @include transition(background-color .25s);
  }
  a.external-link span:before {
    content: '' !important;
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 15px 15px;
    margin-left: 6px;
    position: relative;
    top: 2px;
  }
  a:hover span:before {
    text-decoration: none;
  }
}

.card-callout__description {
  font-size: 15px;
  text-align: center;
}

.card-callout__image {
  overflow: hidden;
}

.card-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
  &:hover {
    transform: scale(1.1);
  }
}

.card-callout__cta {
  font-size: 14px;
  a {
    border: none;
  }
}

.card-callout__cta a:nth-child(1) {
  padding-right: 5px;
}

.card-callout__cta a:nth-child(2) {
  border-left: 1px solid $wcm-border-gray;
  padding-left: 7px;
}

/* =Bar Callouts
----------------------------------------------------------*/

.bar-callout .row.is-flex {
  @include breakpoint($xs) {
    display: block;
  }
}

.bar-panel--title {
  a {
    &:after {
      content: '';
    }
  }
  @include breakpoint($sm) {
    background: $wcm-bg-gray;
  }
}

.bar-panel--title--linked {
  cursor: pointer;
}

.bar-callout .row {
  &:hover {
    .bar-callout__image img {
        transform: scale(1.1);
    }
  }
}

.bar-callout__text {
  margin-bottom: 20px;
  
  @include breakpoint($sm) {
    text-align: center;
    @include center();
    width: 70%;
    margin: 0;
    font-size: 13px;
  }
  @include breakpoint($md) {
    font-size: 15px
  }
}

.bar-callout__title {
  font-size: 15px;
  @include breakpoint($md) {
    font-size: 18px;
  }
  a {
    color: $wcm-red;
    &:hover {
      // color: $wcm-bright-orange;
      text-decoration: underline;
    }
  }
}

.bar-callout__description {
  color: $wcm-med-gray;
  margin: 5px 0 0;
  @include breakpoint($md) {
    font-size: 18px;
    line-height: 1.6;
  }
  &:hover {
    text-decoration: underline;
  }
}

.bar-callout__image {
  overflow: hidden;
  a {
    display: block;
    border: none;
    &:hover {
      border: none;
    }
  }
}

.bar-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
  &:hover {
    transform: scale(1.1);
  }
}

/* =Video Callouts
----------------------------------------------------------*/

.featured-video-callout__wrapper .row,
.video-bar-callout .row {
  @include breakpoint($md) {
    display: flex;
    flex-wrap: wrap;
    &:before {
      display: block;
    }
  }
}

.featured-video-callout__wrapper .row > [class*='col-'],
.video-bar-callout .row > [class*='col-'] {
  @include breakpoint($md) {
    display: flex;
    flex-direction: column;
  }
}

.embed-container {
  position: relative;
  overflow: hidden;
    &:after {
      content: '';
      display: block;
      /* intrinsic aspect ratio */
      padding-bottom: 56.25%;
      height: 0;
  }
} 

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wcm-video__embed {
  background-color: $wcm-med-gray;
  position: relative;
  cursor: pointer;
  @include breakpoint($md) {
    margin-bottom: 30px;
  }
  &:after {
    content: '';
    display: block;
    /* intrinsic aspect ratio */
    padding-bottom: 56.25%;
    height: 0;
  }
}
.wcm-video__embed img {
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
}
.wcm-video__embed .wcm-video__play {
    width: 90px;
    height: 60px;
    background-color: $wcm-red;
    box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
}
.wcm-video__embed .wcm-video__play:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26.0px;
    border-color: transparent transparent transparent #fff;
}
.wcm-video__embed img,
.wcm-video__embed .wcm-video__play {
    cursor: pointer;
}
.wcm-video__embed img,
.wcm-video__embed iframe {
  @include breakpoint($md) {
    position: absolute;
  }
}
.wcm-video__embed .wcm-video__play,
.wcm-video__embed .wcm-video__play:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d( -50%, -50%, 0 );
}
.wcm-video__embed iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.video-bar-callout__title {
  margin-bottom: 20px;
  @include breakpoint($md) {
    @include center();
    width: 70%;
    margin: 0;
  }
  a {
    color: $wcm-dark-orange;
    &:hover {
      color: $wcm-red;
      text-decoration: none;
    }
  }
}

.featured-video-callout {
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0 20px;
  @include breakpoint($md) {
    padding: 40px 0;
  }
  .paragraph-section__title {
    background: rgba(0,0,0,0.5);
  }
}

.featured-video-callout__wrapper {
  padding: 30px;
  background: rgba(0,0,0,0.5);
}

.featured-video__description {
  color: $wcm-bg-gray;
  margin: 20px 0;
  @include breakpoint($md) {
    font-size: 18px;
    line-height: 1.8;
    @include center();
    width: 70%;
    margin: 0;
  }
}

.embed-container {
    background-color: #000;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      .play-button {
        background-color: $wcm-red;
        opacity: 1;
      }
    }
}
.embed-container img {
    width: 100%;
    top: -16.82%;
    left: 0;
}
.embed-container .play-button {
    width: 90px;
    height: 60px;
    background-color: $wcm-dark-gray;
    box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
    transition: all 0.3s ease 0s;
}
.embed-container .play-button:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26.0px;
    border-color: transparent transparent transparent #fff;
}
.embed-container img,
.embed-container .play-button {
    cursor: pointer;
}
.embed-container img,
.embed-container iframe,
.embed-container .play-button,
.embed-container .play-button:before {
    position: absolute;
}
.embed-container .play-button,
.embed-container .play-button:before {
    top: 50%;
    left: 50%;
    transform: translate3d( -50%, -50%, 0 );
}
.embed-container iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

/* =Metrics
----------------------------------------------------------*/

.paragraphs-item-metric-section {
  padding: 20px 0;
  background: $wcm-bg-gray;
  @include clearfix();
  @include breakpoint($sm) {
    padding: 40px 0;
  }
}

.metric {
  text-align: center;
  margin: 0 0 20px;
}

.metric:nth-of-type(5n) {
  clear: left;
}

.metric__value {
  display: inline-block;
  font-size: 40px;
  line-height: 1.0;
  font-family: $wcm-bold;
  color: $wcm-dark-gray;
  border-bottom: 1px solid $wcm-dark-orange;
  @include breakpoint($md) {
    font-size: 48px;
  }
  @include breakpoint($lg) {
    font-size: 58px;
  }
}

.metric__description {
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
  color: $wcm-med-gray;
  padding: 10px 0;
  @include breakpoint($md) {
    padding: 10px 20px;
  }
}

/* =Quotes
----------------------------------------------------------*/

.paragraphs-item-quote-section {
  background: $wcm-bg-gray; // url(../images/quote-bg.jpg) top center no-repeat;
  // background-size: cover;
  margin: 20px 0;
  padding: 0 0 20px;
}

.quote {
  margin: 20px 0;
}

.quote:nth-child(odd) {
  clear: left;
}

.quote__image {
  a {
    border: none;
  }
  img {
  border-radius: 50%;
  border: 2px solid $wcm-white;
  padding: 3px;
    @include breakpoint($xs) {
      width: 120px;
      height: auto;
    }
  }
}

.quote__title {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 700;
  // color: $wcm-dark-gray;
  margin-bottom: 10px;
}

.quote__description {
  line-height: 1.65;
  font-style: italic;
  color: $wcm-dark-gray;
  // @include breakpoint($md) {
  //   font-size: 16px;
  // } 
  .cta-icon {
    width: 23px;
    height: 23px;
    fill: $wcm-bright-orange;
    color: $wcm-dark-orange;
    float: left;
    margin-right: 10px;
    // @include breakpoint($md) {
    //   width: 23px;
    //   height: 23px;
    // }
  }
}

.quote__more {
  // font-weight: 700;
  // text-transform: uppercase;
  font-size: 14px;
  margin: 10px 0 0;
  a {
    &:after {
      content: '\e80d';
      color: $wcm-bright-orange;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
  }
}

/* =Link Lists
----------------------------------------------------------*/

.paragraphs-item-link-list-section {
  padding: 30px 0;
}

.link_list__title {
  font-size: 16px;
  font-weight: 700;
  color: $wcm-dark-gray;
  margin: 0 0 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.field-link-list-link {
  @include breakpoint($md) {
    font-size: 18px;
  }
  ul {
    margin: 0 0 0 20px;
    padding: 0;
  }
  li {
    padding: 0 10px 5px;
  }
  a {
    color: $wcm-red;
    &:hover {
      color: $wcm-red;
    }
  }
  a.external-link:after {    
    @include wcm-external-icon();
  }
}

/* =Quick Links
----------------------------------------------------------*/

.quick-links {
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0 0px;
  @include breakpoint($md) {
    padding: 0 0 40px;
  }
}

.quick-links .paragraph-section__title {
  margin: 0 0 40px;
}

.quick-links__wrapper {
  text-align: center;
  @include breakpoint($sm) {
    text-align: left;
  }
  @include breakpoint($md) {
    font-size: 18px;
  }
  ul {
    padding: 0 0 30px;
    @include breakpoint($sm) {
      column-count: 2;
      column-gap: 150px;
      margin: 0 0 40px;
    }
    @include breakpoint($lg) {
      column-gap: 250px;
    }
  }
  li {
    list-style-type: none;
    border-top: 1px solid #ccc;
    padding: 10px 0;
    a {
      border: none;
    }
  }
  .external-link:after {
    @include wcm-external-icon();
  }
}

/* =Quick Text
----------------------------------------------------------*/

.quick-text {
  padding: 0 0 30px;
  background-repeat: no-repeat;
  background-size: cover;
  .external-link:after {
    @include wcm-external-icon();
  }
  li {
    list-style-type: none;
    padding: 0 0 10px;
    text-align: center;
    a {
      font-size: 18px;
    }  
    a:after {
      content: '\e80d';
      color: $wcm-bright-orange;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
  }
}

.quick-text .paragraph-section__title {
  margin: 0 0 30px;
}

.quick-text__wrapper {
  max-width: 760px;
  margin: 0 auto;

  ul {
    padding-left: 0;
  }
}

/* =Icon Callouts
----------------------------------------------------------*/

.icon-callout {
  margin: 30px 0;
}

.icon-callout__link {
  text-align: center;
  @include breakpoint($sm) {
    //padding: 40px 30px;
    margin: 0;
  }
}

.icon-callout__link a {
  position: relative;
  display: block;
  z-index: 10;
  padding: 50px 30px;
  border: none;
  // Put the box shadow on a psuedo-element
  // http://tobiasahlin.com/blog/how-to-animate-box-shadow/
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
  }
  &:hover {
    z-index: 1000;
    text-decoration: none;
    transform: scale(1.1, 1.1);
    border-width: 1px;
    background: $wcm-white;
  }
  &:hover:after {
    opacity: 1;
  }
}

.icon-callout__detail {
  width: 80%;
  @include center();
}

.icon-callout__link a span {
  display: block;
  text-align: center;
}

.icon-callout__link.col-sm-12 a {
  border: none;
}

.icon-callout__link.col-sm-6:nth-child(1) a {
  @include breakpoint($sm) {
    border-right: 1px solid $wcm-border-gray;
  }
}

.icon-callout__link.col-sm-4:nth-child(1) a,
.icon-callout__link.col-sm-4:nth-child(2) a {
  @include breakpoint($sm) {
    border-right: 1px solid $wcm-border-gray;
  }
}

.icon-callout__link.col-sm-3:nth-child(1) a,
.icon-callout__link.col-sm-3:nth-child(2) a,
.icon-callout__link.col-sm-3:nth-child(3) a {
  @include breakpoint($sm) {
    border-right: 1px solid $wcm-border-gray;
  }
}

.icon-callout__icon .cta-icon {
  width: 40px;
  height: 40px;
  fill: #e87722;
  color: #cf4520;
}

.icon-callout__text > a.external-link:after,
.icon-callout__icon > a.external-link:after {
  content: '';
  padding-left: 0;
  background: none;
}

/* =Event Callouts
----------------------------------------------------------*/
.event-callout {
  background: $wcm-bg-gray;
  margin-top: 20px;
  margin-bottom: 20px;

  .event-callout__wrapper {
    text-align: center;
    font-size: 17px;
    padding: 25px 20px;

    .event-callout__date {
      background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/calendar.svg');
      padding-left: 35px;
      padding-right: 20px;
      padding-top: 1px;
      padding-bottom: 1px;
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: center left;
      border-right: 1px solid $wcm-bright-orange;
    }

    .event-callout__time {
      background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/appointment.svg');
      padding-left: 35px;
      margin-left: 20px;
      padding-top: 1px;
      padding-bottom: 1px;
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: center left;
    }

    .event-callout__location {
      background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/location.svg');
      display: inline-block;
      padding-left: 35px;
      margin-top: 10px;
      padding-bottom: 2px;
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: left 5px;
    }
  }
}

/* =Event Callouts
----------------------------------------------------------*/
.generic-callout {
  background: $wcm-bg-gray;
  margin-top: 20px;
  margin-bottom: 20px;

  .generic-callout__wrapper {
    text-align: center;
    padding: 25px 20px;

    .btn {
      padding: 10px 20px;
      margin-top: 20px;
      width: auto;
      min-width: 220px;
      display: inline-block;

      &:before {
        line-height: 2.5;
      }

      &:hover, &:focus {
        background-color: $wcm-dark-orange;
      }
    }
  }
}

/* =Gallery
----------------------------------------------------------*/
.fancybox-nav span {
  visibility: visible;
}

.paragraphs-items-field-gallery-media-files {
  @include clearfix();
  font-size: 13px;
  margin-left: -20px;
  margin-right: -20px;

  .media-file-caption {
    position: relative;
    margin-top: 20px;
  }

  h2 {
    padding-left: 20px;
    margin-bottom: 10px;
  }

  .media-files-filter {
    padding-left: 20px;
    margin-bottom: 20px;

    input[type=radio] {
      cursor: pointer;
    }
    input[type=radio] + label {
      color: $wcm-dark-orange;
    } 
    input[type=radio]:checked + label {
      color: $wcm-black;
    }
    label {
      margin-right: 20px;
      padding-left: 5px;
      cursor: pointer;
    }
  }

  .media-files-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }

  .media-file-wrapper {
    display: flex;
    flex-direction: column;

    transition: all 0.3s ease;
    margin-bottom: 20px;

    @include breakpoint($xs) {
      width: 100%;

      img {
        width: 100%;
      }
    }

    &.media-file-hidden {
      opacity: 0;
      width: 0;
      padding: 0;
      transform: scale(0, 0);
      transform-origin: center center;
    }

    a {
      position: relative;
      display: block;
      border: none;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba( 0,0,0,0.45 );
        display: block;
        color: $wcm-white;
        text-align: center;
        padding-top: 32%;
        font-size: 15px;
        font-weight: 700;
        opacity: 0;
        transition: all 0.3s ease;
      }

      &:after {
        content: '';
        bottom: -23px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: $wcm-white;
        border-radius: 50%;
        position: absolute;
        width: 50px;
        height: 50px;
        background-size: 25px 25px;
        background-repeat: no-repeat;
        background-position: center center;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
  }
  .media-video {
    a {
      &:before {
        content: 'SHOW VIDEO';
      }
      &:after {
        background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/video.svg');
      }
    }
  }
  .media-image {
    a {
      &:before {
        content: 'SHOW IMAGE';
      }
      &:after {
        background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/slideshow.svg');
      }
    }
  }
}
