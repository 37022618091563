@charset "UTF-8";
/**
 * WCM EDU
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/**
 * Styles targeting the Main Header
 *
 */
/* =Branding
----------------------------------------------------------*/
.brand img {
  max-width: 280px;
  height: auto; }

/* =Pinned Header
----------------------------------------------------------*/
@media screen and (min-width: 992px) {
  .main-menu-toggle {
    border: none; } }

.wcm-global-links li.first {
  background: #fff;
  border: none; }
  .wcm-global-links li.first:hover {
    background: #b31b1b; }
    .wcm-global-links li.first:hover a {
      color: #fff; }
  .wcm-global-links li.first a {
    color: #cf4520;
    border: none; }
    .wcm-global-links li.first a:focus {
      color: #fff; }

/**
 * Styles targeting the Site Footer
 *
 */
/* =Footer Contact
----------------------------------------------------------*/
.footer-contact__site-name a {
  top: 55%; }

/* =Footer Map Link
----------------------------------------------------------*/
.footer-contact__map-link a {
  text-align: center;
  background-color: transparent !important;
  color: #fff; }
  @media screen and (max-width: 767px) {
    .footer-contact__map-link a {
      width: 40%;
      height: auto;
      margin: 6% 1% 6% 10%;
      padding: 15px 0;
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      line-height: 13px;
      border: 1px solid #fff; } }
  @media screen and (min-width: 768px) {
    .footer-contact__map-link a {
      height: 40px;
      margin: 10px 0 0;
      border-right: 1px solid #fff; } }
  @media screen and (min-width: 992px) {
    .footer-contact__map-link a {
      margin-right: 20px; } }
  .footer-contact__map-link a:before {
    font-size: 30px; }
    @media screen and (max-width: 767px) {
      .footer-contact__map-link a:before {
        display: none; } }
  .footer-contact__map-link a:hover {
    color: #ffc72c;
    border-right: 1px solid #fff; }
    @media screen and (max-width: 767px) {
      .footer-contact__map-link a:hover {
        background-color: #e7751d !important;
        color: #fff; } }
  .footer-contact__map-link a:focus {
    color: #fff; }

/* =Footer Contact Us
----------------------------------------------------------*/
.footer-contact__contact-us a {
  position: relative;
  display: block;
  float: left;
  height: 60px;
  margin: 0;
  padding: 0;
  color: #fff !important;
  text-align: center;
  font-family: "1898Sans-Bold", sans-serif;
  border-left: 2px solid #cf4520 !important;
  background-color: transparent !important; }
  @media screen and (max-width: 767px) {
    .footer-contact__contact-us a {
      width: 40%;
      height: auto;
      margin: 6% 0% 6% 1%;
      padding: 15px 0;
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      line-height: 13px;
      border: 1px solid #fff !important; } }
  @media screen and (min-width: 768px) {
    .footer-contact__contact-us a {
      width: 60px;
      font: 0/0 a;
      color: transparent;
      text-shadow: none;
      background-color: transparent;
      border: 0; } }
  @media screen and (min-width: 992px) {
    .footer-contact__contact-us a {
      display: none; } }
  .footer-contact__contact-us a:before {
    content: '\e80c';
    width: 60px;
    color: #fff;
    font-size: 30px;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: translateY(-50%);
    /* IE 9 */
    transform: translateY(-50%);
    /* IE 10, Fx 16+, Op 12.1+ */ }
    @media screen and (max-width: 767px) {
      .footer-contact__contact-us a:before {
        display: none; } }
    @media screen and (min-width: 992px) {
      .footer-contact__contact-us a:before {
        display: none; } }
  .footer-contact__contact-us a:hover {
    background-color: #e7751d !important;
    color: #fff !important; }
    .footer-contact__contact-us a:hover:before {
      color: #fff; }

/* =Footer Social Links
 ----------------------------------------------------------*/
@media screen and (max-width: 767px) {
  .footer-contact__social-links {
    clear: both;
    background: #fff;
    text-align: center;
    padding: 10px 0 0; } }

@media screen and (min-width: 768px) {
  .footer-contact__social-links {
    display: block;
    float: right;
    height: 60px; } }

@media screen and (max-width: 767px) {
  .footer-contact__social-links li {
    padding: 0 8px; } }

.footer-contact__social-links a {
  color: #cf4520; }
  @media screen and (min-width: 768px) {
    .footer-contact__social-links a {
      color: #fff; } }
  @media screen and (max-width: 767px) {
    .footer-contact__social-links a:before {
      font-size: 34px; } }

.footer-contact__social-links a:hover:before {
  color: #ffc72c; }

/* =Footer Navigation
 ----------------------------------------------------------*/
.footer-nav {
  width: auto;
  float: none;
  padding: 0;
  margin: 0;
  text-align: left; }
  @media screen and (min-width: 992px) {
    .footer-nav {
      width: 82%;
      float: left;
      padding-left: 40px;
      margin: 0 0 20px; } }
  .footer-nav a.external-link:after {
    content: ''; }

.footer-nav .level-1 {
  border-bottom: 1px solid #dddddd;
  margin: 0 0 10px; }
  @media screen and (min-width: 992px) {
    .footer-nav .level-1 {
      width: 16.66667%;
      float: left;
      margin: 0;
      padding: 0 10px 5px;
      border: none; } }

.footer-menu__toggle:after {
  width: 20px;
  height: 20px;
  content: '+';
  color: #cf4520;
  font-weight: 700;
  float: right;
  font-size: 18px;
  line-height: 18px; }
  @media screen and (min-width: 992px) {
    .footer-menu__toggle:after {
      display: none; } }

.footer-menu__section--open .footer-menu__toggle:after {
  content: '–'; }

.footer-nav .level-1:last-child {
  border: none; }

.footer-nav .footer-menu__section {
  display: block;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase; }

.footer-nav .level-2 {
  padding: 0 0 5px; }

/* =Footer Site Info
----------------------------------------------------------*/
@media screen and (min-width: 768px) {
  .footer-site-info {
    padding: 0 0 30px; } }

.footer-address {
  display: none; }
  @media screen and (min-width: 992px) {
    .footer-address {
      display: block; } }

.footer-address__address .btn {
  margin-top: 10px; }
  .footer-address__address .btn:hover {
    background: #b31b1b;
    border: 1px solid #b31b1b; }
  .footer-address__address .btn:focus {
    background: #b31b1b;
    border: 1px solid #b31b1b; }

/* =Footer Patient Care CTA
----------------------------------------------------------*/
.footer-patient-care {
  display: none; }
  @media screen and (min-width: 992px) {
    .footer-patient-care {
      display: block;
      border: 5px solid #cf4520;
      border-width: 0 0 5px;
      font-size: 14px;
      margin: 0 auto;
      padding: 10px 0;
      position: relative;
      text-align: center; } }
  .footer-patient-care a {
    font-weight: 700;
    border-bottom: 1px dotted; }
    .footer-patient-care a:hover {
      border-bottom: 1px solid;
      text-decoration: none; }

/* =Footer Statement
----------------------------------------------------------*/
.footer-statement {
  display: none; }
  @media screen and (min-width: 992px) {
    .footer-statement {
      display: block;
      margin: 30px 0;
      padding: 0 60px 30px;
      border-bottom: 1px solid #dddddd;
      font-size: 14px;
      color: #666666;
      text-align: center; } }
  .footer-statement a {
    font-weight: 700;
    border-bottom: 1px dotted; }
    .footer-statement a:hover {
      border-bottom: 1px solid;
      text-decoration: none; }

/* =Footer Partnership Logos
----------------------------------------------------------*/
.footer-partnership {
  text-align: center; }
  .footer-partnership:before, .footer-partnership:after {
    content: " ";
    display: table; }
  .footer-partnership:after {
    clear: both; }
  @media screen and (max-width: 767px) {
    .footer-partnership {
      border-top: 5px solid #cf4520;
      padding: 20px 0 0; } }
  @media screen and (min-width: 768px) {
    .footer-partnership {
      margin: 20px 0 0; } }
  @media screen and (min-width: 992px) {
    .footer-partnership {
      text-align: left; } }

.footer-partnership__logo {
  display: block;
  color: #666666;
  font-size: 12px;
  padding: 5px 0;
  text-decoration: underline;
  text-decoration-style: dotted; }
  @media screen and (min-width: 768px) {
    .footer-partnership__logo {
      display: inline-block;
      text-decoration: none;
      padding: 0;
      border-bottom: 1px dotted; } }
  @media screen and (min-width: 992px) {
    .footer-partnership__logo {
      display: block;
      width: 25%;
      float: left;
      font: 0/0 a;
      color: transparent;
      text-shadow: none;
      background-color: transparent;
      border: 0; }
      .footer-partnership__logo:after {
        content: ''; } }

.footer-partnership__decorator {
  display: none; }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .footer-partnership__decorator {
      display: inline; } }

@media screen and (min-width: 992px) {
  .footer-partnership__wcmed {
    height: 45px;
    background: url(../images/footer-logos/wcm_footer_logos_wcm_logo.svg) no-repeat center center;
    background-size: 235px 24px; } }

@media screen and (min-width: 1200px) {
  .footer-partnership__wcmed {
    background-size: 250px 24px; } }

@media screen and (min-width: 992px) {
  .footer-partnership__cornell {
    height: 51px;
    background: url(../images/footer-logos/wcm_footer_logos_cu_logo.svg) no-repeat center center;
    background-size: 200px 51px; } }

@media screen and (min-width: 992px) {
  .footer-partnership__nypres {
    height: 45px;
    background: url(../images/footer-logos/wcm_footer_logos_nyp_logo.png) no-repeat center center;
    background-size: 220px 50px; } }

@media screen and (min-width: 768px) {
  .footer-partnership__methodist:after {
    content: ''; } }

@media screen and (min-width: 992px) {
  .footer-partnership__methodist {
    height: 45px;
    background: url(../images/footer-logos/wcm_footer_logos_hm_logo.svg) no-repeat center center;
    background-size: 150px 45px; } }

.footer-links {
  border: none; }
  @media screen and (max-width: 767px) {
    .footer-links {
      padding: 0 0 30px; } }

.footer-links__menu a {
  text-decoration: underline;
  text-decoration-style: dotted; }

/**
 * Component Styles
 *
 */
/* =SiteImprove (accessibility)
 ----------------------------------------------------------*/
a:hover,
a:focus {
  color: #b31b1b; }

#active-third-level-nav .level-3-select a {
  color: #cf4520; }

.footer-contact__site-name a {
  color: #fff; }
  .footer-contact__site-name a:hover {
    color: #fff; }

@media screen and (min-width: 768px) {
  .footer-contact__social-links a {
    color: #fff; }
    .footer-contact__social-links a:hover {
      color: #ffc72c; } }

.social-share a {
  color: #cf4520; }

.btn--wcm:hover, .btn--wcm:focus, .btn--wcm.focus, .btn--wcm:active, .btn--wcm.active,
.open > .btn--wcm.dropdown-toggle {
  color: #fff;
  background-color: #cf4520;
  border-color: #b31b1b;
  border-width: 4px; }

/* =Structure
----------------------------------------------------------*/
.site-main {
  margin: 70px 0 0; }
  @media screen and (min-width: 992px) {
    .site-main {
      margin: 0; } }

/* =Typography
 ----------------------------------------------------------*/
.wcm-edu-external-link:after {
  display: inline-block;
  content: '' !important;
  background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
  margin-left: 6px;
  position: relative;
  top: 2px; }
  @media screen and (min-width: 992px) {
    .wcm-edu-external-link:after {
      background-size: 15px 15px;
      height: 15px;
      width: 15px; } }

.institutional-link:after {
  content: '' !important; }

/* =Branding
----------------------------------------------------------*/
@media screen and (max-width: 767px) {
  .brand__nav {
    margin: 0 0 5px; } }

.brand__lockup a {
  border: none; }

/* =Image Panes
----------------------------------------------------------*/
.pane-bundle-image img {
  width: 100%;
  height: auto; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

/* =Autocomplete
----------------------------------------------------------*/
.wcm-autocomplete {
  padding: 10px;
  border: 1px solid #dddddd; }
  .wcm-autocomplete li a {
    border: none; }
  .wcm-autocomplete .ui-autocomplete-category {
    text-transform: capitalize;
    color: #cf4520;
    font-weight: 700; }
  .wcm-autocomplete .ui-state-focus {
    background: #cf4520;
    color: #fff;
    border-color: #cf4520; }

.loader {
  background: url(../images/ajax-loader.gif) bottom center no-repeat;
  display: none;
  width: 20px;
  height: 20px; }

/* =Buttons
----------------------------------------------------------*/
.btn--minor {
  font-size: 13px;
  font-weight: 400;
  border-radius: 10px;
  border-color: #cf4520;
  border: 1px solid #cf4520; }
  .btn--minor:hover {
    background: #b31b1b;
    border: 1px solid #b31b1b; }

/* =Social Media Links
----------------------------------------------------------*/
.wcm-graduation-social-cta {
  text-align: center; }

.wcm-social-media-icons {
  position: relative;
  margin: 0 0 10px; }
  .wcm-social-media-icons:before, .wcm-social-media-icons:after {
    content: " ";
    display: table; }
  .wcm-social-media-icons:after {
    clear: both; }
  .wcm-social-media-icons h4 {
    margin: 10px 0; }
  .wcm-social-media-icons > div {
    display: inline-block; }
  .wcm-social-media-icons .wcm-cta__tile {
    width: 46px;
    height: 46px;
    border: none;
    margin-right: 5px; }
  .wcm-social-media-icons .wcm-cta__tile .cta-icon {
    width: 35px;
    height: 35px; }
  .wcm-social-media-icons .wcm-cta__detail {
    padding-top: 6px; }
  .wcm-social-media-icons .wcm-edu-external-link:after {
    display: none; }

/* =Embeds
----------------------------------------------------------*/
/* Responsive maps, etc */
.embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }

.embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/* =Misc
----------------------------------------------------------*/
.row.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0; }

@media screen and (min-width: 768px) {
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.45); } }

.patient-care-callout-sidebar {
  border-top: 1px solid #dddddd;
  border-bottom: 2px solid #dddddd;
  text-align: center;
  padding: 20px 0; }
  .patient-care-callout-sidebar a {
    border: none; }
    .patient-care-callout-sidebar a.external-link:after {
      content: '';
      display: none; }
  .patient-care-callout-sidebar .cta-icon {
    margin-top: 10px;
    width: 40px;
    height: 40px;
    fill: #e7751d;
    color: #cf4520; }

.tabledrag-handle {
  border-bottom: none; }
  .tabledrag-handle:hover {
    border-bottom: none; }

iframe {
  border: none; }

.general-intro-text {
  text-align: center;
  font-size: large; }
  @media screen and (min-width: 768px) {
    .general-intro-text {
      padding: 0 100px 20px; } }

.mini-site-menu-wrapper {
  margin-bottom: 10px; }

.mini-site-title {
  font-family: "1898Sans-Regular", sans-serif;
  font-weight: normal;
  font-size: 50px;
  line-height: 60px;
  margin-top: 45px;
  margin-bottom: 25px;
  color: #b31b1b;
  text-align: center; }

html {
  scroll-behavior: smooth; }

/**
* Styles related to the Unit Content Type and List View
*
*/
/* =Custom Unit List Filters
----------------------------------------------------------*/
.unit-list-filters {
  background: #f7f7f7;
  margin: 0 0 20px;
  padding: 20px 20px 0; }
  .unit-list-filters .input-group-btn .btn {
    background-color: #cf4520;
    border: 1px solid #cf4520;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 16px;
    height: 39px;
    padding: 6px 15px; }
    .unit-list-filters .input-group-btn .btn:hover {
      background-color: #b31b1b; }
  .unit-list-filters .glyphicon-search {
    font-size: 16px;
    position: relative;
    top: 3px;
    padding-right: 5px; }

.currently-selected {
  margin: 20px 0;
  font-size: 13px; }
  .currently-selected span {
    font-weight: 700;
    padding-right: 5px; }

@media screen and (min-width: 768px) {
  #unit-search {
    display: inline-block;
    margin: 0 0 20px; } }

#unit-filter {
  max-width: none; }

.unit-alpha-filter {
  margin: 20px 0 0;
  padding: 20px 0 0;
  border-top: 1px solid #dddddd; }
  .unit-alpha-filter button {
    border: none;
    background: none;
    color: #b31b1b;
    text-decoration: none;
    border-right: 1px solid #dddddd;
    border-radius: 0px;
    padding: 0 10px;
    margin: 0 0 20px; }
    .unit-alpha-filter button:hover {
      color: #b31b1b;
      text-decoration: underline; }

.unit-alpha-filter button[disabled] {
  color: #666666; }
  .unit-alpha-filter button[disabled]:hover {
    text-decoration: none; }

.unit-alpha-filter .alpha-filter--selected {
  color: #000000;
  font-weight: 700; }

input[type="radio"].primary-toggle {
  margin: 25px 0 0; }

label[for=unit-primary],
label[for=unit-all] {
  font-weight: normal;
  padding: 0 5px; }

/* =Autocomplete
----------------------------------------------------------*/
.wcm-unit-autocomplete {
  padding: 10px;
  border: 1px solid #dddddd; }
  .wcm-unit-autocomplete .ui-autocomplete-category {
    text-transform: capitalize;
    color: #cf4520;
    font-weight: 700; }
  .wcm-unit-autocomplete .ui-state-focus {
    background: #cf4520;
    color: #fff;
    border-color: #cf4520; }

.unit-filter label {
  font-size: 14px; }

.unit-filter-select {
  box-shadow: none;
  border: none; }

.ms-parent {
  width: 100% !important;
  height: 38px; }

.ms-choice {
  height: 38px;
  border: 1px solid #dddddd;
  padding: 6px 0;
  font-size: 13px; }

.ms-choice > div {
  display: none; }

.ms-choice > span {
  position: relative; }

.ms-drop {
  font-size: 13px; }

.ms-drop input[type="checkbox"] {
  margin: 0 5px 0 0; }

.unit-list-no-results {
  display: none; }

.selected-clear {
  display: none;
  border: none;
  background: none;
  color: #cf4520;
  text-decoration: underline; }

/* =Unit Teasers
----------------------------------------------------------*/
.view-unit-list {
  min-height: 200px; }

.js .view-unit-list .unit-teaser {
  display: none; }

.unit-teaser {
  margin: 0; }

.unit-teaser__overview {
  border: 1px solid #dddddd;
  border-width: 0 0 1px; }

.unit-teaser__name {
  font-size: 18px;
  padding: 10px 0 5px; }
  @media screen and (max-width: 767px) {
    .unit-teaser__name {
      line-height: 1.3; } }

.unit-teaser__actions {
  font-size: 14px; }
  @media screen and (min-width: 768px) {
    .unit-teaser__actions {
      text-align: right;
      padding-right: 20px;
      position: absolute;
      top: 50%;
      right: 20px;
      -webkit-transform: translateY(-50%);
      /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
      -ms-transform: translateY(-50%);
      /* IE 9 */
      transform: translateY(-50%); } }

.unit-teaser__cta {
  margin: 0 0 20px; }
  @media screen and (min-width: 768px) {
    .unit-teaser__cta {
      margin: 0; } }
  .unit-teaser__cta a.external-link:after {
    display: inline-block;
    content: '';
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
    margin-left: 6px;
    position: relative;
    top: 2px; }

.unit-teaser__cta,
.unit-teaser__readmore {
  display: inline-block;
  font-size: 13px; }

.unit-teaser__readmore {
  margin: 10px 0; }
  @media screen and (min-width: 768px) {
    .unit-teaser__readmore {
      margin: 0 0 10px; } }
  .unit-teaser__readmore a:after {
    content: '\e80d';
    color: #e7751d;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: middle;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5; }

.unit-teaser__cta {
  display: inline-block;
  font-size: 15px; }

/* =Unit Detail
----------------------------------------------------------*/
.wcm-edu-details-side h3.pane-title {
  margin: 0 0 5px;
  font-size: 16px;
  color: #000000; }

.pane-node-field-unit-cta {
  margin: 0 0 20px; }
  .pane-node-field-unit-cta:before, .pane-node-field-unit-cta:after {
    content: " ";
    display: table; }
  .pane-node-field-unit-cta:after {
    clear: both; }

.pane-node-field-unit-cta-website,
.pane-node-field-unit-cta-care {
  padding: 20px 0 30px; }
  .pane-node-field-unit-cta-website a:before,
  .pane-node-field-unit-cta-care a:before {
    content: '';
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/website.svg");
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 55px;
    position: relative;
    padding-right: 15px;
    top: -6px;
    float: left; }
  .pane-node-field-unit-cta-website a.external-link:after,
  .pane-node-field-unit-cta-care a.external-link:after {
    display: none; }

.pane-node-field-unit-cta-care {
  border-top: 1px solid #dddddd; }
  .pane-node-field-unit-cta-care a:before {
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/patients.svg"); }

/* =Unit News and Events
----------------------------------------------------------*/
.wcm-edu-details-split-section {
  background: #f7f7f7;
  margin: 20px 0;
  padding: 20px 0; }
  .wcm-edu-details-split-section .pane-title {
    font-size: 30px; }

.wcm-edu-details-split-section.wcm-edu-detail--split-none {
  background: none; }

.unit-news__title,
.unit-event__title {
  line-height: 1.4; }
  .unit-news__title a,
  .unit-event__title a {
    color: #b31b1b; }
    .unit-news__title a:hover,
    .unit-event__title a:hover {
      text-decoration: underline; }

.unit-news__date {
  font-size: 14px;
  margin: 2px 0;
  color: #666666; }

.unit-event {
  border-bottom: 1px solid #dddddd; }

.unit-event__date--day {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 20px 0 0; }

.unit-event__date--time {
  font-size: 14px;
  margin: 0 0 10px;
  color: #666666; }

.unit-event__title {
  margin: 0 0 20px; }

.unit-detail-partner h3.pane-title,
.unit-detail-parents h3.pane-title,
.unit-detail-child h3.pane-title {
  font-size: 30px; }

.wcm-unit__view-all {
  font-size: 13px;
  margin: 10px 0 0; }
  @media screen and (min-width: 768px) {
    .wcm-unit__view-all {
      position: absolute;
      top: 20px;
      right: 50px;
      margin: 0; } }

/* =Related Units (on detail page)
----------------------------------------------------------*/
.related-units .unit-teaser:first-of-type {
  margin: 15px 0 0; }

.related-units .unit-teaser:last-of-type .unit-teaser__overview {
  border: none; }

/**
 * Styles targeting the Faculty Landing page
 *
 */
.faculty-search {
  position: relative;
  margin-top: -70px; }
  .faculty-search .form-group {
    background: #fff;
    padding: 20px 30px 20px;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .faculty-search .form-group {
        padding: 20px 45px 20px; } }
  .faculty-search .form-control {
    width: 100%;
    max-width: none; }
  .faculty-search .input-group-btn .btn {
    background-color: #cf4520;
    border: 1px solid #cf4520;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 16px;
    height: 39px;
    padding: 6px 15px; }
    .faculty-search .input-group-btn .btn:hover {
      background-color: #b31b1b; }
  .faculty-search .glyphicon-search {
    font-size: 16px;
    position: relative;
    top: 3px;
    padding-right: 5px; }

/**
 * Styles targeting the Paragraph bundles used on the site
 *
 */
/* =Sections
----------------------------------------------------------*/
.paragraph-section__title {
  border-top: 5px solid #b31b1b;
  text-align: center; }
  .paragraph-section__title span {
    display: inline-block;
    background: #b31b1b;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: -1px 0 0;
    padding: 5px 20px;
    letter-spacing: 2px; }
    @media screen and (min-width: 992px) {
      .paragraph-section__title span {
        margin: 0; } }

/* =Tile Callouts
----------------------------------------------------------*/
.tile-callout {
  margin: 20px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd; }
  .tile-callout a.external-link:after {
    display: inline-block;
    content: '' !important;
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
    background-size: 10px 10px;
    height: 10px;
    width: 10px;
    margin-left: 6px;
    position: relative;
    top: 2px; }
    @media screen and (min-width: 992px) {
      .tile-callout a.external-link:after {
        background-size: 15px 15px;
        height: 15px;
        width: 15px; } }
  @media screen and (min-width: 768px) {
    .tile-callout {
      border: none;
      padding-bottom: 0; } }

.tile-callout:nth-of-type(3n) {
  clear: left; }

.tile-callout__title a {
  color: #b31b1b; }
  .tile-callout__title a:hover {
    color: #e7751d;
    text-decoration: underline; }

/*.tile-callout__description {
  color: $wcm-med-gray;
  @include breakpoint($md) {
    font-size: 18px;
    line-height: 1.4;
  }
}*/
.tile-callout__photo {
  overflow: hidden; }

.tile-callout__photo img {
  transition: all 0.3s ease 0s; }
  .tile-callout__photo img:hover {
    transform: scale(1.1); }

/* =Card Callouts
----------------------------------------------------------*/
@media screen and (min-width: 992px) {
  .paragraphs-items-field-card-callout {
    display: flex; } }

.card-callout {
  margin: 20px 0;
  padding-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .card-callout {
      border: none;
      padding-bottom: 0;
      overflow: hidden; } }

.card-callout__wrapper {
  background: #f7f7f7; }
  @media screen and (min-width: 992px) {
    .card-callout__wrapper {
      margin-bottom: -99999px;
      padding-bottom: 99999px; } }

.card-callout__text {
  padding: 20px; }

.card-callout__title {
  margin: 0 0 10px;
  text-align: center; }
  .card-callout__title a {
    color: #b31b1b; }
    .card-callout__title a:hover {
      color: #e7751d;
      text-decoration: underline; }
      .card-callout__title a:hover:before {
        text-decoration: none; }
    .card-callout__title a:after {
      content: '' !important;
      display: none !important; }
  .card-callout__title a span {
    display: block;
    position: relative;
    top: -40px;
    margin: 0 auto -40px; }
  .card-callout__title a span:before {
    content: '\e802';
    width: 50px;
    height: 50px;
    color: #e7751d;
    background: #f7f7f7;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    line-height: 50px;
    display: inline-block;
    -webkit-transition: background-color 0.25s;
    -o-transition: background-color 0.25s;
    transition: background-color 0.25s; }
  .card-callout__title a.external-link span:before {
    content: '' !important;
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 15px 15px;
    margin-left: 6px;
    position: relative;
    top: 2px; }
  .card-callout__title a:hover span:before {
    text-decoration: none; }

.card-callout__description {
  font-size: 15px;
  text-align: center; }

.card-callout__image {
  overflow: hidden; }

.card-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s; }
  .card-callout__image img:hover {
    transform: scale(1.1); }

.card-callout__cta {
  font-size: 14px; }
  .card-callout__cta a {
    border: none; }

.card-callout__cta a:nth-child(1) {
  padding-right: 5px; }

.card-callout__cta a:nth-child(2) {
  border-left: 1px solid #dddddd;
  padding-left: 7px; }

/* =Bar Callouts
----------------------------------------------------------*/
@media screen and (max-width: 767px) {
  .bar-callout .row.is-flex {
    display: block; } }

.bar-panel--title a:after {
  content: ''; }

@media screen and (min-width: 768px) {
  .bar-panel--title {
    background: #f7f7f7; } }

.bar-panel--title--linked {
  cursor: pointer; }

.bar-callout .row:hover .bar-callout__image img {
  transform: scale(1.1); }

.bar-callout__text {
  margin-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .bar-callout__text {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 70%;
      margin: 0;
      font-size: 13px; } }
  @media screen and (min-width: 992px) {
    .bar-callout__text {
      font-size: 15px; } }

.bar-callout__title {
  font-size: 15px; }
  @media screen and (min-width: 992px) {
    .bar-callout__title {
      font-size: 18px; } }
  .bar-callout__title a {
    color: #b31b1b; }
    .bar-callout__title a:hover {
      text-decoration: underline; }

.bar-callout__description {
  color: #666666;
  margin: 5px 0 0; }
  @media screen and (min-width: 992px) {
    .bar-callout__description {
      font-size: 18px;
      line-height: 1.6; } }
  .bar-callout__description:hover {
    text-decoration: underline; }

.bar-callout__image {
  overflow: hidden; }
  .bar-callout__image a {
    display: block;
    border: none; }
    .bar-callout__image a:hover {
      border: none; }

.bar-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s; }
  .bar-callout__image img:hover {
    transform: scale(1.1); }

/* =Video Callouts
----------------------------------------------------------*/
@media screen and (min-width: 992px) {
  .featured-video-callout__wrapper .row,
  .video-bar-callout .row {
    display: flex;
    flex-wrap: wrap; }
    .featured-video-callout__wrapper .row:before,
    .video-bar-callout .row:before {
      display: block; } }

@media screen and (min-width: 992px) {
  .featured-video-callout__wrapper .row > [class*='col-'],
  .video-bar-callout .row > [class*='col-'] {
    display: flex;
    flex-direction: column; } }

.embed-container {
  position: relative;
  overflow: hidden; }
  .embed-container:after {
    content: '';
    display: block;
    /* intrinsic aspect ratio */
    padding-bottom: 56.25%;
    height: 0; }

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.wcm-video__embed {
  background-color: #666666;
  position: relative;
  cursor: pointer; }
  @media screen and (min-width: 992px) {
    .wcm-video__embed {
      margin-bottom: 30px; } }
  .wcm-video__embed:after {
    content: '';
    display: block;
    /* intrinsic aspect ratio */
    padding-bottom: 56.25%;
    height: 0; }

.wcm-video__embed img {
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.7; }

.wcm-video__embed .wcm-video__play {
  width: 90px;
  height: 60px;
  background-color: #b31b1b;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 0.8;
  border-radius: 6px; }

.wcm-video__embed .wcm-video__play:before {
  content: "";
  border-style: solid;
  border-width: 15px 0 15px 26.0px;
  border-color: transparent transparent transparent #fff; }

.wcm-video__embed img,
.wcm-video__embed .wcm-video__play {
  cursor: pointer; }

@media screen and (min-width: 992px) {
  .wcm-video__embed img,
  .wcm-video__embed iframe {
    position: absolute; } }

.wcm-video__embed .wcm-video__play,
.wcm-video__embed .wcm-video__play:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }

.wcm-video__embed iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0; }

.video-bar-callout__title {
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .video-bar-callout__title {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 70%;
      margin: 0; } }
  .video-bar-callout__title a {
    color: #cf4520; }
    .video-bar-callout__title a:hover {
      color: #b31b1b;
      text-decoration: none; }

.featured-video-callout {
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0 20px; }
  @media screen and (min-width: 992px) {
    .featured-video-callout {
      padding: 40px 0; } }
  .featured-video-callout .paragraph-section__title {
    background: rgba(0, 0, 0, 0.5); }

.featured-video-callout__wrapper {
  padding: 30px;
  background: rgba(0, 0, 0, 0.5); }

.featured-video__description {
  color: #f7f7f7;
  margin: 20px 0; }
  @media screen and (min-width: 992px) {
    .featured-video__description {
      font-size: 18px;
      line-height: 1.8;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 70%;
      margin: 0; } }

.embed-container {
  background-color: #000;
  position: relative;
  overflow: hidden;
  cursor: pointer; }
  .embed-container:hover .play-button {
    background-color: #b31b1b;
    opacity: 1; }

.embed-container img {
  width: 100%;
  top: -16.82%;
  left: 0; }

.embed-container .play-button {
  width: 90px;
  height: 60px;
  background-color: #555555;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 0.8;
  border-radius: 6px;
  transition: all 0.3s ease 0s; }

.embed-container .play-button:before {
  content: "";
  border-style: solid;
  border-width: 15px 0 15px 26.0px;
  border-color: transparent transparent transparent #fff; }

.embed-container img,
.embed-container .play-button {
  cursor: pointer; }

.embed-container img,
.embed-container iframe,
.embed-container .play-button,
.embed-container .play-button:before {
  position: absolute; }

.embed-container .play-button,
.embed-container .play-button:before {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }

.embed-container iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0; }

/* =Metrics
----------------------------------------------------------*/
.paragraphs-item-metric-section {
  padding: 20px 0;
  background: #f7f7f7; }
  .paragraphs-item-metric-section:before, .paragraphs-item-metric-section:after {
    content: " ";
    display: table; }
  .paragraphs-item-metric-section:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .paragraphs-item-metric-section {
      padding: 40px 0; } }

.metric {
  text-align: center;
  margin: 0 0 20px; }

.metric:nth-of-type(5n) {
  clear: left; }

.metric__value {
  display: inline-block;
  font-size: 40px;
  line-height: 1.0;
  font-family: "1898Sans-Bold", sans-serif;
  color: #555555;
  border-bottom: 1px solid #cf4520; }
  @media screen and (min-width: 992px) {
    .metric__value {
      font-size: 48px; } }
  @media screen and (min-width: 1200px) {
    .metric__value {
      font-size: 58px; } }

.metric__description {
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
  color: #666666;
  padding: 10px 0; }
  @media screen and (min-width: 992px) {
    .metric__description {
      padding: 10px 20px; } }

/* =Quotes
----------------------------------------------------------*/
.paragraphs-item-quote-section {
  background: #f7f7f7;
  margin: 20px 0;
  padding: 0 0 20px; }

.quote {
  margin: 20px 0; }

.quote:nth-child(odd) {
  clear: left; }

.quote__image a {
  border: none; }

.quote__image img {
  border-radius: 50%;
  border: 2px solid #fff;
  padding: 3px; }
  @media screen and (max-width: 767px) {
    .quote__image img {
      width: 120px;
      height: auto; } }

.quote__title {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 700;
  margin-bottom: 10px; }

.quote__description {
  line-height: 1.65;
  font-style: italic;
  color: #555555; }
  .quote__description .cta-icon {
    width: 23px;
    height: 23px;
    fill: #e7751d;
    color: #cf4520;
    float: left;
    margin-right: 10px; }

.quote__more {
  font-size: 14px;
  margin: 10px 0 0; }
  .quote__more a:after {
    content: '\e80d';
    color: #e7751d;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: middle;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5; }

/* =Link Lists
----------------------------------------------------------*/
.paragraphs-item-link-list-section {
  padding: 30px 0; }

.link_list__title {
  font-size: 16px;
  font-weight: 700;
  color: #555555;
  margin: 0 0 10px;
  text-transform: uppercase;
  letter-spacing: 2px; }

@media screen and (min-width: 992px) {
  .field-link-list-link {
    font-size: 18px; } }

.field-link-list-link ul {
  margin: 0 0 0 20px;
  padding: 0; }

.field-link-list-link li {
  padding: 0 10px 5px; }

.field-link-list-link a {
  color: #b31b1b; }
  .field-link-list-link a:hover {
    color: #b31b1b; }

.field-link-list-link a.external-link:after {
  display: inline-block;
  content: '' !important;
  background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
  margin-left: 6px;
  position: relative;
  top: 2px; }
  @media screen and (min-width: 992px) {
    .field-link-list-link a.external-link:after {
      background-size: 15px 15px;
      height: 15px;
      width: 15px; } }

/* =Quick Links
----------------------------------------------------------*/
.quick-links {
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0 0px; }
  @media screen and (min-width: 992px) {
    .quick-links {
      padding: 0 0 40px; } }

.quick-links .paragraph-section__title {
  margin: 0 0 40px; }

.quick-links__wrapper {
  text-align: center; }
  @media screen and (min-width: 768px) {
    .quick-links__wrapper {
      text-align: left; } }
  @media screen and (min-width: 992px) {
    .quick-links__wrapper {
      font-size: 18px; } }
  .quick-links__wrapper ul {
    padding: 0 0 30px; }
    @media screen and (min-width: 768px) {
      .quick-links__wrapper ul {
        column-count: 2;
        column-gap: 150px;
        margin: 0 0 40px; } }
    @media screen and (min-width: 1200px) {
      .quick-links__wrapper ul {
        column-gap: 250px; } }
  .quick-links__wrapper li {
    list-style-type: none;
    border-top: 1px solid #ccc;
    padding: 10px 0; }
    .quick-links__wrapper li a {
      border: none; }
  .quick-links__wrapper .external-link:after {
    display: inline-block;
    content: '' !important;
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
    background-size: 10px 10px;
    height: 10px;
    width: 10px;
    margin-left: 6px;
    position: relative;
    top: 2px; }
    @media screen and (min-width: 992px) {
      .quick-links__wrapper .external-link:after {
        background-size: 15px 15px;
        height: 15px;
        width: 15px; } }

/* =Quick Text
----------------------------------------------------------*/
.quick-text {
  padding: 0 0 30px;
  background-repeat: no-repeat;
  background-size: cover; }
  .quick-text .external-link:after {
    display: inline-block;
    content: '' !important;
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
    background-size: 10px 10px;
    height: 10px;
    width: 10px;
    margin-left: 6px;
    position: relative;
    top: 2px; }
    @media screen and (min-width: 992px) {
      .quick-text .external-link:after {
        background-size: 15px 15px;
        height: 15px;
        width: 15px; } }
  .quick-text li {
    list-style-type: none;
    padding: 0 0 10px;
    text-align: center; }
    .quick-text li a {
      font-size: 18px; }
    .quick-text li a:after {
      content: '\e80d';
      color: #e7751d;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
      text-decoration: none;
      line-height: 1.5; }

.quick-text .paragraph-section__title {
  margin: 0 0 30px; }

.quick-text__wrapper {
  max-width: 760px;
  margin: 0 auto; }
  .quick-text__wrapper ul {
    padding-left: 0; }

/* =Icon Callouts
----------------------------------------------------------*/
.icon-callout {
  margin: 30px 0; }

.icon-callout__link {
  text-align: center; }
  @media screen and (min-width: 768px) {
    .icon-callout__link {
      margin: 0; } }

.icon-callout__link a {
  position: relative;
  display: block;
  z-index: 10;
  padding: 50px 30px;
  border: none; }
  .icon-callout__link a:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0; }
  .icon-callout__link a:hover {
    z-index: 1000;
    text-decoration: none;
    transform: scale(1.1, 1.1);
    border-width: 1px;
    background: #fff; }
  .icon-callout__link a:hover:after {
    opacity: 1; }

.icon-callout__detail {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.icon-callout__link a span {
  display: block;
  text-align: center; }

.icon-callout__link.col-sm-12 a {
  border: none; }

@media screen and (min-width: 768px) {
  .icon-callout__link.col-sm-6:nth-child(1) a {
    border-right: 1px solid #dddddd; } }

@media screen and (min-width: 768px) {
  .icon-callout__link.col-sm-4:nth-child(1) a,
  .icon-callout__link.col-sm-4:nth-child(2) a {
    border-right: 1px solid #dddddd; } }

@media screen and (min-width: 768px) {
  .icon-callout__link.col-sm-3:nth-child(1) a,
  .icon-callout__link.col-sm-3:nth-child(2) a,
  .icon-callout__link.col-sm-3:nth-child(3) a {
    border-right: 1px solid #dddddd; } }

.icon-callout__icon .cta-icon {
  width: 40px;
  height: 40px;
  fill: #e87722;
  color: #cf4520; }

.icon-callout__text > a.external-link:after,
.icon-callout__icon > a.external-link:after {
  content: '';
  padding-left: 0;
  background: none; }

/* =Event Callouts
----------------------------------------------------------*/
.event-callout {
  background: #f7f7f7;
  margin-top: 20px;
  margin-bottom: 20px; }
  .event-callout .event-callout__wrapper {
    text-align: center;
    font-size: 17px;
    padding: 25px 20px; }
    .event-callout .event-callout__wrapper .event-callout__date {
      background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/calendar.svg");
      padding-left: 35px;
      padding-right: 20px;
      padding-top: 1px;
      padding-bottom: 1px;
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: center left;
      border-right: 1px solid #e7751d; }
    .event-callout .event-callout__wrapper .event-callout__time {
      background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/appointment.svg");
      padding-left: 35px;
      margin-left: 20px;
      padding-top: 1px;
      padding-bottom: 1px;
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: center left; }
    .event-callout .event-callout__wrapper .event-callout__location {
      background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/location.svg");
      display: inline-block;
      padding-left: 35px;
      margin-top: 10px;
      padding-bottom: 2px;
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: left 5px; }

/* =Event Callouts
----------------------------------------------------------*/
.generic-callout {
  background: #f7f7f7;
  margin-top: 20px;
  margin-bottom: 20px; }
  .generic-callout .generic-callout__wrapper {
    text-align: center;
    padding: 25px 20px; }
    .generic-callout .generic-callout__wrapper .btn {
      padding: 10px 20px;
      margin-top: 20px;
      width: auto;
      min-width: 220px;
      display: inline-block; }
      .generic-callout .generic-callout__wrapper .btn:before {
        line-height: 2.5; }
      .generic-callout .generic-callout__wrapper .btn:hover, .generic-callout .generic-callout__wrapper .btn:focus {
        background-color: #cf4520; }

/* =Gallery
----------------------------------------------------------*/
.fancybox-nav span {
  visibility: visible; }

.paragraphs-items-field-gallery-media-files {
  font-size: 13px;
  margin-left: -20px;
  margin-right: -20px; }
  .paragraphs-items-field-gallery-media-files:before, .paragraphs-items-field-gallery-media-files:after {
    content: " ";
    display: table; }
  .paragraphs-items-field-gallery-media-files:after {
    clear: both; }
  .paragraphs-items-field-gallery-media-files .media-file-caption {
    position: relative;
    margin-top: 20px; }
  .paragraphs-items-field-gallery-media-files h2 {
    padding-left: 20px;
    margin-bottom: 10px; }
  .paragraphs-items-field-gallery-media-files .media-files-filter {
    padding-left: 20px;
    margin-bottom: 20px; }
    .paragraphs-items-field-gallery-media-files .media-files-filter input[type=radio] {
      cursor: pointer; }
    .paragraphs-items-field-gallery-media-files .media-files-filter input[type=radio] + label {
      color: #cf4520; }
    .paragraphs-items-field-gallery-media-files .media-files-filter input[type=radio]:checked + label {
      color: #000000; }
    .paragraphs-items-field-gallery-media-files .media-files-filter label {
      margin-right: 20px;
      padding-left: 5px;
      cursor: pointer; }
  .paragraphs-items-field-gallery-media-files .media-files-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap; }
  .paragraphs-items-field-gallery-media-files .media-file-wrapper {
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    margin-bottom: 20px; }
    @media screen and (max-width: 767px) {
      .paragraphs-items-field-gallery-media-files .media-file-wrapper {
        width: 100%; }
        .paragraphs-items-field-gallery-media-files .media-file-wrapper img {
          width: 100%; } }
    .paragraphs-items-field-gallery-media-files .media-file-wrapper.media-file-hidden {
      opacity: 0;
      width: 0;
      padding: 0;
      transform: scale(0, 0);
      transform-origin: center center; }
    .paragraphs-items-field-gallery-media-files .media-file-wrapper a {
      position: relative;
      display: block;
      border: none; }
      .paragraphs-items-field-gallery-media-files .media-file-wrapper a:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.45);
        display: block;
        color: #fff;
        text-align: center;
        padding-top: 32%;
        font-size: 15px;
        font-weight: 700;
        opacity: 0;
        transition: all 0.3s ease; }
      .paragraphs-items-field-gallery-media-files .media-file-wrapper a:after {
        content: '';
        bottom: -23px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        width: 50px;
        height: 50px;
        background-size: 25px 25px;
        background-repeat: no-repeat;
        background-position: center center; }
      .paragraphs-items-field-gallery-media-files .media-file-wrapper a:hover:before {
        opacity: 1; }
  .paragraphs-items-field-gallery-media-files .media-video a:before {
    content: 'SHOW VIDEO'; }
  .paragraphs-items-field-gallery-media-files .media-video a:after {
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/video.svg"); }
  .paragraphs-items-field-gallery-media-files .media-image a:before {
    content: 'SHOW IMAGE'; }
  .paragraphs-items-field-gallery-media-files .media-image a:after {
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/slideshow.svg"); }

/**
* Styles related to the Leadership Landing, Bio Content Type and List View
*
*/
/* =Leadership Landing
----------------------------------------------------------*/
.leadership-landing__banner img ~ p {
  font-size: 13px;
  font-style: italic;
  text-align: right;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .leadership-landing__banner img ~ p:before, .leadership-landing__banner img ~ p:after {
    content: " ";
    display: table; }
  .leadership-landing__banner img ~ p:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .leadership-landing__banner img ~ p {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .leadership-landing__banner img ~ p {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .leadership-landing__banner img ~ p {
      width: 1170px; } }

.leadership-landing__intro {
  margin: 0 0 30px; }
  .leadership-landing__intro .fieldable-panels-pane {
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px; }
    .leadership-landing__intro .fieldable-panels-pane:before, .leadership-landing__intro .fieldable-panels-pane:after {
      content: " ";
      display: table; }
    .leadership-landing__intro .fieldable-panels-pane:after {
      clear: both; }
    @media screen and (min-width: 768px) {
      .leadership-landing__intro .fieldable-panels-pane {
        width: 750px; } }
    @media screen and (min-width: 992px) {
      .leadership-landing__intro .fieldable-panels-pane {
        width: 970px; } }
    @media screen and (min-width: 1200px) {
      .leadership-landing__intro .fieldable-panels-pane {
        width: 1170px; } }
  .leadership-landing__intro h1 {
    text-align: center;
    margin-bottom: 10px; }
  .leadership-landing__intro p {
    text-align: center;
    font-size: large; }
    @media screen and (min-width: 768px) {
      .leadership-landing__intro p {
        padding: 0 100px; } }

.leadership-landing__nav {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .leadership-landing__nav ul {
    text-align: center; }
    @media screen and (min-width: 768px) {
      .leadership-landing__nav ul {
        text-align: left; } }
    @media screen and (min-width: 992px) {
      .leadership-landing__nav ul {
        font-size: 18px; } }
  .leadership-landing__nav ul.first {
    padding-top: 30px; }
    @media screen and (min-width: 768px) {
      .leadership-landing__nav ul.first {
        padding: 30px 125px 30px 0; } }
  .leadership-landing__nav ul.second {
    padding-bottom: 30px; }
    @media screen and (min-width: 768px) {
      .leadership-landing__nav ul.second {
        padding: 30px 0 30px 0; } }
  .leadership-landing__nav li {
    border-bottom: 1px solid #fff;
    padding: 10px 0;
    list-style-type: none; }
    .leadership-landing__nav li a {
      color: #fff;
      white-space: nowrap;
      border: none; }
      .leadership-landing__nav li a:after {
        content: '\e80d' !important;
        color: #e7751d;
        padding-left: 10px;
        font-size: 60%;
        vertical-align: middle;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
        text-decoration: none;
        line-height: 1.5; }
  .leadership-landing__nav li:last-of-type {
    border: none; }

/* =Leadership Bio Detail
----------------------------------------------------------*/
.pane-node-field-leadership-headshot {
  margin: 0 0 20px; }

@media screen and (max-width: 767px) {
  .node-type-leadership-biography .sidebar {
    margin: 0 0 20px; } }

.node-type-leadership-biography .sidebar .field-label {
  font-weight: 700;
  display: inline; }

.leadership-detail-pro-title {
  margin: 0 0 40px; }
  .leadership-detail-pro-title ul {
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 700;
    color: #555555; }
  .leadership-detail-pro-title li {
    list-style-type: none;
    margin: 0 0 10px;
    padding: 0; }

.pane-node-field-leadership-headshot img {
  border-top: 8px solid #b31b1b; }
  @media screen and (min-width: 992px) {
    .pane-node-field-leadership-headshot img {
      max-width: none;
      width: 100%; } }

.leadership-bio-external-link .btn--wcm {
  width: auto;
  margin: 20px auto 20px;
  padding-right: 35px; }

/* =Leadership Bio List Display
----------------------------------------------------------*/
.leadership-list-item__featured-text {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
  -ms-transform: translateY(-50%);
  /* IE 9 */
  transform: translateY(-50%);
  /* IE 10, Fx 16+, Op 12.1+ */ }

.choi-senior-leadership {
  padding: 20px 0 0; }
  .choi-senior-leadership .field-name-field-leadership-pro-title {
    margin: 0 0 20px; }

.sort-list {
  font-size: 13px;
  margin: 20px 0 25px;
  padding: 0 0 15px;
  border-bottom: 1px solid #dddddd; }
  .sort-list span {
    font-weight: 700; }
  .sort-list button {
    border: none;
    background: none;
    color: #cf4520; }
    .sort-list button:hover {
      color: #e7751d;
      text-decoration: underline; }
    .sort-list button:focus {
      outline: none;
      border: 1px dotted; }
  .sort-list .active-sort {
    color: #000000;
    font-weight: 700; }

.leadership-list-group-title {
  margin: 0 0 15px; }

.leadership-list-item {
  margin: 0 0 20px; }

.paragraphs-items-field-leadership-group-list .field-label {
  display: none; }

@media screen and (min-width: 768px) {
  .paragraphs-items-field-leadership-list-items {
    display: flex;
    flex-wrap: wrap; }
    .paragraphs-items-field-leadership-list-items:before {
      display: block; } }

@media screen and (min-width: 768px) {
  .paragraphs-items-field-leadership-list-items > [class*='col-'] {
    display: flex;
    flex-direction: column; } }

.leadership-list-item__headshot {
  position: relative;
  overflow: hidden;
  border-top: 8px solid #b31b1b;
  transition: all 0.3s ease 0s; }
  @media screen and (max-width: 767px) {
    .leadership-list-item__headshot {
      max-width: 180px; } }
  .leadership-list-item__headshot img {
    width: 180px;
    height: auto;
    transition: all 0.3s ease 0s; }
    @media screen and (min-width: 768px) {
      .leadership-list-item__headshot img {
        width: 100%;
        max-width: none; } }

.leadership-list-item__headshot .bio-icon {
  position: absolute;
  display: inline-block;
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  bottom: 10px;
  right: 10px;
  z-index: 10; }
  @media screen and (min-width: 992px) {
    .leadership-list-item__headshot .bio-icon {
      background-size: 30px 30px;
      height: 30px;
      width: 30px;
      bottom: 15px;
      right: 15px; } }
  .leadership-list-item__headshot .bio-icon svg {
    width: 20px;
    height: 20px;
    fill: #e7751d;
    color: #cf4520; }
    @media screen and (min-width: 992px) {
      .leadership-list-item__headshot .bio-icon svg {
        width: 30px;
        height: 30px; } }

.leadership-list-item__headshot .mask {
  opacity: 0;
  transition: all 0.3s ease 0s; }

.leadership-list-item__headshot.is-hover {
  border-top: 8px solid #e7751d; }
  .leadership-list-item__headshot.is-hover img {
    transform: scale(1.1); }
  .leadership-list-item__headshot.is-hover .mask {
    opacity: 1.0; }

.leadership-list-item__headshot.is-hover .bio-icon svg {
  fill: #fff;
  color: #fff; }

.leadership-list-item__headshot--placeholder:after {
  display: none; }

.leadership-list-item__name {
  font-weight: 700;
  line-height: 1.3;
  margin: 15px 0 10px; }

.leadership-list-item__name a {
  color: #cf4520; }

.leadership-list-item__name a:hover {
  text-decoration: none; }
  .leadership-list-item__name a:hover h4 {
    color: #b31b1b; }

.leadership-list-item__pro-title ul {
  margin: 0;
  padding: 0; }
  .leadership-list-item__pro-title ul li {
    list-style-type: none; }

/**
 * Styles targeting the Mission Page content type
 *
 */
.mission-page__hero {
  position: relative;
  margin: 0 0 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #666666;
  height: calc(60vh);
  overflow: hidden; }

@media screen and (min-width: 800px) and (max-height: 420px) {
  .mission-page__hero {
    height: calc(72vh); } }

@media screen and (min-width: 768px) and (min-height: 1024px) {
  .mission-page__hero {
    height: calc(100vh - 310px); } }

@media screen and (min-width: 992px) {
  .logged-in .mission-page__hero {
    height: calc(100vh - 500px); } }

.mission-page__hero-bg {
  position: absolute;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .35s; }
  .mission-page__hero-bg .mission-page__hero-bg-inner {
    position: absolute;
    z-index: 8;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll; }
    @media screen and (max-width: 768px) {
      .mission-page__hero-bg .mission-page__hero-bg-inner {
        background-position: center !important; } }
    @media screen and (min-width: 769px) {
      .mission-page__hero-bg .mission-page__hero-bg-inner {
        background-attachment: fixed;
        background-position: center;
        height: 100%; } }
    @media only screen and (min-width: 768px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      .mission-page__hero-bg .mission-page__hero-bg-inner {
        background-attachment: scroll;
        background-position: center !important; } }

@media screen and (min-width: 992px) {
  .mission-page__hero.over .mission-page__hero-bg {
    transform: scale(1.08); } }

@media screen and (min-width: 768px) {
  .hero-mask:before {
    /* change border-right px to adjust left slope*/
    border-left: 0 none;
    border-right: 35px solid transparent;
    left: 0; } }

@media screen and (min-width: 768px) {
  .hero-mask:after {
    /* change border-left px to adjust right slope*/
    border-left: 35px solid transparent;
    border-right: 0 none;
    right: 0; } }

@media screen and (min-width: 768px) {
  .hero-mask:before,
  .hero-mask:after {
    /* bottom-border adjusts thickess of mask*/
    border-bottom: 20px solid #fff;
    content: " ";
    display: block;
    height: 0;
    position: absolute;
    top: 0;
    width: 50%;
    box-sizing: border-box; } }

@media screen and (min-width: 768px) {
  .hero-mask {
    bottom: 0;
    /* adjust height of mask*/
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 110; } }

.mission-page .mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45); }

.mission-page__title {
  color: #fff; }

.mission-page__hero .mission-page__title {
  z-index: 120;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
  -ms-transform: translateY(-50%);
  /* IE 9 */
  transform: translateY(-50%);
  /* IE 10, Fx 16+, Op 12.1+ */ }

.mission-page__no-hero {
  position: relative;
  background: #666666;
  min-height: 350px; }
  .mission-page__no-hero .mission-page__title {
    padding: 60px 0 0; }

.mission-page__tagline {
  font-size: 24px;
  font-family: "1898Sans-Bold", sans-serif;
  line-height: 1.2;
  color: #fff;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .mission-page__tagline {
      padding: 0 30px 0; } }
  @media screen and (min-width: 375px) {
    .mission-page__tagline {
      font-size: 34px; } }
  @media screen and (min-width: 768px) {
    .mission-page__tagline {
      font-size: 46px;
      padding: 0 40px 10px; } }
  @media screen and (min-width: 992px) {
    .mission-page__tagline {
      font-size: 50px; } }

.mission-page hr {
  width: 30%;
  border-top: 2px solid #ffc72c;
  margin-top: 15px;
  margin-bottom: 15px; }
  @media screen and (max-width: 767px) {
    .mission-page hr {
      margin-top: 10px;
      margin-bottom: 10px; } }

.mission-page__short-description {
  color: #eee;
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  letter-spacing: 1px;
  padding: 0 30px; }
  @media screen and (min-width: 768px) {
    .mission-page__short-description {
      font-size: 20px;
      padding-top: 20px; } }

.mission-page__primary_cta {
  margin: 20px 0;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .mission-page__primary_cta {
      margin: 30px 0 0; } }
  .mission-page__primary_cta a {
    color: #ffc72c;
    display: block;
    text-align: center;
    font-size: 14px;
    letter-spacing: 1px;
    border: none;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.2s ease 0s; }
    @media screen and (min-width: 768px) {
      .mission-page__primary_cta a {
        font-size: 18px; } }
    @media screen and (min-width: 992px) {
      .mission-page__primary_cta a:hover {
        background: rgba(0, 0, 0, 0.7); } }

.mission-page__primary_cta a:after {
  content: '\e802';
  padding-left: 10px;
  font-size: 90%;
  vertical-align: middle;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  text-decoration: none;
  line-height: 1.5; }

@media screen and (min-width: 800px) and (max-height: 420px) {
  .mission-page__tagline {
    font-size: 36px; }
  .mission-page hr {
    margin-top: 10px;
    margin-bottom: 10px; }
  .mission-page__short-description {
    font-size: 15px;
    padding-top: 10px; } }

.pane-node-field-mission-description {
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .pane-node-field-mission-description:before, .pane-node-field-mission-description:after {
    content: " ";
    display: table; }
  .pane-node-field-mission-description:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .pane-node-field-mission-description {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .pane-node-field-mission-description {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .pane-node-field-mission-description {
      width: 1170px; } }
  .pane-node-field-mission-description p {
    padding: 0 17.5%;
    text-align: center;
    font-size: large; }

/* =Paragraph Styles targeting Mission Landing Pages
----------------------------------------------------------*/
.node-type-mission-page .paragraphs-item-tile-callouts-section {
  background: #f7f7f7;
  margin: 0 0 30px;
  padding: 0 0 10px; }

.node-type-mission-page .paragraphs-item-link-list-section {
  background: #f7f7f7;
  margin: 0 0 30px;
  padding: 40px 0; }
  @media screen and (min-width: 768px) {
    .node-type-mission-page .paragraphs-item-link-list-section {
      margin: 0; } }

.node-type-mission-page .pane-wcmc-newsroom-pane, .node-type-mission-page .pane-wcm-patient-care-news {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px; }
  .node-type-mission-page .pane-wcmc-newsroom-pane:before, .node-type-mission-page .pane-wcmc-newsroom-pane:after, .node-type-mission-page .pane-wcm-patient-care-news:before, .node-type-mission-page .pane-wcm-patient-care-news:after {
    content: " ";
    display: table; }
  .node-type-mission-page .pane-wcmc-newsroom-pane:after, .node-type-mission-page .pane-wcm-patient-care-news:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .node-type-mission-page .pane-wcmc-newsroom-pane, .node-type-mission-page .pane-wcm-patient-care-news {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .node-type-mission-page .pane-wcmc-newsroom-pane, .node-type-mission-page .pane-wcm-patient-care-news {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .node-type-mission-page .pane-wcmc-newsroom-pane, .node-type-mission-page .pane-wcm-patient-care-news {
      width: 1170px; } }

.node-type-mission-page .newsroom-article a.external-link:after {
  display: inline-block;
  content: '' !important;
  background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
  margin-left: 6px;
  position: relative;
  top: 2px; }
  @media screen and (min-width: 992px) {
    .node-type-mission-page .newsroom-article a.external-link:after {
      background-size: 15px 15px;
      height: 15px;
      width: 15px; } }

@media screen and (max-width: 767px) {
  .node-type-mission-page .newsroom-article {
    margin: 0 0 20px; } }

.node-type-mission-page .newsroom-article .date {
  margin: 10px 0 5px;
  color: #666666;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  font-family: "1898Sans-Bold", sans-serif;
  letter-spacing: 2px; }

.node-type-mission-page .newsroom-article .news-title {
  font-size: 13px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0; }
  @media screen and (min-width: 992px) {
    .node-type-mission-page .newsroom-article .news-title {
      font-size: 16px; } }

.node-type-mission-page .newsroom-article .newsroom-article__image {
  overflow: hidden;
  margin: 0 0 20px; }

.node-type-mission-page .newsroom-article img {
  width: 100%;
  transition: all 0.3s ease 0s; }
  .node-type-mission-page .newsroom-article img:hover {
    transform: scale(1.1); }

.node-type-mission-page footer {
  margin: 0;
  padding: 0; }

/**
 * Styles targeting the Priority Highlights / Focus Area content type
 *
 */
/* =Priority Highlights - Teasers
 ----------------------------------------------------------*/
.highlights {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #666666; }
  .highlights:before, .highlights:after {
    content: " ";
    display: table; }
  .highlights:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .highlights {
      display: flex; } }

.highlights-mobile-nav {
  background: rgba(207, 69, 32, 0.9);
  z-index: 12;
  padding: 10px 30px 20px;
  position: relative; }
  .highlights-mobile-nav:before, .highlights-mobile-nav:after {
    content: " ";
    display: table; }
  .highlights-mobile-nav:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .highlights-mobile-nav {
      display: none; } }
  .highlights-mobile-nav:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: rgba(207, 69, 32, 0.9) transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    right: 50%; }
  .highlights-mobile-nav .wayfinder--highlights {
    width: 80%;
    margin: 0 auto; }
    .highlights-mobile-nav .wayfinder--highlights button {
      width: 100%;
      text-align: left; }
    .highlights-mobile-nav .wayfinder--highlights .selection-arrow span {
      top: 5px; }
    .highlights-mobile-nav .wayfinder--highlights .highlights__nav--sm.dropdown-menu {
      width: 90%; }
      .highlights-mobile-nav .wayfinder--highlights .highlights__nav--sm.dropdown-menu a {
        border-bottom: none; }

.highlights__nav {
  display: none; }
  @media screen and (min-width: 768px) {
    .highlights__nav {
      display: block;
      float: left;
      width: 33.33333%;
      margin: 0;
      font-size: 18px;
      background: #cf4520;
      color: #fff;
      opacity: 0.9;
      z-index: 10;
      padding: 40px 30px; } }
  @media screen and (min-width: 992px) {
    .highlights__nav {
      padding: 60px 30px 60px 60px; } }
  .highlights__nav li {
    list-style-type: none;
    padding: 0;
    border: 1px dotted;
    border-width: 1px 0 0;
    position: relative; }
    @media screen and (min-width: 992px) {
      .highlights__nav li {
        padding: 0; } }
  .highlights__nav li:last-child {
    border-width: 1px 0; }
  .highlights__nav a {
    display: block;
    padding: 10px 5px;
    border: none;
    color: #fff;
    text-align: right; }
    .highlights__nav a.active, .highlights__nav a:hover {
      background: #b31b1b; }

.highlights__nav a.active:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #cf4520;
  position: absolute;
  top: 15px;
  right: -40px; }

.highlights__teaser {
  display: none;
  position: relative;
  z-index: 11;
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    .highlights__teaser {
      min-height: 250px;
      padding: 50px; } }
  @media screen and (min-width: 768px) {
    .highlights__teaser {
      float: left;
      width: 66.66666%; } }

.highlights__teaser:nth-of-type(2) {
  display: block; }

.highlights__text {
  color: #fff; }
  @media screen and (max-width: 767px) {
    .highlights__text {
      text-align: center; } }
  @media screen and (min-width: 768px) {
    .highlights__text {
      width: 75%;
      padding: 0 0 0 60px;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
      -ms-transform: translateY(-50%);
      /* IE 9 */
      transform: translateY(-50%);
      /* IE 10, Fx 16+, Op 12.1+ */ } }

.highlights__title {
  font-size: 42px;
  font-family: "1898Sans-Bold", sans-serif;
  line-height: 1.2;
  padding: 0 0 10px; }
  @media screen and (min-width: 768px) {
    .highlights__title {
      text-align: left; } }

@media screen and (max-width: 767px) {
  .highlights__title:after {
    content: '\e80d';
    padding-left: 10px;
    font-size: 40%;
    vertical-align: middle;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5;
    color: #ffc72c; } }

.highlights__desc {
  font-size: 18px; }

.highlights__indicator {
  display: none; }
  @media screen and (min-width: 768px) {
    .highlights__indicator {
      display: block;
      position: absolute;
      height: 50%;
      top: 25%;
      right: 5%;
      border-left: 1px dotted #ffc72c;
      padding-left: 30px;
      z-index: 10;
      opacity: 0.7;
      transition: all 0.2s ease 0s; } }
  .highlights__indicator:after {
    content: "";
    position: relative;
    top: 25%;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 40px 40px;
    border-color: transparent transparent transparent #ffc72c; }

.highlights .mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45); }
  @media screen and (min-width: 992px) {
    .highlights .mask {
      transition: all 0.2s ease 0s; } }

@media screen and (min-width: 992px) {
  .highlights.is-hover .highlights__indicator {
    opacity: 1.0; } }

@media screen and (min-width: 992px) {
  .highlights.is-hover .mask {
    background: rgba(0, 0, 0, 0.6); } }

/* =Priority Highlights - Full Node
 ----------------------------------------------------------*/
.focus-area__hero {
  position: relative; }
  .focus-area__hero img {
    width: 100%;
    height: auto; }

.focus-area__no-hero {
  position: relative;
  background: #666666;
  min-height: 350px; }

.focus-area__title {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 32px;
  font-family: "1898Sans-Bold", sans-serif;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
  @media screen and (min-width: 768px) {
    .focus-area__title {
      font-size: 52px; } }
  @media screen and (min-width: 992px) {
    .focus-area__title {
      font-size: 60px; } }

.focus-area__short-description {
  background: #cf4520;
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 1.4;
  padding: 15px 40px; }
  @media screen and (min-width: 992px) {
    .focus-area__short-description {
      font-size: 24px;
      padding: 15px 60px; } }

.focus-area__description {
  background: #f7f7f7;
  text-align: center;
  line-height: 1.5;
  padding: 3% 6%;
  /*@include breakpoint($sm) {
    font-size: 18px;
  }
  @include breakpoint($md) {
    font-size: 20px;
  }
  @include breakpoint($lg) {
    font-size: 22px;
  }*/ }
  .focus-area__description .focus-area__description-content {
    max-width: 1180px;
    margin: 0 auto;
    font-size: large; }
    @media screen and (min-width: 768px) {
      .focus-area__description .focus-area__description-content {
        padding: 0 17%; } }

.focus-area__hero .mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45); }

/* =Focus Area/Priority Highlight Landing Index
----------------------------------------------------------*/
.highlight {
  text-align: center;
  margin: 0 0 40px; }

.highlight__image {
  overflow: hidden; }
  .highlight__image a {
    display: block; }
  .highlight__image img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease 0s; }
    .highlight__image img:hover {
      transform: scale(1.1); }

.highlight__title {
  font-size: 24px;
  font-family: "1898Sans-Bold", sans-serif;
  color: #cf4520; }
  .highlight__title a {
    color: #b31b1b;
    border: none; }
    .highlight__title a:hover {
      color: #e7751d; }
      .highlight__title a:hover:before {
        text-decoration: none; }
    .highlight__title a:after {
      content: '' !important;
      display: none !important; }
  .highlight__title a span {
    display: block;
    position: relative;
    top: -25px;
    margin: 0 auto -25px; }
  .highlight__title a span:before {
    content: '\e802';
    width: 50px;
    height: 50px;
    color: #e7751d;
    background: #fff;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    line-height: 50px;
    display: inline-block;
    -webkit-transition: background-color 0.25s;
    -o-transition: background-color 0.25s;
    transition: background-color 0.25s; }
  .highlight__title a.external-link span:before {
    content: '' !important;
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 15px 15px;
    margin-left: 6px;
    position: relative;
    top: 2px; }
  .highlight__title a:hover span:before {
    text-decoration: none; }

.highlight__description {
  font-size: 20px;
  color: #666666; }

/**
 * Styles targeting the top level (non-Mission) landing pages
 *
 */
/* =Our Story
 ----------------------------------------------------------*/
.page-node-55 .pane-node-field-featured-image img,
.page-node-56 .pane-node-field-featured-image img {
  width: 100%;
  max-width: none;
  height: auto; }

.page-node-55 .pane-node-field-featured-image figcaption,
.page-node-56 .pane-node-field-featured-image figcaption {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1180px;
  width: auto;
  float: none; }

.page-node-55 h1.title,
.page-node-56 h1.title {
  text-align: center;
  margin: 40px 0 20px; }

.page-node-55 .pane-node-body,
.page-node-56 .pane-node-body {
  max-width: 760px;
  margin-bottom: 20px;
  text-align: center;
  font-size: large;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .page-node-55 .pane-node-body:before, .page-node-55 .pane-node-body:after,
  .page-node-56 .pane-node-body:before,
  .page-node-56 .pane-node-body:after {
    content: " ";
    display: table; }
  .page-node-55 .pane-node-body:after,
  .page-node-56 .pane-node-body:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .page-node-55 .pane-node-body,
    .page-node-56 .pane-node-body {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .page-node-55 .pane-node-body,
    .page-node-56 .pane-node-body {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .page-node-55 .pane-node-body,
    .page-node-56 .pane-node-body {
      width: 1170px; } }

.page-node-55 .hero-image--full {
  width: 100%;
  height: 175px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media screen and (min-width: 768px) {
    .page-node-55 .hero-image--full {
      height: 300px; } }

.our-story-leadership {
  margin: 0 0 20px; }
  @media screen and (max-width: 767px) {
    .our-story-leadership .paragraph-section__title {
      margin: 0 0 20px; } }

@media screen and (max-width: 767px) {
  .our-story-leadership__headshot {
    margin: 0 0 20px; } }

.our-story-leadership__quote {
  font-style: italic;
  font-weight: 300;
  margin: 0 0 20px; }
  @media screen and (min-width: 992px) {
    .our-story-leadership__quote {
      font-size: 18px;
      padding-right: 100px; } }
  @media screen and (min-width: 1200px) {
    .our-story-leadership__quote {
      font-size: 20px; } }
  .our-story-leadership__quote .cta-icon {
    width: 20px;
    height: 20px;
    fill: #c0c0c0;
    color: #c0c0c0;
    display: inline-block;
    margin-right: 10px; }
    @media screen and (min-width: 992px) {
      .our-story-leadership__quote .cta-icon {
        width: 26px;
        height: 26px; } }

.our-story-leadership__cta {
  font-size: 18px; }
  .our-story-leadership__cta ul {
    margin: 0;
    padding: 0; }
  .our-story-leadership__cta li {
    list-style-type: none; }
    @media screen and (min-width: 768px) {
      .our-story-leadership__cta li {
        display: inline;
        border-right: 1px solid #dddddd;
        padding-right: 8px;
        margin-right: 8px; } }
    .our-story-leadership__cta li:last-child {
      border: none; }

.our-story-contact .paragraph-section__title {
  margin: 0 0 20px; }

.our-story-contact h3 a {
  text-decoration: none; }
  .our-story-contact h3 a:after {
    content: '\e80d';
    color: #e7751d;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: middle;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5; }
  .our-story-contact h3 a:hover {
    color: #b31b1b;
    border-bottom: 1px solid !important; }
    .our-story-contact h3 a:hover:after {
      color: #b31b1b; }

.our-story-contact h4 {
  margin: 0 0 20px; }

.our-story-contact .embed {
  padding-bottom: 40%; }

.page-node-375 footer {
  padding-top: 0; }

.node-type-panopoly-landing-page h1.title {
  text-align: center; }

/**
 * Styles targeting the Faculty Callout panes
 *
 */
/* =Home Faculty Callout
----------------------------------------------------------*/
.faculty-callout-section {
  background: #666666;
  padding: 80px 0; }
  @media screen and (min-width: 768px) {
    .faculty-callout-section {
      background: url("/sites/default/files/education-featured-video-background-hires.png");
      background-size: cover; } }
  .faculty-callout-section .paragraph-section__title {
    margin: 0 0 30px; }

.faculty-callout__wrap {
  background: rgba(0, 0, 0, 0.6);
  padding: 0 0 10px; }
  @media screen and (min-width: 768px) {
    .faculty-callout__wrap {
      padding: 0 40px 60px; } }

.faculty-callouts {
  width: 100%;
  margin: 0;
  padding: 0; }
  @media screen and (min-width: 768px) {
    .faculty-callouts {
      display: flex; } }

.faculty-callouts li {
  max-width: 300px;
  margin: 0 auto;
  list-style-type: none;
  position: relative; }
  @media screen and (min-width: 768px) {
    .faculty-callouts li {
      max-width: none;
      vertical-align: top;
      position: relative;
      width: 33.33333%;
      margin: 0;
      border: 0 transparent solid;
      border-width: 0 5px;
      background-clip: padding-box; } }
  @media screen and (min-width: 992px) {
    .faculty-callouts li {
      border-width: 0 20px; } }
  .faculty-callouts li .faculty-callout__summary {
    cursor: pointer; }
    @media screen and (max-width: 767px) {
      .faculty-callouts li .faculty-callout__summary {
        height: auto !important; } }

.faculty-callout {
  position: relative;
  width: 100%;
  background: #555555;
  margin: 0 0 20px; }
  @media screen and (min-width: 768px) {
    .faculty-callout {
      margin: 0; } }
  .faculty-callout:before {
    content: '';
    background: #555555;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.6);
    box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.6); }

.faculty-callout__image {
  position: relative;
  overflow: hidden;
  cursor: pointer; }
  .faculty-callout__image:before {
    content: '+';
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    bottom: -1.5%;
    right: 2%;
    z-index: 100;
    color: #fff; }
  .faculty-callout__image:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    border-style: solid;
    border-color: transparent;
    border-width: 30px;
    border-right-color: #cf4520;
    border-bottom-color: #cf4520; }
  .faculty-callout__image img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease 0s; }
    .faculty-callout__image img:hover {
      transform: scale(1.1); }

.faculty-callout__identity {
  background: #555555;
  color: #fff;
  padding: 20px 30px;
  position: relative; }
  @media screen and (max-width: 767px) {
    .faculty-callout__identity {
      padding: 20px 20px; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .faculty-callout__identity {
      padding: 15px 20px; } }

.faculty-callout__name {
  font-weight: 700;
  margin: 0 0 10px;
  line-height: 1.3; }

.faculty-callout__title {
  font-size: 14px; }

.faculty-callout__details {
  background: #555555;
  color: #fff;
  position: relative; }
  @media screen and (min-width: 768px) {
    .faculty-callout__details {
      opacity: 0;
      margin: 0;
      width: 66.6666%;
      float: left; } }

.faculty-callout__text {
  margin: 10px 20px; }
  @media screen and (min-width: 768px) {
    .faculty-callout__text {
      padding: 0 20px 0 20px;
      margin: 0 0 0 0; } }
  @media screen and (min-width: 992px) {
    .faculty-callout__text {
      padding: 0 20px 0 3px; } }

.faculty-callout__description {
  font-size: 14px;
  margin: 0 0 20px; }
  @media screen and (min-width: 992px) {
    .faculty-callout__description {
      font-size: 18px;
      margin: 0 20px 60px 0; } }

@media screen and (min-width: 768px) {
  .faculty-callout__profile-links {
    position: absolute;
    bottom: 0;
    right: 0; } }

.faculty-callout__profile-links a {
  position: relative;
  display: block;
  background: #cf4520;
  color: #fff;
  margin: 5px 0;
  padding: 10px 40px;
  border: none;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .faculty-callout__profile-links a {
      display: inline-block;
      margin: 0 0px 0 0;
      float: left; } }
  @media screen and (min-width: 768px) {
    .faculty-callout__profile-links a:first-child:before {
      content: '';
      position: absolute;
      right: 0;
      height: 70%;
      top: 15%;
      border: 0.5px solid #fff;
      opacity: 0.5; } }
  .faculty-callout__profile-links a:after {
    content: '\e80d';
    padding-left: 10px;
    font-size: 70%;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
  .faculty-callout__profile-links a:hover {
    background: #b31b1b; }

/* Pane Toggled */
@media screen and (min-width: 768px) {
  .faculty-callouts.open li {
    width: 0%; } }

@media screen and (min-width: 768px) {
  .faculty-callouts.open li.open {
    width: 100%;
    border-width: 0 5px 0 5px; } }

@media screen and (min-width: 992px) {
  .faculty-callouts.open li.open {
    border-width: 0 20px; } }

.faculty-callouts.open li.closed {
  border-width: 0px; }

@media screen and (min-width: 768px) {
  .faculty-callouts.open li.closed .faculty-callout {
    display: none; } }

@media screen and (min-width: 768px) {
  li.open .faculty-callout {
    display: flex; } }

li.open .faculty-callout__image:before {
  content: '–'; }

@media screen and (min-width: 768px) {
  li.open .faculty-callout__summary {
    width: 33.33333%;
    float: left;
    padding-right: 6.65px; } }

@media screen and (min-width: 992px) {
  li.open .faculty-callout__summary {
    padding-right: 26.6px; } }

li.open .faculty-callout__details {
  opacity: 1;
  display: block; }
  @media screen and (min-width: 768px) {
    li.open .faculty-callout__details {
      width: 66.66666%;
      float: left; } }

@media screen and (min-width: 768px) {
  li.open .faculty-callout__text {
    animation: reveal 0.5s;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: translateY(-50%);
    /* IE 9 */
    transform: translateY(-50%);
    /* IE 10, Fx 16+, Op 12.1+ */
    position: absolute; } }

@keyframes reveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/**
 * Styles targeting the Care. Discover. Teach. panels on the home page.
 *
 */
/* =Home Panels
 ----------------------------------------------------------*/
.wcm-edu-home-newpanels__details {
  position: relative; }

.wcm-edu-home-newpanels__summary {
  position: relative;
  list-style: none;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  background-repeat: none;
  background-color: #000000; }
  .wcm-edu-home-newpanels__summary::marker, .wcm-edu-home-newpanels__summary::-webkit-details-marker {
    display: none; }
  .wcm-edu-home-newpanels__summary:focus {
    box-shadow: inset 0 0 0 4px #ffc72c; }

@media (max-width: 767px) {
  .wcm-edu-home-newpanels__summary {
    min-height: 315px;
    height: calc(50vh - 100px); }
  .wcm-edu-home-newpanels__text__top-links,
  .wcm-edu-home-newpanels__text__headline {
    display: none; }
  .wcm-edu-home-newpanels__text {
    position: absolute;
    left: 0;
    top: 0;
    right: 0; }
  .wcm-edu-home-newpanels__text__bottom-link a {
    color: #b31b1b; } }

@media (min-width: 768px) {
  .wcm-edu-home-newpanels {
    width: 100%;
    overflow: hidden; }
  .wcm-edu-home-newpanels__flex {
    position: relative;
    height: 60vh;
    display: flex;
    transition: left .5s;
    left: 0; }
    .wcm-edu-home-newpanels__flex[data-current="0"] {
      left: 0; }
    .wcm-edu-home-newpanels__flex[data-current="1"] {
      left: -33.3333%; }
    .wcm-edu-home-newpanels__flex[data-current="2"] {
      left: -66.6667%; }
  .wcm-edu-home-newpanels__details {
    flex-shrink: 0;
    width: 33.3333%;
    transition: width .5s;
    position: relative;
    background: linear-gradient(to bottom, #cf4520 calc(40% - 2px), #dddddd calc(40% - 2px), #dddddd 40%, #f7f7f7 40%); }
  .wcm-edu-home-newpanels__summary {
    position: relative;
    width: 100%;
    transition: width .5s;
    height: 100%; }
  .wcm-edu-home-newpanels__text {
    position: absolute;
    width: 33.3333vw;
    width: calc(33.3333vw - var(--scrollbar-width)*.3333);
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
    background: #f7f7f7;
    z-index: -1; }
  .wcm-edu-home-newpanels__text__top-links {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .wcm-edu-home-newpanels__details[open] {
    left: 0;
    width: 100%;
    z-index: 1; }
    .wcm-edu-home-newpanels__details[open] .wcm-edu-home-newpanels__summary {
      width: 66.6667%; } }

@media (min-width: 992px) {
  .wcm-edu-home-newpanels__flex {
    height: calc(100vh - 256px); } }

.wcm-edu-home-panels__indicator, .wcm-edu-home-panels__indicator.institutional-link {
  clear: both;
  padding: 8px 0;
  display: none; }
  .wcm-edu-home-panels__indicator, .wcm-edu-home-panels__indicator:hover, .wcm-edu-home-panels__indicator:focus, .wcm-edu-home-panels__indicator.institutional-link, .wcm-edu-home-panels__indicator.institutional-link:hover, .wcm-edu-home-panels__indicator.institutional-link:focus {
    border-bottom-width: 0; }
  .wcm-edu-home-panels__indicator:after, .wcm-edu-home-panels__indicator.institutional-link:after {
    content: '\e80f' !important;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    display: block;
    background: #fff;
    color: #b31b1b;
    font-size: 24px;
    text-align: center;
    line-height: 1.0; }
  @media screen and (min-width: 768px) {
    .wcm-edu-home-panels__indicator, .wcm-edu-home-panels__indicator.institutional-link {
      display: block; } }

.wcm-edu-home-newpanels__summary__title {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 0 10px; }
  @media screen and (min-width: 992px) {
    .wcm-edu-home-newpanels__summary__title {
      padding: 25px 0 20px; } }
  .wcm-edu-home-newpanels__summary__title:before {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10; }
  .wcm-edu-home-newpanels__summary__title h2, .wcm-edu-home-newpanels__summary__title span {
    text-shadow: 0px 3px 9px #333;
    font-size: 48px;
    color: white;
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 1.0;
    isolation: isolate;
    position: relative;
    z-index: 100;
    transition: all 0.25s ease; }
    @media screen and (min-width: 768px) {
      .wcm-edu-home-newpanels__summary__title h2, .wcm-edu-home-newpanels__summary__title span {
        font-size: 40px; } }
    @media screen and (min-width: 992px) {
      .wcm-edu-home-newpanels__summary__title h2, .wcm-edu-home-newpanels__summary__title span {
        font-size: 64px; } }
  .wcm-edu-home-newpanels__summary__title span {
    display: block; }
  .wcm-edu-home-newpanels__summary__title h2 {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%); }
    @media screen and (min-width: 768px) {
      .wcm-edu-home-newpanels__details[open] .wcm-edu-home-newpanels__summary__title h2 {
        right: 15%;
        transform: translate(0, -50%); } }
    @media screen and (min-width: 992px) {
      .wcm-edu-home-newpanels__details[open] .wcm-edu-home-newpanels__summary__title h2 {
        right: 8%; } }

.wcm-edu-home-newpanels__summary:focus .wcm-edu-home-newpanels__summary__title:before {
  width: auto;
  height: auto;
  top: 0;
  left: 4px;
  right: 4px;
  bottom: 4px; }

.wcm-edu-home-newpanels__summary:after {
  content: "";
  display: block;
  pointer-events: none;
  width: 70px;
  height: 70px;
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0; }

.wcm-edu-home-newpanels__details--care .wcm-edu-home-newpanels__summary:after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Crect y='6' width='15' height='3' fill='%23fff'/%3E%3Crect x='6' width='3' height='15' fill='%23fff'/%3E%3C/svg%3E") no-repeat 44px 44px/15px 15px, linear-gradient(135deg, transparent 50%, #b31b1b 50%, #b31b1b) no-repeat bottom right/60px 60px; }

.wcm-edu-home-newpanels__details--care[open] .wcm-edu-home-newpanels__summary:after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Crect y='6' width='15' height='3' fill='%23fff'/%3E%3C/svg%3E") no-repeat 44px 44px/15px 15px, linear-gradient(135deg, transparent 50%, #b31b1b 50%, #b31b1b) no-repeat bottom right/60px 60px; }

@media (min-width: 768px) {
  .wcm-edu-home-newpanels__summary:after {
    transition: .2s all; }
  .wcm-edu-home-newpanels__details--care .wcm-edu-home-newpanels__summary:hover:after,
  .wcm-edu-home-newpanels__details--care[open] .wcm-edu-home-newpanels__summary:hover:after {
    background-size: 20px 20px, 70px 70px;
    background-position: 39px 39px, bottom right; } }

.wcm-edu-home-newpanels__details--discover .wcm-edu-home-newpanels__summary:after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Crect y='6' width='15' height='3' fill='%23fff'/%3E%3Crect x='6' width='3' height='15' fill='%23fff'/%3E%3C/svg%3E") no-repeat 44px 44px/15px 15px, linear-gradient(135deg, transparent 50%, #e7751d 50%, #e7751d) no-repeat bottom right/60px 60px; }

.wcm-edu-home-newpanels__details--discover[open] .wcm-edu-home-newpanels__summary:after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Crect y='6' width='15' height='3' fill='%23fff'/%3E%3C/svg%3E") no-repeat 44px 44px/15px 15px, linear-gradient(135deg, transparent 50%, #e7751d 50%, #e7751d) no-repeat bottom right/60px 60px; }

@media (min-width: 768px) {
  .wcm-edu-home-newpanels__summary:after {
    transition: .2s all; }
  .wcm-edu-home-newpanels__details--discover .wcm-edu-home-newpanels__summary:hover:after,
  .wcm-edu-home-newpanels__details--discover[open] .wcm-edu-home-newpanels__summary:hover:after {
    background-size: 20px 20px, 70px 70px;
    background-position: 39px 39px, bottom right; } }

.wcm-edu-home-newpanels__details--teach .wcm-edu-home-newpanels__summary:after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Crect y='6' width='15' height='3' fill='%23fff'/%3E%3Crect x='6' width='3' height='15' fill='%23fff'/%3E%3C/svg%3E") no-repeat 44px 44px/15px 15px, linear-gradient(135deg, transparent 50%, #ffc72c 50%, #ffc72c) no-repeat bottom right/60px 60px; }

.wcm-edu-home-newpanels__details--teach[open] .wcm-edu-home-newpanels__summary:after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Crect y='6' width='15' height='3' fill='%23fff'/%3E%3C/svg%3E") no-repeat 44px 44px/15px 15px, linear-gradient(135deg, transparent 50%, #ffc72c 50%, #ffc72c) no-repeat bottom right/60px 60px; }

@media (min-width: 768px) {
  .wcm-edu-home-newpanels__summary:after {
    transition: .2s all; }
  .wcm-edu-home-newpanels__details--teach .wcm-edu-home-newpanels__summary:hover:after,
  .wcm-edu-home-newpanels__details--teach[open] .wcm-edu-home-newpanels__summary:hover:after {
    background-size: 20px 20px, 70px 70px;
    background-position: 39px 39px, bottom right; } }

@supports (mix-blend-mode: multiply) {
  .wcm-edu-home-newpanels__summary__title:before {
    mix-blend-mode: multiply; } }

@supports not (mix-blend-mode: multiply) {
  .wcm-edu-home-newpanels__summary__title:before {
    opacity: 0.8; } }

.wcm-edu-home-newpanels__text__top-links {
  margin: 0;
  padding: 30px;
  border-bottom: 2px solid #dddddd;
  font-size: 14px;
  background: #cf4520;
  color: #fff;
  height: 40%; }
  @media screen and (min-width: 992px) {
    .wcm-edu-home-newpanels__text__top-links {
      height: 40%;
      margin: 0;
      padding: 20px;
      font-size: 16px; } }
  .wcm-edu-home-newpanels__text__top-links ul {
    margin: 0;
    padding: 0;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: translateY(-50%);
    /* IE 9 */
    transform: translateY(-50%);
    /* IE 10, Fx 16+, Op 12.1+ */ }
  .wcm-edu-home-newpanels__text__top-links li {
    list-style-type: none;
    padding: 0;
    border: 1px dotted;
    border-width: 1px 0 0;
    position: relative; }
    @media screen and (min-width: 992px) {
      .wcm-edu-home-newpanels__text__top-links li {
        padding: 0; } }
  .wcm-edu-home-newpanels__text__top-links li:last-child {
    border-width: 1px 0; }
  .wcm-edu-home-newpanels__text__top-links a {
    display: block;
    padding: 8px 20px 5px 8px;
    border: none;
    color: #fff; }
    .wcm-edu-home-newpanels__text__top-links a:after {
      content: '\e80d';
      font-size: 60%;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
      text-decoration: none;
      line-height: 1.5;
      position: absolute;
      top: 33%;
      right: 5px; }
    .wcm-edu-home-newpanels__text__top-links a:hover {
      background: #b31b1b; }
  .wcm-edu-home-newpanels__text__top-links a.external-link:after {
    content: '\e80d';
    padding-left: 0; }

.wcm-edu-home-newpanels__text__wrapper {
  padding: 20px; }
  @media screen and (min-width: 992px) {
    .wcm-edu-home-newpanels__text__wrapper {
      height: 60%; } }
  @media screen and (min-width: 1200px) {
    .wcm-edu-home-newpanels__text__wrapper {
      padding: 30px; } }

.wcm-edu-home-newpanels__text__headline {
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 18px;
  line-height: 1.2;
  color: #b31b1b;
  margin: 0 0 10px; }
  @media screen and (min-width: 992px) {
    .wcm-edu-home-newpanels__text__headline {
      font-size: 22px; } }

.wcm-edu-home-newpanels__text__teaser {
  font-size: 13px; }

@media screen and (max-width: 767px) {
  .wcm-edu-home-newpanels__text__bottom-link {
    font-weight: 700;
    margin: 20px 0 0; } }

@media screen and (min-width: 768px) {
  .wcm-edu-home-newpanels__text__bottom-link {
    margin: 5px 0 0; } }

@media screen and (min-width: 992px) {
  .wcm-edu-home-newpanels__text__bottom-link {
    margin: 30px 0 0; } }

.wcm-edu-home-newpanels__text__bottom-link a {
  color: #b31b1b; }

@media screen and (min-width: 992px) and (min-height: 800px) {
  .wcm-edu-home-newpanels__text__headline {
    font-size: 3.0vh; }
  .wcm-edu-home-newpanels__text__top-links,
  .wcm-edu-home-newpanels__text__teaser,
  .wcm-edu-home-newpanels__text__bottom-link {
    font-size: 1.7vh; } }

@media screen and (min-width: 992px) and (min-height: 1080px) {
  .wcm-edu-home-newpanels__text__headline {
    font-size: 3.0vh; }
  .wcm-edu-home-newpanels__text__top-links,
  .wcm-edu-home-newpanels__text__teaser,
  .wcm-edu-home-newpanels__text__bottom-link {
    font-size: 1.7vh; } }

@media screen and (min-width: 1200px) and (min-height: 1080px) {
  .wcm-edu-home-newpanels__text__top-links {
    padding: 30px; }
  .wcm-edu-home-newpanels__text__headline {
    font-size: 3.4vh; }
  .wcm-edu-home-newpanels__text__top-links,
  .wcm-edu-home-newpanels__text__teaser,
  .wcm-edu-home-newpanels__text__bottom-link {
    font-size: 1.7vh; } }

@media screen and (min-width: 992px) and (min-height: 768px) {
  .logged-in .wcm-edu-home-newpanels__text__top-links {
    height: 33%; } }

@media screen and (min-height: 1080px) {
  .wcm-edu-home-newpanels__text__top-links {
    height: 30%; } }

@media screen and (min-width: 1024px) and (max-height: 800px) {
  .wcm-edu-home-newpanels__text__headline {
    font-size: 24px; }
  .wcm-edu-home-newpanels__text__top-links a {
    padding: 8px 5px; }
  .wcm-edu-home-newpanels__text__top-links,
  .wcm-edu-home-newpanels__text__teaser,
  .wcm-edu-home-newpanels__text__bottom-link {
    font-size: 15px; }
  .wcm-edu-home-newpanels__text__wrapper {
    padding: 15px 20px; } }

.wcm-edu-home-newpanels__details--care .wcm-edu-home-newpanels__summary {
  background-image: url("/sites/default/files/styles/panopoly_image_original/public/homepage_care.png"); }

@media (max-width: 767px) {
  .wcm-edu-home-newpanels__details--care[open] .wcm-edu-home-newpanels__summary {
    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("/sites/default/files/styles/panopoly_image_original/public/homepage_care.png") center/cover; } }

.wcm-edu-home-newpanels__details--care .wcm-edu-home-newpanels__summary__title:before {
  background-color: #b31b1b; }

.wcm-edu-home-newpanels__details--discover .wcm-edu-home-newpanels__summary {
  background-image: url("/sites/default/files/styles/panopoly_image_original/public/homepage_discover.png"); }

@media (max-width: 767px) {
  .wcm-edu-home-newpanels__details--discover[open] .wcm-edu-home-newpanels__summary {
    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("/sites/default/files/styles/panopoly_image_original/public/homepage_discover.png") center/cover; } }

.wcm-edu-home-newpanels__details--discover .wcm-edu-home-newpanels__summary__title:before {
  background-color: #e7751d; }

.wcm-edu-home-newpanels__details--teach .wcm-edu-home-newpanels__summary {
  background-image: url("/sites/default/files/styles/panopoly_image_original/public/homepage_teach.png"); }

@media (max-width: 767px) {
  .wcm-edu-home-newpanels__details--teach[open] .wcm-edu-home-newpanels__summary {
    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("/sites/default/files/styles/panopoly_image_original/public/homepage_teach.png") center/cover; } }

.wcm-edu-home-newpanels__details--teach .wcm-edu-home-newpanels__summary__title:before {
  background-color: #ffc72c; }

.pane-dean-messages-panel-pane-1 .views-row {
  margin-bottom: 40px; }

.pane-dean-messages-panel-pane-1 .view-filters {
  margin-bottom: 40px; }
  .pane-dean-messages-panel-pane-1 .view-filters .select2-container {
    min-width: 225px; }
  .pane-dean-messages-panel-pane-1 .view-filters label {
    font-size: 13px; }

.pane-dean-messages-panel-pane-1 .deans-message__date {
  margin-bottom: 0; }

.pane-dean-messages-panel-pane-1 .deans-message__link a:after {
  content: '\e80d';
  color: #e7751d;
  padding-left: 10px;
  font-size: 60%;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.node-type-dean-message .deans-message__date {
  margin-bottom: 0; }

.node-type-dean-message .social-share .social-share__print a:before {
  content: '\e814'; }

.node-type-dean-message .social-share a:after {
  display: none; }

.node-type-dean-message .pane-node-field-messages-related-links {
  font-size: 18px; }
  .node-type-dean-message .pane-node-field-messages-related-links .field-label {
    font-size: 16px;
    font-weight: 700;
    color: #555555;
    margin: 0 0 10px;
    text-transform: uppercase;
    letter-spacing: 2px; }
  .node-type-dean-message .pane-node-field-messages-related-links ul {
    margin: 0 0 0 20px;
    padding: 0; }
  .node-type-dean-message .pane-node-field-messages-related-links li {
    padding: 0 10px; }
  .node-type-dean-message .pane-node-field-messages-related-links a.external-link:after {
    display: inline-block;
    content: '' !important;
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
    background-size: 10px 10px;
    height: 10px;
    width: 10px;
    margin-left: 6px;
    position: relative;
    top: 2px; }
    @media screen and (min-width: 992px) {
      .node-type-dean-message .pane-node-field-messages-related-links a.external-link:after {
        background-size: 15px 15px;
        height: 15px;
        width: 15px; } }

.node-type-dean-message .content-footer {
  background: #f7f7f7;
  padding: 40px 0; }

.node-type-dean-message footer {
  padding-top: 0; }

.dean-callout-sidebar {
  border-bottom: 2px solid #dddddd; }
  .dean-callout-sidebar img {
    border-top: 5px solid #b31b1b; }
    @media screen and (min-width: 992px) {
      .dean-callout-sidebar img {
        width: 100%; } }
  .dean-callout-sidebar a:after {
    content: '\e80d';
    color: #e7751d;
    padding-left: 10px;
    font-size: 60%;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.deans-message__back {
  width: auto; }

.deans-messages-callout {
  background: #f7f7f7;
  padding: 5px 25px;
  margin-top: 20px; }
  .deans-messages-callout a:hover {
    text-decoration: underline; }

.pager--infinite-scroll li a {
  border-radius: 20px;
  border: 4px solid #e7751d;
  padding: 10px 20px;
  font-size: 13px;
  width: 220px; }
  .pager--infinite-scroll li a:hover, .pager--infinite-scroll li a:focus {
    border-color: #b31b1b;
    background-color: #cf4520;
    color: #fff; }

/* =Special Messages
----------------------------------------------------------*/
.speaking-up {
  display: block;
  height: 250px;
  background: #000;
  color: #fff;
  position: relative;
  text-align: center;
  cursor: pointer; }
  @media screen and (min-width: 768px) {
    .speaking-up {
      height: 350px; } }
  .speaking-up:hover .speaking-up__link a {
    color: #e7751d; }

.speaking-up__copy {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.speaking-up__headline {
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 26px;
  line-height: 1.2;
  color: #fff; }
  @media screen and (min-width: 768px) {
    .speaking-up__headline {
      font-size: 36px; } }
  @media screen and (min-width: 992px) {
    .speaking-up__headline {
      font-size: 46px; } }
  @media screen and (min-width: 1200px) {
    .speaking-up__headline {
      font-size: 50px; } }

.speaking-up__link a {
  border: none; }
  .speaking-up__link a:hover {
    border: none;
    color: #e7751d; }
  .speaking-up__link a:after {
    content: '\e802';
    padding-left: 10px;
    font-size: 60%;
    vertical-align: center;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5; }
  @media screen and (min-width: 768px) {
    .speaking-up__link a {
      font-size: 24px; } }

/**
* Styles related to the Affiliations Content Type and List View
*
*/
.affiliations-highlight .quick-text {
  color: #fff;
  background-image: linear-gradient(rgba(179, 27, 27, 0.6), rgba(179, 27, 27, 0.6)), url("/sites/default/files/quick_text_bg/affiliations-background.jpg") !important; }
  .affiliations-highlight .quick-text a {
    color: #fff; }

.affiliations-intro-text {
  text-align: center;
  font-size: large; }
  @media screen and (min-width: 768px) {
    .affiliations-intro-text {
      padding: 0 100px 20px; } }

/* =Affiliations Teasers
----------------------------------------------------------*/
.view-affiliations {
  min-height: 200px; }
  .view-affiliations .views-row:first-child .affiliations-teaser__overview {
    border-width: 1px 0 1px; }

.js .view-affiliations .affiliations-teaser {
  display: none; }

.affiliations-teaser {
  margin: 0; }

.affiliations-teaser__overview {
  border: 1px solid #dddddd;
  border-width: 0 0 1px; }
  @media screen and (max-width: 767px) {
    .affiliations-teaser__overview div[class*='col-'] {
      width: 100%; } }

.affiliations-teaser__name {
  line-height: 30px;
  color: #cf4520;
  font-size: 18px;
  padding: 20px 55px 20px 0px;
  position: relative;
  cursor: pointer; }
  .affiliations-teaser__name:after {
    content: '+';
    width: 50px;
    display: block;
    position: absolute;
    top: 20px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #dddddd;
    border-width: 0 1px;
    color: #cf4520; }
  .affiliations-teaser__name.affiliations-teaser__toggle--open {
    color: #000000; }
    .affiliations-teaser__name.affiliations-teaser__toggle--open:after {
      content: '–'; }

.affiliations__link {
  padding-top: 22px; }
  @media screen and (max-width: 767px) {
    .affiliations__link {
      padding-top: 10px;
      padding-bottom: 10px; } }
  .affiliations__link a {
    border-bottom: none; }

.unit-teaser__cta {
  margin: 0 0 20px; }
  @media screen and (min-width: 768px) {
    .unit-teaser__cta {
      margin: 0; } }
  .unit-teaser__cta a.external-link:after {
    display: inline-block;
    content: '';
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
    margin-left: 6px;
    position: relative;
    top: 2px; }

.unit-teaser__readmore {
  margin: 10px 0; }
  @media screen and (min-width: 768px) {
    .unit-teaser__readmore {
      margin: 0 0 10px; } }
  .unit-teaser__readmore a:after {
    content: '\e80d';
    color: #e7751d;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: middle;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5; }

.unit-teaser__cta {
  display: inline-block; }

/* =Unit Hide and Show
----------------------------------------------------------*/
.affiliations-teaser {
  position: relative; }
  .affiliations-teaser:before, .affiliations-teaser:after {
    content: " ";
    display: table; }
  .affiliations-teaser:after {
    clear: both; }

.affiliations-teaser__details {
  display: none;
  padding: 20px 0 0; }
  @media screen and (min-width: 992px) {
    .affiliations-teaser__details {
      padding: 20px 200px 0 60px; } }

.no-js .affiliations-teaser__details {
  display: block; }

.no-js .affiliations-teaser__actions:before {
  display: none; }

/**
 * Locations for Maps and Directions
 *
 */
/* =Location Index
 ----------------------------------------------------------*/
.view-locations {
  padding: 30px 0; }

.location {
  margin: 0 0 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid #dddddd; }

.location__title {
  font-size: 20px;
  font-family: "1898Sans-Bold", sans-serif;
  color: #b31b1b;
  line-height: 1.3;
  margin: 0 0 5px; }

.location__photo {
  margin: 0 0 20px; }
  .location__photo img {
    border-top: 8px solid #b31b1b; }

.location__address {
  font-size: 16px;
  margin: 0 0 20px; }

.location__directions,
.location__download {
  display: inline-block; }
  .location__directions a,
  .location__download a {
    border-bottom: none; }
    .location__directions a:before,
    .location__download a:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      float: left;
      margin: 5px 8px 0 0;
      background-repeat: no-repeat;
      background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg");
      background-size: 395px 120px; }
    .location__directions a.wcm-edu-external-link:after,
    .location__download a.wcm-edu-external-link:after {
      display: none; }

.location__directions {
  margin-bottom: 20px; }
  .location__directions a:before {
    background-position: -300px -25px; }

.location__download {
  border-left: 1px solid #dddddd;
  margin-left: 20px;
  padding-left: 20px; }
  .location__download a:before {
    background-position: -25px -75px; }

/**
 * Styles targeting general components on the home page
 *
 */
/* =Nav Border (Home Only)
----------------------------------------------------------*/
.front nav.main-nav .menu {
  border-bottom: none; }

@media screen and (min-width: 992px) {
  .front .site-main {
    border-top: 5px solid #cf4520; } }

/* =Home News
----------------------------------------------------------*/
.pane-wcm-home-news {
  margin: 0 0 30px; }

.home-news-feature {
  position: relative;
  margin: 30px 0; }

.home-news-feature__view-all {
  font-size: 13px;
  margin: 10px 0 0; }
  @media screen and (min-width: 768px) {
    .home-news-feature__view-all {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0; } }

.home-news-feature > .row.is-flex {
  display: block; }
  @media screen and (min-width: 768px) {
    .home-news-feature > .row.is-flex {
      display: flex;
      align-items: center; } }

.home-news-feature__image,
.home-news__image {
  overflow: hidden;
  margin: 0 0 20px; }
  .home-news-feature__image img,
  .home-news__image img {
    width: 100%;
    transition: all 0.3s ease 0s; }
    .home-news-feature__image img:hover,
    .home-news__image img:hover {
      transform: scale(1.1); }

.home-news-feature__date,
.home-news__date {
  margin: 0 0 5px;
  color: #666666;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  font-family: "1898Sans-Bold", sans-serif;
  letter-spacing: 2px; }

.home-news-feature__title {
  font-size: 18px;
  line-height: 1.6;
  margin: 10px 0; }
  @media screen and (min-width: 992px) {
    .home-news-feature__title {
      font-size: 20px;
      margin: 20px 0; } }
  .home-news-feature__title a, .home-news-feature__title a:hover {
    text-decoration: none; }
  .home-news-feature__title a:after {
    content: '\e80d';
    color: #e7751d;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: middle;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5; }
  .home-news-feature__title a:hover {
    color: #b31b1b;
    border-bottom: 1px solid !important; }
    .home-news-feature__title a:hover:after {
      color: #b31b1b; }

.home-news-feature__teaser {
  font-size: 13px; }
  @media screen and (min-width: 992px) {
    .home-news-feature__teaser {
      font-size: 15px; } }

.home-news__title {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.6;
  margin: 0 0 20px; }
  .home-news__title a {
    border-bottom-width: 0; }
  @media screen and (min-width: 768px) {
    .home-news__title {
      margin: 0; } }
  @media screen and (min-width: 992px) {
    .home-news__title {
      margin: 0;
      font-size: 16px; } }

/* =Home Events
----------------------------------------------------------*/
.home-event-section {
  background: #fff;
  margin: 30px 0 0;
  padding: 0 0 60px; }

.home-events {
  position: relative; }
  @media screen and (min-width: 768px) {
    .home-events {
      padding: 50px 0 0; } }

.home-events__view-all {
  font-size: 12px;
  margin: 10px 0; }
  @media screen and (min-width: 768px) {
    .home-events__view-all {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0; } }
  @media screen and (min-width: 992px) {
    .home-events__view-all {
      font-size: 13px; } }

@media screen and (max-width: 767px) {
  .home-event {
    margin: 0 auto 20px;
    max-width: 300px; } }

.home-event__image {
  position: relative;
  overflow: hidden;
  background-color: #000000;
  /*a:before {
    content: "";
    background: $wcm-red;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
  }*/ }
  .home-event__image a {
    display: block;
    border: none; }
    .home-event__image a:hover {
      border: none; }
    .home-event__image a:focus {
      border: 5px solid #000000; }
  .home-event__image:hover .home-event__date {
    display: none; }
  .home-event__image:hover a:before {
    opacity: 0; }
  .home-event__image:hover img {
    transform: scale(1.1); }
  .home-event__image img {
    width: 100%;
    transition: all 0.3s ease 0s; }

/*.home-event__date {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  color: $wcm-white;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  @include breakpoint($md) {
    font-size: 36px;
  }
}*/
.home-event__date {
  color: #666666;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  font-family: "1898Sans-Bold", sans-serif;
  letter-spacing: 2px;
  margin-top: 20px;
  /*@include breakpoint($md) {
    font-size: 15px;
    margin: 20px 0 10px;
  }*/ }

.home-event__time {
  font-size: 13px;
  margin: 10px 0 5px; }
  @media screen and (min-width: 992px) {
    .home-event__time {
      font-size: 15px;
      margin: 20px 0 10px; } }

.home-event__title {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5; }
  @media screen and (min-width: 992px) {
    .home-event__title {
      font-size: 16px; } }

/* =Home Quick Text
----------------------------------------------------------*/
.home-priority-highlight .quick-text {
  color: #fff;
  background-image: linear-gradient(rgba(179, 27, 27, 0.6), rgba(179, 27, 27, 0.6)), url("/sites/default/files/quick_text_bg/innovation-banner.jpg") !important; }
  .home-priority-highlight .quick-text a {
    color: #fff; }

/* =Latest Dean's Message
----------------------------------------------------------*/
.view-display-id-home_latest_message {
  margin: 40px 0px; }
  .view-display-id-home_latest_message .view-content {
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px; }
    .view-display-id-home_latest_message .view-content:before, .view-display-id-home_latest_message .view-content:after {
      content: " ";
      display: table; }
    .view-display-id-home_latest_message .view-content:after {
      clear: both; }
    @media screen and (min-width: 768px) {
      .view-display-id-home_latest_message .view-content {
        width: 750px; } }
    @media screen and (min-width: 992px) {
      .view-display-id-home_latest_message .view-content {
        width: 970px; } }
    @media screen and (min-width: 1200px) {
      .view-display-id-home_latest_message .view-content {
        width: 1170px; } }
    @media screen and (max-width: 767px) {
      .view-display-id-home_latest_message .view-content {
        padding-left: 20px;
        padding-right: 20px; } }

.deans-message {
  position: relative; }

.deans-message > .row.is-flex {
  display: block; }
  @media screen and (min-width: 992px) {
    .deans-message > .row.is-flex {
      display: flex;
      align-items: center; } }

@media screen and (min-width: 992px) {
  .deans-message__image img {
    width: 100%;
    height: auto; } }

.deans-message__view-all {
  font-size: 13px;
  margin: 0 0 20px; }
  @media screen and (min-width: 768px) {
    .deans-message__view-all {
      position: absolute;
      top: 0;
      right: 0;
      margin: -20px 0 0; } }

.deans-message__date {
  margin: 0 0 30px;
  color: #666666;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  font-family: "1898Sans-Bold", sans-serif;
  letter-spacing: 2px; }

.deans-message__title {
  font-size: 24px;
  font-family: "1898Sans-Bold", sans-serif;
  margin-top: 5px; }
  .deans-message__title a {
    border-bottom: none !important;
    color: #b31b1b; }
    .deans-message__title a:hover {
      border-bottom: 1px solid !important; }
      .deans-message__title a:hover:after {
        color: #b31b1b; }
    .deans-message__title a:after {
      content: '\e80d';
      color: #e7751d;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
      text-decoration: none;
      line-height: 1.5; }

/**
 * Styles targeting the Graduation Page
 *
 */
.graduation-page .panel-onecol-full {
  position: relative;
  background-color: #fff;
  overflow: hidden; }
  .graduation-page .panel-onecol-full:before {
    content: '';
    background: #cf4520;
    bottom: 0;
    display: block;
    height: 600px;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(15deg);
    transform-origin: 0;
    top: -310px; }
    @media screen and (min-width: 768px) {
      .graduation-page .panel-onecol-full:before {
        top: -255px; } }
    @media screen and (min-width: 992px) {
      .graduation-page .panel-onecol-full:before {
        top: -190px; } }
  .graduation-page .panel-onecol-full:after {
    content: '';
    bottom: 0;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 40vw solid #cf4520;
    border-left: 90vw solid transparent;
    transform-origin: 0; }
  .graduation-page .panel-onecol-full .container-fluid {
    position: relative;
    z-index: 1; }
  .graduation-page .panel-onecol-full .col-md-12 {
    background: url(../images/Seal_RGB_WHT.png) no-repeat 0 130px;
    background-size: 30%; }
    @media screen and (min-width: 768px) {
      .graduation-page .panel-onecol-full .col-md-12 {
        background-size: 40%;
        background-position: 0 120px; } }
    @media screen and (min-width: 992px) {
      .graduation-page .panel-onecol-full .col-md-12 {
        background-size: auto;
        background-position: 0 50px; } }

.graduation-page .pane-node-title {
  text-align: center;
  background-color: #fff;
  margin-bottom: 20px; }
  .graduation-page .pane-node-title h1 {
    font-size: 55px;
    background-color: #fff;
    color: #e7751d;
    margin: 20px 0;
    display: inline-block;
    max-width: 1180px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; }

.graduation-page .pane-node-field-featured-image .hero-image {
  position: relative;
  margin-bottom: 20px;
  background-color: #000000; }
  .graduation-page .pane-node-field-featured-image .hero-image img {
    margin-bottom: 0;
    border: 1px solid #fff; }

.graduation-page .pane-node-field-featured-image figcaption {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-style: normal;
  font-family: "1898Sans-Bold", sans-serif;
  text-align: center;
  float: none;
  width: 80%;
  color: #fff;
  font-size: 18px;
  top: 20%; }
  @media screen and (min-width: 768px) {
    .graduation-page .pane-node-field-featured-image figcaption {
      font-size: 30px;
      line-height: 40px; } }
  @media screen and (min-width: 992px) {
    .graduation-page .pane-node-field-featured-image figcaption {
      font-size: 36px;
      line-height: 46px; } }

.graduation-page .featured-news .tile-callout {
  width: 100%; }
  @media screen and (min-width: 768px) {
    .graduation-page .featured-news .tile-callout .tile-callout__photo {
      width: 48%;
      float: left;
      margin-right: 40px; } }
  .graduation-page .featured-news .tile-callout .tile-callout__title {
    font-size: 30px; }
    @media screen and (min-width: 992px) {
      .graduation-page .featured-news .tile-callout .tile-callout__title {
        padding-top: 2%; } }
    @media screen and (min-width: 1200px) {
      .graduation-page .featured-news .tile-callout .tile-callout__title {
        padding-top: 7%; } }

.graduation-page .wcm-graduation-social-cta {
  background-color: #f7f7f7;
  padding: 20px 0;
  margin-bottom: 20px; }
  .graduation-page .wcm-graduation-social-cta a:after {
    display: none; }
  .graduation-page .wcm-graduation-social-cta .title-section {
    font-size: 30px;
    color: #b31b1b;
    margin-bottom: 20px; }
    .graduation-page .wcm-graduation-social-cta .title-section span {
      font-family: "1898Sans-Bold", sans-serif; }
  .graduation-page .wcm-graduation-social-cta .wcm-social-media-icons {
    display: inline-block;
    padding-right: 35px;
    margin-right: 35px; }
    @media screen and (min-width: 992px) {
      .graduation-page .wcm-graduation-social-cta .wcm-social-media-icons {
        border-right: 1px solid #dddddd; } }
    .graduation-page .wcm-graduation-social-cta .wcm-social-media-icons:last-child {
      border-right: none; }
    .graduation-page .wcm-graduation-social-cta .wcm-social-media-icons div {
      margin: 0 10px; }
    .graduation-page .wcm-graduation-social-cta .wcm-social-media-icons .header {
      font-family: "1898Sans-Bold", sans-serif;
      color: #666666;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-size: 13px;
      float: left;
      padding-top: 15px; }
  .graduation-page .wcm-graduation-social-cta .wcm-cta__tile:hover {
    background-color: transparent; }
  .graduation-page .wcm-graduation-social-cta .wcm-cta__tile .cta-icon {
    width: 50px;
    height: 50px; }
  .graduation-page .wcm-graduation-social-cta .wcm-cta__tile.external-link > .wcm-cta__detail:after {
    content: '';
    display: none; }

.graduation-page .paragraphs-items-field-card-callout {
  display: inherit; }
  @media screen and (min-width: 992px) {
    .graduation-page .paragraphs-items-field-card-callout .card-callout {
      width: 50%; }
      .graduation-page .paragraphs-items-field-card-callout .card-callout .card-callout__wrapper {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px; } }
