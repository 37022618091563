/**
 * Styles targeting general components on the home page
 *
 */

/* =Nav Border (Home Only)
----------------------------------------------------------*/

.front nav.main-nav .menu {
  border-bottom: none;
}

.front .site-main {
  @include breakpoint($md) {
    border-top: 5px solid $wcm-dark-orange;
  }
}

 /* =Home News
----------------------------------------------------------*/

.pane-wcm-home-news {
  margin: 0 0 30px;
}

.home-news-feature {
  position: relative;
  margin: 30px 0;
}

.home-news-feature__view-all {
  font-size: 13px;
  margin: 10px 0 0;
  @include breakpoint($sm) {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
}

.home-news-feature > .row.is-flex {
  display: block;
  @include breakpoint($sm) {
    display: flex;
    align-items: center;
  }
}

.home-news-feature__image,
.home-news__image {
  overflow: hidden;
  margin: 0 0 20px;
  img {
    width: 100%;
    transition: all 0.3s ease 0s;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.home-news-feature__date,
.home-news__date {
  margin: 0 0 5px;
  color: $wcm-med-gray;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  font-family: $wcm-bold;
  letter-spacing: 2px;
}

.home-news-feature__title {
  font-size: 18px;
  line-height: 1.6;
  margin: 10px 0;
  @include breakpoint($md) {
    font-size: 20px;
    margin: 20px 0;
  }
  a {
    &, &:hover {
      text-decoration: none;
    }
    &:after {
      content: '\e80d';
      color: $wcm-bright-orange;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
    &:hover {
      color: $wcm-red;
      border-bottom: 1px solid !important;
      &:after {
        color: $wcm-red;
      }
    }
  }
}

.home-news-feature__teaser {
  font-size: 13px;
  @include breakpoint($md) {
    font-size: 15px;
  }
}

.home-news__title {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.6;
  margin: 0 0 20px;
  a {
    border-bottom-width: 0;
  }
  @include breakpoint($sm) {
    margin: 0;
  }
  @include breakpoint($md) {
    margin: 0;
    font-size: 16px;
  }
}

/* =Home Events
----------------------------------------------------------*/

.home-event-section {
  background: $wcm-white;
  margin: 30px 0 0;
  padding: 0 0 60px;
}

.home-events {
  position: relative;
  @include breakpoint($sm) {
    padding: 50px 0 0;
  }
}

.home-events__view-all {
  font-size: 12px;
  margin: 10px 0;
  @include breakpoint($sm) {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
  @include breakpoint($md) {
    font-size: 13px;
  }
}

.home-event {
  @include breakpoint($xs) {
    margin: 0 auto 20px;
    max-width: 300px;
  }
}

.home-event__image {
  position: relative;
  overflow: hidden;
  background-color: $wcm-black;
  a {
    display: block;
    border: none;
    &:hover {
      border: none;
    }
    &:focus {
      border: 5px solid $wcm-black;
    }
  }
  &:hover {
    .home-event__date {
      display: none;
    }
    a:before {
      opacity: 0;
    }
    img {
      transform: scale(1.1);
    }
  }
  /*a:before {
    content: "";
    background: $wcm-red;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
  }*/
  img {
    width: 100%;
    transition: all 0.3s ease 0s;
  }
}

/*.home-event__date {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  color: $wcm-white;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  @include breakpoint($md) {
    font-size: 36px;
  }
}*/

.home-event__date {
  color: $wcm-med-gray;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  font-family: $wcm-bold;
  letter-spacing: 2px;
  margin-top: 20px;
  /*@include breakpoint($md) {
    font-size: 15px;
    margin: 20px 0 10px;
  }*/
}

.home-event__time {
  font-size: 13px;
  margin: 10px 0 5px;
  @include breakpoint($md) {
    font-size: 15px;
    margin: 20px 0 10px;
  }
}

.home-event__title {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  @include breakpoint($md) {
    font-size: 16px;
  }
}

/* =Home Quick Text
----------------------------------------------------------*/

.home-priority-highlight .quick-text {
  color: $wcm-white;
  background-image: linear-gradient( rgba(179, 27, 27, 0.6), rgba(179, 27, 27, 0.6) ), url('/sites/default/files/quick_text_bg/innovation-banner.jpg') !important;
  a {
    color: $wcm-white;
  }
}

/* =Latest Dean's Message
----------------------------------------------------------*/

.view-display-id-home_latest_message {
  margin: 40px 0px;
  .view-content {
    @include container-wrap();
    @include breakpoint($xs) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.deans-message {
  position: relative;
}

.deans-message > .row.is-flex {
  display: block;
  @include breakpoint($md) {
    display: flex;
    align-items: center;
  }
}

.deans-message__image img {
  @include breakpoint($md) {
    width: 100%;
    height: auto;
  }
}

.deans-message__view-all {
  font-size: 13px;
  margin: 0 0 20px;
  @include breakpoint($sm) {
    position: absolute;
    top: 0;
    right: 0;
    margin: -20px 0 0;
  }
}

.deans-message__date {
  margin: 0 0 30px;
  color: $wcm-med-gray;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  font-family: $wcm-bold;
  letter-spacing: 2px;
}

.deans-message__title {
  font-size: 24px;
  font-family: $wcm-bold;
  margin-top: 5px;
  a {
    border-bottom: none !important;
    color: $wcm-red;
    &:hover {
      border-bottom: 1px solid !important;
      // color: $wcm-dark-orange;
      &:after {
        color: $wcm-red;
      }
    }
    &:after {
      content: '\e80d';
      color: $wcm-bright-orange;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
  }
}
