/**
* Styles related to the Unit Content Type and List View
*
*/

/* =Custom Unit List Filters
----------------------------------------------------------*/

.unit-list-filters {
  background: $wcm-bg-gray;
  margin: 0 0 20px;
  padding: 20px 20px 0;

  .input-group-btn .btn {
    background-color: $wcm-dark-orange;
    border: 1px solid $wcm-dark-orange;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 16px;
    height: 39px;
    padding: 6px 15px;
    &:hover {
      background-color: $wcm-red;
    }
  }
  .glyphicon-search {
    font-size: 16px;
    position: relative;
    top: 3px;
    padding-right: 5px;
  }
}

.currently-selected {
  margin: 20px 0;
  font-size: 13px;
  span {
    font-weight: 700;
    padding-right: 5px;
  }
}

#unit-search {
  @include breakpoint($sm) {
    display: inline-block;
    margin: 0 0 20px;
  }
}

#unit-filter {
  // max-width: 800px;
  max-width: none;
}


.unit-alpha-filter {
  margin: 20px 0 0;
  padding: 20px 0 0;
  border-top: 1px solid $wcm-border-gray;
  button {
    border: none;
    background: none;
    color: $wcm-red;
    text-decoration: none;
    border-right: 1px solid $wcm-border-gray;
    border-radius: 0px;
    padding: 0 10px;
    margin: 0 0 20px;
    &:hover {
      color: $wcm-red;
      text-decoration: underline;
    }
  }
}

.unit-alpha-filter button[disabled] {
  color: $wcm-med-gray;
  &:hover {
    text-decoration: none;
  }
}

.unit-alpha-filter .alpha-filter--selected {
  color: $wcm-black;
  font-weight: 700;
}

input[type="radio"].primary-toggle {
  margin: 25px 0 0;
}

label[for=unit-primary],
label[for=unit-all] {
  font-weight: normal;
  padding: 0 5px;
}

/* =Autocomplete
----------------------------------------------------------*/

.wcm-unit-autocomplete {
  padding: 10px;
  border: 1px solid $wcm-border-gray;
  .ui-autocomplete-category {
    text-transform: capitalize;
    color: $wcm-dark-orange;
    font-weight: 700;
  }
  .ui-state-focus {
    background: $wcm-dark-orange;
    color: $wcm-white;
    border-color: $wcm-dark-orange;
  }
}

.unit-filter label {
  font-size: 14px;
}

.unit-filter-select {
  box-shadow: none;
  border: none;
}

.ms-parent {
  width: 100% !important;
  height: 38px;
}

.ms-choice {
  height: 38px;
  border: 1px solid $wcm-border-gray;
  padding: 6px 0;
  font-size: 13px;
}

.ms-choice > div {
  display: none;
}

.ms-choice > span {
  position: relative;
}

.ms-drop {
  font-size: 13px;
}

.ms-drop input[type="checkbox"] {
  margin: 0 5px 0 0;
}

.unit-list-no-results {
  display: none;
}

.selected-clear {
  display: none;
  border: none;
  background: none;
  color: $wcm-dark-orange;
  text-decoration: underline;
}

/* =Unit Teasers
----------------------------------------------------------*/

.view-unit-list {
  min-height: 200px;
}

.js .view-unit-list .unit-teaser {
  display: none;
}

.unit-teaser {
  margin: 0;
}

.unit-teaser__overview {
  border: 1px solid $wcm-border-gray;
  border-width: 0 0 1px;
}

.unit-teaser__name {
  @include breakpoint($xs) {
    line-height: 1.3;
  }
  font-size: 18px;
  padding: 10px 0 5px;
}

.unit-teaser__actions {
  font-size: 14px;
  @include breakpoint($sm) {
    text-align: right;
    padding-right: 20px;

    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%); /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: translateY(-50%); /* IE 9 */
    transform: translateY(-50%);
  }
}

.unit-teaser__cta {
  margin: 0 0 20px;
  @include breakpoint($sm) {
    margin: 0;    
  }
  a.external-link:after {
    display: inline-block;
    content: '';
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
    margin-left: 6px;
    position: relative;
    top: 2px;
  }
}

.unit-teaser__cta,
.unit-teaser__readmore {
  display: inline-block;
  font-size: 13px;
}

.unit-teaser__readmore {
  margin: 10px 0;
  @include breakpoint($sm) {
    margin: 0 0 10px;
  }
  a {
    &:after {
      content: '\e80d';
      color: $wcm-bright-orange;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
  }
}

.unit-teaser__cta {
  display: inline-block;
  font-size: 15px;
}

/* =Unit Detail
----------------------------------------------------------*/

.wcm-edu-details-side h3.pane-title {
  margin: 0 0 5px;
  font-size: 16px;
  color: $wcm-black;
}

.pane-node-field-unit-cta {
  @include clearfix();
  margin: 0 0 20px;
}

.pane-node-field-unit-cta-website,
.pane-node-field-unit-cta-care {
  padding: 20px 0 30px;
  a:before {
    content: '';
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/website.svg');
    background-size: 40px 40px;
    background-repeat: no-repeat;
    height: 40px;
    width: 55px;
    position: relative;
    padding-right: 15px;
    top: -6px;
    float: left;
  }
  a.external-link:after {
    display: none;
  }
}

.pane-node-field-unit-cta-care {
  border-top: 1px solid $wcm-border-gray;
  a:before {
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/patients.svg');
  }
}

/* =Unit News and Events
----------------------------------------------------------*/

.wcm-edu-details-split-section {
  background: $wcm-bg-gray;
  margin: 20px 0;
  padding: 20px 0;
  .pane-title {
    font-size: 30px;
  }
}

.wcm-edu-details-split-section.wcm-edu-detail--split-none {
  background: none;
}

.unit-news__title,
.unit-event__title {
  line-height: 1.4;
  a {
    color: $wcm-red;
    &:hover {
      text-decoration: underline;
    }
  }
}

.unit-news__date {
  font-size: 14px;
  margin: 2px 0;
  color: $wcm-med-gray;
}

.unit-event {
  border-bottom: 1px solid $wcm-border-gray;
}

.unit-event__date--day {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 20px 0 0;
}

.unit-event__date--time {
  font-size: 14px;
  margin: 0 0 10px;
  color: $wcm-med-gray;
}

.unit-event__title {
  margin: 0 0 20px;
}

.unit-detail-partner,
.unit-detail-parents,
.unit-detail-child {
  h3.pane-title {
    font-size: 30px;
  }
}

.wcm-unit__view-all {
  font-size: 13px;
  margin: 10px 0 0;
  @include breakpoint($sm) {
    position: absolute;
    top: 20px;
    right: 50px;
    margin: 0;
  }
}

/* =Related Units (on detail page)
----------------------------------------------------------*/

.related-units {
  .unit-teaser:first-of-type {
    margin: 15px 0 0;
  }
  .unit-teaser:last-of-type {
    .unit-teaser__overview {
      border: none;
    }
  }
}



