/**
 * Styles targeting the top level (non-Mission) landing pages
 *
 */

 /* =Our Story
 ----------------------------------------------------------*/

 .page-node-55,
 .page-node-56 {
   .pane-node-field-featured-image img {
     width: 100%;
     max-width: none;
     height: auto;
   }
   .pane-node-field-featured-image figcaption {
     margin-top: 20px;
     margin-left: auto;
     margin-right: auto;
     padding-left: 20px;
     padding-right: 20px;
     max-width: 1180px;
     width: auto;
     float: none;
   }
   h1.title {
     text-align: center;
     margin: 40px 0 20px;
   }
   .pane-node-body {
      max-width: 760px;
      margin-bottom: 20px;
      text-align: center;
      font-size: large;
      @include container-wrap();
   }
 }

.page-node-55 {
  .hero-image--full {
    width: 100%;
    height: 175px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include breakpoint($sm) {
      height: 300px;
    }
  }
}

 .our-story-leadership {
  margin: 0 0 20px;
  .paragraph-section__title {
    @include breakpoint($xs) {
      margin: 0 0 20px;
    }
  }
}

.our-story-leadership__headshot {
  @include breakpoint($xs) {
    margin: 0 0 20px;
  }
}

.our-story-leadership__quote {
  font-style: italic;
  font-weight: 300;
  margin: 0 0 20px;
  @include breakpoint($md) {
    font-size: 18px;
    padding-right: 100px;
  }
  @include breakpoint($lg) {
    font-size: 20px;    
  }
  .cta-icon {
    width: 20px;
    height: 20px;
    fill: #c0c0c0;
    color: #c0c0c0;
    display : inline-block;
    margin-right: 10px;
    @include breakpoint($md) {
      width: 26px;
      height: 26px;
    }
  }
}

.our-story-leadership__cta {
  font-size: 18px;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style-type: none;
    @include breakpoint($sm) {
      display: inline;
      border-right: 1px solid $wcm-border-gray;
      padding-right: 8px;
      margin-right: 8px;
    }
    &:last-child {
      border: none;
    }
  }
}

.our-story-contact {
  .paragraph-section__title {
    margin: 0 0 20px;
  }
  h3 a {
    text-decoration: none;
    &:after {
      content: '\e80d';
      color: $wcm-bright-orange;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
    &:hover {
      color: $wcm-red;
      border-bottom: 1px solid !important;
      &:after {
        color: $wcm-red;
      }
    }
  }
  h4 {
    margin: 0 0 20px;
  }
  .embed {
    padding-bottom: 40%;
  }
}

.page-node-375 {
  footer {
    padding-top: 0;
  }
}

.node-type-panopoly-landing-page {
  h1.title {
    text-align: center;
  }
}
 