/**
 * Styles targeting the Site Footer
 *
 */

/* =Footer Contact
----------------------------------------------------------*/

.footer-contact__site-name a {
  top: 55%;
}

/* =Footer Map Link
----------------------------------------------------------*/

.footer-contact__map-link a {
  text-align: center;
  background-color: transparent !important;
  color: $wcm-white;
  @include breakpoint($xs) {
    width: 40%;
    height: auto;
    margin: 6% 1% 6% 10%;
    padding: 15px 0;
    font-family: $font-family-sans-serif;
    font-size: 13px;
    line-height: 13px;
    border: 1px solid $wcm-white;
  }
  @include breakpoint($sm) {
    height: 40px;
    margin: 10px 0 0;
    border-right: 1px solid $wcm-white;
  }
  @include breakpoint($md) {
    margin-right: 20px;
  }
  &:before {
    font-size: 30px;
    @include breakpoint($xs) {
      display: none;
    }
  }
  &:hover {
    color: $wcm-yellow;
    border-right: 1px solid $wcm-white;
    @include breakpoint($xs) {
      background-color: $wcm-bright-orange !important;
      color: $wcm-white;
    }
  }
  &:focus {
    color: $wcm-white;
  }
}

/* =Footer Contact Us
----------------------------------------------------------*/

.footer-contact__contact-us a {
  position: relative;
  display: block;
  float: left;
  height: 60px;
  margin: 0;
  padding: 0;
  color: $wcm-white !important;
  text-align: center;
  font-family: $wcm-bold;
  border-left: 2px solid $wcm-dark-orange !important;
  background-color: transparent !important;
  @include breakpoint($xs) {
    width: 40%;
    height: auto;
    margin: 6% 0% 6% 1%;
    padding: 15px 0;
    font-family: $font-family-sans-serif;
    font-size: 13px;
    line-height: 13px;
    border: 1px solid $wcm-white !important;
  }
  @include breakpoint($sm) {
    width: 60px;
    @include text-hide();
  }
  @include breakpoint($md) {
    display: none;
  }
  &:before {
    @include breakpoint($xs) {
      display: none;
    }
    content:'\e80c';
    width: 60px;
    color: $wcm-white;
    font-size: 30px;
    @include fontello();
    @include v-center();
    @include breakpoint($md) {
      display: none;
    }
  }
  &:hover {
    background-color: $wcm-bright-orange !important;
    color: $wcm-white !important;
    &:before {
      color: $wcm-white;
    }
  }
}

 /* =Footer Social Links
 ----------------------------------------------------------*/

 .footer-contact__social-links {
  @include breakpoint($xs) {
    clear: both;
    background: $wcm-white;
    text-align: center;
    padding: 10px 0 0;
  }
  @include breakpoint($sm) {
    display: block;
    float: right;
    height: 60px;
  }
}

.footer-contact__social-links li {
  @include breakpoint($xs) {
    padding: 0 8px;
  }
}

.footer-contact__social-links a {
  color: $wcm-dark-orange;
  @include breakpoint($sm) {
    color: $wcm-white;
  }
  &:before {
    @include breakpoint($xs) {
      font-size: 34px;
    }
  }
}

.footer-contact__social-links a:hover {
  &:before {
    color: $wcm-yellow;
  }
}
 /* =Footer Navigation
 ----------------------------------------------------------*/

.footer-nav {
  width: auto;
  float: none;
  padding: 0;
  margin: 0;
  text-align: left;
  @include breakpoint($md) {
    width: 82%;
    float: left;
    padding-left: 40px;
    margin: 0 0 20px;
  }
  a.external-link:after {
    content: '';
  }
}

.footer-nav .level-1 {
  border-bottom: 1px solid $wcm-border-gray;
  margin: 0 0 10px;
  @include breakpoint($md) {
    width: (100% / 6);
    float: left;
    margin: 0;
    padding: 0 10px 5px;
    border: none;
  }
}

.footer-menu__toggle:after {
  width: 20px;
  height: 20px;
  content: '+';
  color: $wcm-dark-orange;
  font-weight: 700;
  float: right;
  font-size: 18px;
  line-height: 18px;
  @include breakpoint($md) {
    display: none;
  }
}

.footer-menu__section--open .footer-menu__toggle:after {
  content: '–';
}

.footer-nav .level-1:last-child {
  border: none;
}

.footer-nav .footer-menu__section {
  display: block;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.footer-nav .level-2 {
  padding: 0 0 5px;
}

/* =Footer Site Info
----------------------------------------------------------*/

.footer-site-info {
  @include breakpoint($sm) {
    padding: 0 0 30px;
  }
}

.footer-address {
  display: none;
  @include breakpoint($md) {
    display: block;
  }
}

.footer-address__address .btn {
  margin-top: 10px;
  &:hover {
    background: $wcm-red;
    border: 1px solid $wcm-red;
  }
  &:focus {
    background: $wcm-red;
    border: 1px solid $wcm-red;
  }
}

/* =Footer Patient Care CTA
----------------------------------------------------------*/

.footer-patient-care {
  display: none;
  @include breakpoint($md) {
    display: block;
    border: 5px solid $wcm-dark-orange;
    border-width: 0 0 5px;
    font-size: 14px;
    margin: 0 auto;
    padding: 10px 0;
    position: relative;
    text-align: center;
  }
  a {
    font-weight: 700;
    border-bottom: 1px dotted;
    &:hover {
      border-bottom: 1px solid;
      text-decoration: none;
    }
  }
}

/* =Footer Statement
----------------------------------------------------------*/

.footer-statement {
  display: none;
  @include breakpoint($md) {
    display: block;
    margin: 30px 0;
    padding: 0 60px 30px;
    border-bottom: 1px solid $wcm-border-gray;
    font-size: 14px;
    color: $wcm-med-gray;
    text-align: center;
  }
  a {
    font-weight: 700;
    border-bottom: 1px dotted;
    &:hover {
      border-bottom: 1px solid;
      text-decoration: none;
    }
  }
}

/* =Footer Partnership Logos
----------------------------------------------------------*/

.footer-partnership {
  text-align: center;
  @include clearfix();
  @include breakpoint($xs) {
    border-top: 5px solid #cf4520;
    padding: 20px 0 0;
  }
  @include breakpoint($sm) {
    margin: 20px 0 0;
  }
  @include breakpoint($md) {
    text-align: left;
  }
}

.footer-partnership__logo {
  display: block;
  color: $wcm-med-gray;
  font-size: 12px;
  padding: 5px 0;
  text-decoration: underline;
  text-decoration-style: dotted;
  @include breakpoint($sm) {
    display: inline-block;
    text-decoration: none;
    padding: 0;
    border-bottom: 1px dotted;
  }
  @include breakpoint($md) {
    display: block;
    width: 25%;
    float: left;
    &:after {
      content: '';
    }
    @include text-hide();
  }
}

.footer-partnership__decorator {
  display: none;
  @include breakpoint($sm-only) {
    display: inline;
  }
}

.footer-partnership__wcmed {
  @include breakpoint($md) {
    height: 45px;
    background: url(../images/footer-logos/wcm_footer_logos_wcm_logo.svg) no-repeat center center;
    background-size: 235px 24px;
  }
  @include breakpoint($lg) {
    background-size: 250px 24px;
  }
}

.footer-partnership__cornell {
  @include breakpoint($md) {
    height: 51px;
    background: url(../images/footer-logos/wcm_footer_logos_cu_logo.svg) no-repeat center center;
    background-size: 200px 51px;
  }
}

.footer-partnership__nypres {
  @include breakpoint($md) {
    height: 45px;
    background: url(../images/footer-logos/wcm_footer_logos_nyp_logo.png) no-repeat center center;
    background-size: 220px 50px;
  }
}

.footer-partnership__methodist {
  @include breakpoint($sm) {
    &:after {
      content: '';
    }
  }
  @include breakpoint($md) {
    height: 45px;
    background: url(../images/footer-logos/wcm_footer_logos_hm_logo.svg) no-repeat center center;
    background-size: 150px 45px;
  }
}

.footer-links {
  border: none;
  @include breakpoint($xs) {
    padding: 0 0 30px;
  }
}

.footer-links__menu a {
  text-decoration: underline;
  text-decoration-style: dotted;
}