.pane-dean-messages-panel-pane-1 {
  .views-row {
    margin-bottom: 40px;
  }
  .view-filters {
    margin-bottom: 40px;

    .select2-container {
        min-width: 225px;
    }

    label {
      font-size: 13px;
    }

  }
  .deans-message__date {
    margin-bottom: 0;
  }

  .deans-message__link {
    a {
      &:after {
        content: '\e80d';
        color: $wcm-bright-orange;
        padding-left: 10px;
        font-size: 60%;
        @include fontello();
      }
    }
  }
}

.node-type-dean-message {

  .deans-message__date {
    margin-bottom: 0;
  }

  .social-share {

    .social-share__print a:before {
      content: '\e814';
    }

    a {
      &:after {
        display: none;
      }
    }
  }

  .pane-node-field-messages-related-links {
    .field-label {
      font-size: 16px;
      font-weight: 700;
      color: $wcm-dark-gray;
      margin: 0 0 10px;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    font-size: 18px;
    ul {
      margin: 0 0 0 20px;
      padding: 0;
    }
    li {
      padding: 0 10px;
    }
    a.external-link:after {
      @include wcm-external-icon();
    }
  }

  .content-footer {
    background: $wcm-bg-gray;
    padding: 40px 0;
  }

  footer {
    padding-top: 0;
  }
}

.dean-callout-sidebar {
  img {
    border-top: 5px solid $wcm-red;
    @include breakpoint($md) {
      width: 100%;
    }
  }
  border-bottom: 2px solid $wcm-border-gray;

  a {
    &:after {
        content: '\e80d';
        color: $wcm-bright-orange;
        padding-left: 10px;
        font-size: 60%;
        @include fontello();
      }
  }
}

.deans-message__back {
  width: auto;
}

.deans-messages-callout {
  background: $wcm-bg-gray;
  padding: 5px 25px;
  margin-top: 20px;

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.pager--infinite-scroll {
    li a {
        border-radius: 20px;
        border: 4px solid $wcm-bright-orange;
        padding: 10px 20px;
        font-size: 13px;
        width: 220px;
        
        &:hover, &:focus {
            border-color: $wcm-red;
            background-color: $wcm-dark-orange;
            color: #fff;
        }
    }
}

/* =Special Messages
----------------------------------------------------------*/

.speaking-up {
  display: block;
  height: 250px;
  background: #000;
  color: #fff;
  position: relative;
  text-align: center;
  cursor: pointer;
  @include breakpoint($sm) {
    height: 350px;
  }
  &:hover {
    .speaking-up__link a {
      color: $wcm-bright-orange;
    }
  }
}

.speaking-up__copy {
  width: 90%;
  @include center();
}

.speaking-up__headline {
  font-family: $wcm-bold;
  font-size: 26px;
  line-height: 1.2;
  color: #fff;
  @include breakpoint($sm) {
    font-size: 36px;
  }
  @include breakpoint($md) {
    font-size: 46px;
  }
  @include breakpoint($lg) {
    font-size: 50px;
  }
}

.speaking-up__link {
  a {
    border: none;
    &:hover {
      border: none;
      color: $wcm-bright-orange;
    }
    &:after {
      content: '\e802';
      padding-left: 10px;
      font-size: 60%;
      vertical-align: center;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
    }
    @include breakpoint($sm) {
      font-size: 24px;
    }
  }
}

