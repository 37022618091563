/**
 * Styles targeting the Care. Discover. Teach. panels on the home page.
 *
 */




/* =Home Panels
 ----------------------------------------------------------*/

.wcm-edu-home-newpanels__details {
    position: relative;
}
.wcm-edu-home-newpanels__summary {
    position: relative;
    list-style: none;
    &::marker,
    &::-webkit-details-marker {
        display: none;
    }
    &:focus {
        box-shadow: inset 0 0 0 4px $wcm-yellow;
    }
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    background-repeat: none;
    background-color: $wcm-black;
}

@media (max-width: 767px) {
    .wcm-edu-home-newpanels__summary {
        min-height: 315px;
        height: calc(50vh - 100px);
    }
    .wcm-edu-home-newpanels__text__top-links,
    .wcm-edu-home-newpanels__text__headline {
        display: none;
    }
    .wcm-edu-home-newpanels__text {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
    }
    .wcm-edu-home-newpanels__text__bottom-link a {
        color: $wcm-red;
    }
}

@media (min-width: 768px) {
    .wcm-edu-home-newpanels {
        width: 100%;
        overflow: hidden;
    }
    .wcm-edu-home-newpanels__flex {
        position: relative;
        height: 60vh;
        display: flex;
        transition: left .5s;
        left: 0;
        &[data-current="0"] { left: 0; }
        &[data-current="1"] { left: -33.3333%; }
        &[data-current="2"] { left: -66.6667%; }
    }
    .wcm-edu-home-newpanels__details {
        flex-shrink: 0;
        width: 33.3333%;
        transition: width .5s;
        position: relative;
        background: linear-gradient(to bottom, $wcm-dark-orange calc(40% - 2px), $wcm-border-gray calc(40% - 2px), $wcm-border-gray 40%, $wcm-bg-gray 40%);
    }
    .wcm-edu-home-newpanels__summary {
        position: relative;
        width: 100%;
        transition: width .5s;
        height: 100%;
    }
    .wcm-edu-home-newpanels__text {
        position: absolute;
        width: 33.3333vw;
        width: calc(33.3333vw - var(--scrollbar-width)*.3333);
        left: auto;
        top: 0;
        right: 0;
        bottom: 0;
        background: #f7f7f7;
        z-index: -1;
    }
    .wcm-edu-home-newpanels__text__top-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .wcm-edu-home-newpanels__details[open] {
        left: 0;
        width: 100%;
        z-index: 1;
        .wcm-edu-home-newpanels__summary {
            width: 66.6667%;
        };
        .wcm-edu-home-newpanels__text {
            // width: 33.3333%;
        }
    }
}
@media (min-width: 992px) {
    .wcm-edu-home-newpanels__flex {
        height: calc(100vh - 256px);
    }
}

.wcm-edu-home-panels__indicator {
    &, &.institutional-link {
        clear: both;
        padding: 8px 0;
        display: none;
        &, &:hover, &:focus {
            border-bottom-width: 0;
        }
        &:after {
            content: '\e80f' !important;
            @include fontello();
            display: block;
            background: $wcm-white;
            color: $wcm-red;
            font-size: 24px;
            text-align: center;
            line-height: 1.0;
        }
        @include breakpoint($sm) {
            display: block;
        }
    }
}

.wcm-edu-home-newpanels__summary__title {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15px 0 10px;
    @include breakpoint($md) {
        padding: 25px 0 20px;
    }
    &:before {
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }
    h2, span {
        text-shadow: 0px 3px 9px #333;
        font-size: 48px;
        color: white;
        margin: 0;
        padding: 0;
        text-align: center;
        line-height: 1.0;
        isolation: isolate;
        position: relative;
        z-index: 100;
        transition: all 0.25s ease;
        @include breakpoint($sm) {
            font-size: 40px;
        }
        @include breakpoint($md) {
            font-size: 64px;
        }
    }
    span {
        display: block;
    }
    h2 {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        .wcm-edu-home-newpanels__details[open] & {
            @include breakpoint($sm) {
                right: 15%;
                transform: translate(0, -50%);
            }
            @include breakpoint($md) {
                right: 8%;
            }
        }
    }
}

// Accounting for parent element's box-shadow when focused
.wcm-edu-home-newpanels__summary:focus .wcm-edu-home-newpanels__summary__title:before {
    width: auto;
    height: auto;
    top: 0;
    left: 4px;
    right: 4px;
    bottom: 4px;
}
// The +/- buttons on the titles
.wcm-edu-home-newpanels__summary:after {
    content: "";
    display: block;
    pointer-events: none;
    width: 70px;
    height: 70px;
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 0;
}

$newpanels_buttons: ("care": $wcm-red, "discover": $wcm-bright-orange, "teach": $wcm-yellow);

@each $name, $color in $newpanels_buttons {
    .wcm-edu-home-newpanels__details--#{$name} .wcm-edu-home-newpanels__summary:after {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Crect y='6' width='15' height='3' fill='%23fff'/%3E%3Crect x='6' width='3' height='15' fill='%23fff'/%3E%3C/svg%3E") no-repeat 44px 44px / 15px 15px,
        linear-gradient(135deg, transparent 50%, $color 50%, $color) no-repeat bottom right / 60px 60px;
    }
    .wcm-edu-home-newpanels__details--#{$name}[open] .wcm-edu-home-newpanels__summary:after {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Crect y='6' width='15' height='3' fill='%23fff'/%3E%3C/svg%3E") no-repeat 44px 44px / 15px 15px,
        linear-gradient(135deg, transparent 50%, $color 50%, $color) no-repeat bottom right / 60px 60px;
    }
    @media (min-width: 768px) {
        .wcm-edu-home-newpanels__summary:after {
            transition: .2s all;
        }
        .wcm-edu-home-newpanels__details--#{$name} .wcm-edu-home-newpanels__summary:hover:after,
        .wcm-edu-home-newpanels__details--#{$name}[open] .wcm-edu-home-newpanels__summary:hover:after {
            background-size: 20px 20px, 70px 70px;
            background-position: 39px 39px, bottom right;
        }
    }
}


//https://www.sitepoint.com/an-introduction-to-css-supports-rule-feature-queries/
@supports (mix-blend-mode: multiply) {
    .wcm-edu-home-newpanels__summary__title:before {
        mix-blend-mode: multiply;
    }
}

// Fallback
@supports not(mix-blend-mode: multiply) {
    .wcm-edu-home-newpanels__summary__title:before {
        opacity: 0.8;
    }
}

.wcm-edu-home-newpanels__text__top-links {
    margin: 0;
    padding: 30px;
    border-bottom: 2px solid $wcm-border-gray;
    font-size: 14px;
    background: $wcm-dark-orange;
    color: $wcm-white;
    height: 40%;
    @include breakpoint($md) {
        height: 40%;
        margin: 0;
        padding: 20px;
        font-size: 16px;
    }
    ul {
        margin: 0;
        padding: 0;
        @include v-center();
    }
    li {
        list-style-type: none;
        padding: 0;
        border: 1px dotted;
        border-width: 1px 0 0;
        position: relative;
        @include breakpoint($md) {
            padding: 0;
        }
    }
    li:last-child {
        border-width: 1px 0;
    }
    a {
        display: block;
        padding: 8px 20px 5px 8px;
        border: none;
        color: $wcm-white;
        &:after {
            content: '\e80d';
            font-size: 60%;
            @include fontello();
            text-decoration: none;
            line-height: 1.5;
            position: absolute;
            top: 33%;
            right: 5px;
        }
        &:hover {
            background: $wcm-red;
        }
    }
    a.external-link {
        &:after {
            content: '\e80d';
            padding-left: 0;
        }
    }
}

.wcm-edu-home-newpanels__text__wrapper {
    padding: 20px;
    @include breakpoint($md) {
        height: 60%;
    }
    @include breakpoint($lg) {
        padding: 30px;
    }
}

.wcm-edu-home-newpanels__text__headline {
    font-family: $wcm-bold;
    font-size: 18px;
    line-height: 1.2;
    color: $wcm-red;
    margin: 0 0 10px;
    @include breakpoint($md) {
        font-size: 22px; // fallback
    }
}

.wcm-edu-home-newpanels__text__teaser {
    font-size: 13px;
}

.wcm-edu-home-newpanels__text__bottom-link {
    @include breakpoint($xs) {
        font-weight: 700;
        margin: 20px 0 0;
    }
    @include breakpoint($sm) {
        margin: 5px 0 0;
    }
    @include breakpoint($md) {
        margin: 30px 0 0;
    }
    a {
        color: $wcm-red;
    }
}

@media screen and ( min-width: 992px) and ( min-height: 800px) {
    .wcm-edu-home-newpanels__text__headline {
        font-size: 3.0vh;
    }
    .wcm-edu-home-newpanels__text__top-links,
    .wcm-edu-home-newpanels__text__teaser,
    .wcm-edu-home-newpanels__text__bottom-link {
        font-size: 1.7vh;
    }
}

@media screen and ( min-width: 992px) and ( min-height: 1080px) {
    .wcm-edu-home-newpanels__text__headline {
        font-size: 3.0vh;
    }
    .wcm-edu-home-newpanels__text__top-links,
    .wcm-edu-home-newpanels__text__teaser,
    .wcm-edu-home-newpanels__text__bottom-link {
        font-size: 1.7vh;
    }
}

@media screen and ( min-width: 1200px) and ( min-height: 1080px) {
    .wcm-edu-home-newpanels__text__top-links {
        padding: 30px;
    }
    .wcm-edu-home-newpanels__text__headline {
        font-size: 3.4vh;
    }
    .wcm-edu-home-newpanels__text__top-links,
    .wcm-edu-home-newpanels__text__teaser,
    .wcm-edu-home-newpanels__text__bottom-link {
        font-size: 1.7vh;
    }
}

@media screen and ( min-width: 992px) and ( min-height: 768px) {
    .logged-in {
        .wcm-edu-home-newpanels__text__top-links {
            height: 33%;
        }
    }
}

@media screen and ( min-height: 1080px) {
    .wcm-edu-home-newpanels__text__top-links  {
        height: 30%;
    }
}

@media screen and ( min-width: 1024px) and ( max-height: 800px) {
    .wcm-edu-home-newpanels__text__headline {
        font-size: 24px;
    }
    .wcm-edu-home-newpanels__text__top-links a {
        padding: 8px 5px;
    }
    .wcm-edu-home-newpanels__text__top-links,
    .wcm-edu-home-newpanels__text__teaser,
    .wcm-edu-home-newpanels__text__bottom-link {
        font-size: 15px;
    }
    .wcm-edu-home-newpanels__text__wrapper {
        padding: 15px 20px;
    }
}


// These images will be made configurable at some point

$newpanels_images: 
    "care" "homepage_care.png" $wcm-red,
    "discover" "homepage_discover.png" $wcm-bright-orange,
    "teach" "homepage_teach.png" $wcm-yellow;

@each $name, $image, $color in $newpanels_images {
    .wcm-edu-home-newpanels__details--#{$name} {
        .wcm-edu-home-newpanels__summary {
            background-image: url('/sites/default/files/styles/panopoly_image_original/public/#{$image}');
        }
        &[open] .wcm-edu-home-newpanels__summary {
            @media (max-width: 767px) {
                background: 
                    linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)),
                    url('/sites/default/files/styles/panopoly_image_original/public/#{$image}') center / cover;
            }
        }
        .wcm-edu-home-newpanels__summary__title:before {
            background-color: $color;
        }
    }
}