/**
 * Styles targeting the Main Header
 *
 */

/* =Branding
----------------------------------------------------------*/


.brand img {
  max-width: 280px;
  height: auto;
}

/* =Pinned Header
----------------------------------------------------------*/

.main-menu-toggle {
  @include breakpoint($md) {
    border: none;
  }
}

.wcm-global-links li.first {
  background: $wcm-white;
  border: none;
  &:hover {
    background: $wcm-red;
    a {
      color: $wcm-white;
    }    
  }
  a {
    color: $wcm-dark-orange;
    border: none;
    &:focus {
      color: $wcm-white;
    }
  }
}