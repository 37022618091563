/**
 * Styles targeting the Mission Page content type
 *
 */

 .mission-page__hero {
  position: relative;
  margin: 0 0 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: $wcm-med-gray;
  height: calc(60vh);
  overflow: hidden;
}

@media screen and ( min-width: 800px ) and ( max-height: 420px ) {
  .mission-page__hero {
    height: calc(72vh);
  }
}

@media screen and ( min-width: 768px ) and ( min-height: 1024px ) {
  .mission-page__hero {
    height: calc(100vh - 310px);
  }
}

.logged-in {
  .mission-page__hero {
    @include breakpoint($md) {
      height: calc(100vh - 500px);
    }
  }
}

.mission-page__hero-bg {
  position: absolute;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .35s; 

  .mission-page__hero-bg-inner {
    position: absolute;
    z-index: 8;
    width: 100%;
    height: 100%;
    background-size: cover;
    @include breakpoint( (max: 768px)) {
      background-position: center !important;
    }
    background-repeat: no-repeat;
    background-attachment: scroll;
    @include breakpoint( (min: 769px) ) {
      background-attachment: fixed;
      background-position: center;
      height: 100%;
    }

    @media only screen and (min-width: 768px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      background-attachment: scroll;
      background-position: center !important;
    }
  }
}

.mission-page__hero.over .mission-page__hero-bg {
  @include breakpoint($md) {
    transform: scale(1.08);
  }
}

.hero-mask:before {
  @include breakpoint($sm) {
    /* change border-right px to adjust left slope*/
    border-left: 0 none;
    border-right: 35px solid transparent;
    left: 0;
  }
}

.hero-mask:after {
  @include breakpoint($sm) {
    /* change border-left px to adjust right slope*/
    border-left: 35px solid transparent;
    border-right: 0 none;
    right: 0;
  }
}

.hero-mask:before,
.hero-mask:after {
  @include breakpoint($sm) {
    /* bottom-border adjusts thickess of mask*/
    border-bottom: 20px solid #fff;
    content: " ";
    display: block;
    height: 0;
    position: absolute;
    top: 0;
    width: 50%;
    box-sizing: border-box;
  }
}

.hero-mask {
  @include breakpoint($sm) {
    bottom: 0;
    /* adjust height of mask*/
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 110;
  }
}

.mission-page .mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.45);
}

.mission-page__title {
  color: $wcm-white;
}

.mission-page__hero {
  .mission-page__title {
    z-index: 120;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    @include v-center();
  }
}

.mission-page__no-hero {
  position: relative;
  background: $wcm-med-gray;
  min-height: 350px;
  .mission-page__title {
    padding: 60px 0 0;
  }
}

.mission-page__tagline {
  font-size: 24px;
  font-family: $wcm-bold;
  line-height: 1.2;
  color: $wcm-white;
  text-align: center;
  @include breakpoint($xs) {
    padding: 0 30px 0;
  }
  @include breakpoint(( min:  375px )) {
    font-size: 34px;
  }
  @include breakpoint($sm) {
    font-size: 46px;
    padding: 0 40px 10px;
  }
  @include breakpoint($md) {
    font-size: 50px;
  }
}

.mission-page hr {
  width: 30%;
  border-top: 2px solid $wcm-yellow;
  margin-top: 15px;
  margin-bottom: 15px;
  @include breakpoint($xs) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.mission-page__short-description {
  color: #eee;
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  letter-spacing: 1px;
  padding: 0 30px;
  @include breakpoint($sm) {
    font-size: 20px;
    padding-top: 20px;
  }
}

.mission-page__primary_cta {
  margin: 20px 0;
  text-align: center;
  @include breakpoint($md) {
    margin: 30px 0 0;
  }
  a {
    color: $wcm-yellow;
    display: block;
    text-align: center;
    font-size: 14px;
    letter-spacing: 1px;
    border: none;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.2s ease 0s;
    @include breakpoint($sm) {
      font-size: 18px;
    }
    &:hover {
      @include breakpoint($md) {
        background: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

// .mission-page__primary_cta a.external-link {
.mission-page__primary_cta a {
  &:after {
    content: '\e802';
    padding-left: 10px;
    font-size: 90%;
    vertical-align: middle;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
  }
}

// Phones in Landscape
@media screen and ( min-width: 800px ) and ( max-height: 420px ) {
  .mission-page__tagline {
    font-size: 36px;
  }
  .mission-page hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mission-page__short-description {
    font-size: 15px;
    padding-top: 10px;
  }
}

.pane-node-field-mission-description {
  margin-bottom: 20px;
  @include container-wrap();
  p {
    padding: 0 17.5%;
    text-align: center;
    font-size: large;
  }
}

/* =Paragraph Styles targeting Mission Landing Pages
----------------------------------------------------------*/

.node-type-mission-page {
  .paragraphs-item-tile-callouts-section {
    background: $wcm-bg-gray;
    margin: 0 0 30px;
    padding: 0 0 10px;
  }
  .paragraphs-item-link-list-section {
    background: $wcm-bg-gray;
    margin: 0 0 30px;
    padding: 40px 0;
    @include breakpoint($sm) {
      margin: 0;
    }
  }
  .pane-wcmc-newsroom-pane, .pane-wcm-patient-care-news {
    @include container-wrap();
    margin-bottom: 20px;
  }
  .newsroom-article {
    a.external-link:after {
      @include wcm-external-icon();
    }
    @include breakpoint($xs) {
      margin: 0 0 20px;
    }
    .date {
      margin: 10px 0 5px;
      color: $wcm-med-gray;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 1.3;
      font-family: $wcm-bold;
      letter-spacing: 2px;
    }
    .news-title {
      font-size: 13px;
      @include breakpoint($md) {
        font-size: 16px;
      }
      font-weight: 700;
      line-height: 1.5;
      margin: 0;
    }
    .newsroom-article__image {
      overflow: hidden;
      margin: 0 0 20px;
    }
    img {
      width: 100%;
      transition: all 0.3s ease 0s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  footer {
    margin: 0;
    padding: 0;
  }
}