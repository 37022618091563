/**
* Styles related to the Leadership Landing, Bio Content Type and List View
*
*/

/* =Leadership Landing
----------------------------------------------------------*/

.leadership-landing__banner {
  img ~ p {
    font-size: 13px;
    font-style: italic;
    text-align: right;
    @include container-wrap();
  }
}

.leadership-landing__intro {
  margin: 0 0 30px;
  .fieldable-panels-pane {
    @include container-wrap();
  }
  h1 {
    text-align: center;
    margin-bottom: 10px;
  }
  p {
    text-align: center;
    font-size: large;
    @include breakpoint($sm) {
      padding: 0 100px;
    }
  }
}

.leadership-landing__nav {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  ul {
    text-align: center;
    @include breakpoint($sm) {
      text-align: left;
    }
    @include breakpoint($md) {
      font-size: 18px;
    }
  }
  ul.first {
    padding-top: 30px;
    @include breakpoint($sm) {
      padding: 30px 125px 30px 0;
    }
  }
  ul.second {
    padding-bottom: 30px;
    @include breakpoint($sm) {
      padding: 30px 0 30px 0;
    }
  }
  li {
    border-bottom: 1px solid $wcm-white;
    padding: 10px 0;
    list-style-type: none;
    a {
      color: $wcm-white;
      white-space: nowrap;
      border: none;
      &:after {
        content: '\e80d' !important;
        color: $wcm-bright-orange;
        padding-left: 10px;
        font-size: 60%;
        vertical-align: middle;
        @include fontello();
        text-decoration: none;
        line-height: 1.5;
      }
    }
  }
  li:last-of-type {
    border: none;
  }
}

/* =Leadership Bio Detail
----------------------------------------------------------*/

.pane-node-field-leadership-headshot {
  margin: 0 0 20px;
}

.node-type-leadership-biography {
  .sidebar {
    @include breakpoint($xs) {
      margin: 0 0 20px;
    }
    .field-label {
      font-weight: 700;
      display: inline;
    }
  }
}

.leadership-detail-pro-title {
  margin: 0 0 40px;
  ul {
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 700;
    color: $wcm-dark-gray;
  }
  li {
    list-style-type: none;
    margin: 0 0 10px;
    padding: 0;
  }
}

.pane-node-field-leadership-headshot {
  img {
    border-top: 8px solid $wcm-red;
    @include breakpoint($md) {
      max-width: none;
      width: 100%;
    }
  }
}

.leadership-bio-external-link {
  .btn--wcm {
    width: auto;
    margin: 20px auto 20px;
    padding-right: 35px;
  }
}

/* =Leadership Bio List Display
----------------------------------------------------------*/

.leadership-list-item__featured-text {
  @include v-center();
}

.choi-senior-leadership {
  padding: 20px 0 0;
  .field-name-field-leadership-pro-title {
    margin: 0 0 20px;
  }
}

.sort-list {
  font-size: 13px;
  margin: 20px 0 25px;
  padding: 0 0 15px;
  border-bottom: 1px solid $wcm-border-gray;
  span {
    font-weight: 700;
  }
  button {
    border: none;
    background: none;
    color: $wcm-dark-orange;
    &:hover {
      color: $wcm-bright-orange;
      text-decoration: underline;
    }
    &:focus {
      outline: none;
      border: 1px dotted;
    }
  }
  .active-sort {
    color: $wcm-black;
    font-weight: 700;
  }
}

.leadership-list-group-title {
  margin: 0 0 15px;
}

.leadership-list-item {
  margin: 0 0 20px;
}

.paragraphs-items-field-leadership-group-list .field-label {
  display: none;
}

.paragraphs-items-field-leadership-list-items {
  @include breakpoint($sm) {
    display: flex;
    flex-wrap: wrap;
    &:before {
      display: block;
    }
  }
}

.paragraphs-items-field-leadership-list-items > [class*='col-'] {
  @include breakpoint($sm) {
    display: flex;
    flex-direction: column;
  }
}

.leadership-list-item__headshot {
  position: relative;
  overflow: hidden;
  border-top: 8px solid $wcm-red;
  transition: all 0.3s ease 0s;
  @include breakpoint($xs) {
    max-width: 180px;
  }
  img {
    width: 180px;
    height: auto;
    transition: all 0.3s ease 0s;
    @include breakpoint($sm) {
      width: 100%;
      max-width: none;
    }
  }
}

.leadership-list-item__headshot .bio-icon {
  position: absolute;
  display: inline-block;
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  @include breakpoint($md) {
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
    bottom: 15px;
    right: 15px;
  }
  svg {
    width: 20px;
    height: 20px;
    fill: $wcm-bright-orange;
    color: $wcm-dark-orange;
    @include breakpoint($md) {
      width: 30px;
      height: 30px;
    }
  }
}

.leadership-list-item__headshot .mask {
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.leadership-list-item__headshot.is-hover {
  border-top: 8px solid $wcm-bright-orange;
  img {
    transform: scale(1.1);
  }
  .mask {
    opacity: 1.0;
  }
}

.leadership-list-item__headshot.is-hover .bio-icon {
   svg {
    fill: $wcm-white;
    color: $wcm-white;
  }
}

.leadership-list-item__headshot--placeholder {
  &:after {
    display: none;
  }
}

.leadership-list-item__name {
  font-weight: 700;
  line-height: 1.3;
  margin: 15px 0 10px;
}

.leadership-list-item__name a {
  color: $wcm-dark-orange;
}

.leadership-list-item__name a:hover {
  text-decoration: none;
  h4 {
    color: $wcm-red;
  }
}

.leadership-list-item__pro-title ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}
